import React from "react"
import { Box } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { MainHeading, OrganisationLogo, RowBox } from ".."
import { useAuth } from "../../services"

const ItemCountHeader = ({ count = 0, subject = "items", loading }) => {
  const {
    settings: { organisation },
  } = useAuth()

  return (
    <RowBox mb={4}>
      <Box>
        <OrganisationLogo />
      </Box>
      <Box>
        <Box ml={1}>
          <MainHeading mb={0} ml={0}>
            {organisation.name}
          </MainHeading>
        </Box>
        <Box ml={1}>
          {!loading && (
            <>
              {count} {subject}
            </>
          )}
          {loading && <Trans>Loading...</Trans>}
        </Box>
      </Box>
    </RowBox>
  )
}

export { ItemCountHeader }

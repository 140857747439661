import React, { useState, useEffect, useMemo, Fragment } from "react"
import {
  Box,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Tooltip,
  makeStyles,
} from "@material-ui/core"
import pluralize from "pluralize"
import { Trans, t } from "@lingui/macro"
import { ROLE_SCOPE, useQueryAdminableRole, useQueryAdminableRoles, useQueryGroupedPermissions } from "../../data"
import { mapToIds, multipleSelectChange, toId, useFormUtils } from "../../utils"
import { CreatorActions, CreatorMaster } from "../Creators"
import { useMutationCreateRole, useMutationUpdateRole } from "../../data/roles/useMutationRole"
import { RoleOutlinedSelect } from "../OutlinedSelect/RoleOutlinedSelect"
import { ColumnBox, FlexBox, RowBox } from "../Boxes"
import { FieldSectionHeading } from "../Headings"
import SearchInput from "../SearchInput"
import { LoadingSpinner } from "../LoadingSpinner"
import { CommonChip } from "../Chips/CommonChip"
import { snakeToTitle } from "../../utils/string"

const useDisabledSwitchStyles = makeStyles((theme) => ({
  disabled: {
    color: `#a7caee !important`,
  },
  track: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    opacity: 0.12,
  },
}))

const initialState = {
  friendlyName: "",
  description: "",
}

const PermissionsList = ({
  rolesData,
  permissions,
  inheritsRoles,
  loading,
  onPermissionChange,
  cy = "PermissionsList",
}) => {
  const disabledSwitchClasses = useDisabledSwitchStyles()
  const { data: permissionsGroupedData, loading: permissionsGroupedLoading } = useQueryGroupedPermissions()
  const [permissionsSearchText, setPermissionsSearchText] = useState("")

  const handleFilter = (text) => {
    setPermissionsSearchText(text.toLowerCase())
  }

  const handlePermissionChange = (key) => {
    onPermissionChange && onPermissionChange(key)
  }

  const effectivePermissions = useMemo(() => {
    const result =
      permissionsGroupedData?.permissions.grouped.reduce((acc, permissionGroup) => {
        acc.push({ key: permissionGroup.key, type: "permissionGroup" })
        acc.push(
          ...permissionGroup.permissions.map((permission) => ({
            key: permission.key,
            description: permission.description || permission.key,
            value: permissions.find((p) => p.key === permission.key)?.value || false,
            inherited: false,
            type: "permission",
          })),
        )
        return acc
      }, []) || []

    inheritsRoles.forEach((role) => {
      const sourceRole = rolesData?.roles.find(({ id: roleId }) => roleId === toId(role))
      if (!sourceRole) {
        return
      }

      const roleEffectivePermissions = sourceRole.effectivePermissions
      Object.values(roleEffectivePermissions).forEach((inheritedPermission) => {
        if (inheritedPermission.value) {
          const permission = result.find((p) => p.key === inheritedPermission.key)
          if (permission) {
            permission.value = true
            permission.inherited = true
          }
        }
      })
    })

    return result
  }, [inheritsRoles, permissions, permissionsGroupedData?.permissions.grouped, rolesData?.roles])

  if (loading || permissionsGroupedLoading) {
    return (
      <FlexBox justifyContent="center" py={1}>
        <LoadingSpinner size={40} delay={false} />
      </FlexBox>
    )
  }

  return (
    <>
      <SearchInput placeholder={t`Filter permissions`} boxProps={{ pl: 0, pr: 0, mt: 2 }} onChange={handleFilter} />

      {effectivePermissions
        ?.filter(({ key, description }) => `${key} ${description}`.toLowerCase().includes(permissionsSearchText))
        .map(({ type, key, description, value, inherited }, index) => {
          if (type === "permissionGroup") {
            return (
              <Fragment key={key}>
                {index > 0 && <Divider light />}
                <FieldSectionHeading size="small" mt={index > 0 ? 2 : 0} mb={2}>
                  {snakeToTitle(pluralize(key))}
                </FieldSectionHeading>
              </Fragment>
            )
          }

          return (
            <Fragment key={key}>
              {index > 0 && <Divider light />}
              <RowBox my={1.25}>
                <ColumnBox flexGrow={1}>
                  <FormControlLabel
                    control={
                      <Tooltip title={key}>
                        <Switch
                          color="primary"
                          checked={value ?? false}
                          disabled={inherited}
                          onChange={() => handlePermissionChange(key)}
                          classes={inherited ? disabledSwitchClasses : {}}
                        />
                      </Tooltip>
                    }
                    label={<FlexBox flexGrow={1}>{description}</FlexBox>}
                  />
                </ColumnBox>
                {inherited && (
                  <ColumnBox>
                    <CommonChip
                      label="Inherited"
                      size="small"
                      variant="outlined"
                      data-cy={`${cy}-Chip-inherited-${key}`}
                    />
                  </ColumnBox>
                )}
              </RowBox>
            </Fragment>
          )
        })}
    </>
  )
}

const RoleCreator = ({ open, onClose, id, isInline, cy = "RoleCreator" }) => {
  const { isValid } = useFormUtils()
  const { data, loading } = useQueryAdminableRole({ skip: !id || id === "new" || !open, variables: { id } })
  const { data: rolesData } = useQueryAdminableRoles()
  const [createRole, { loading: createRoleLoading }] = useMutationCreateRole()
  const [updateRole, { loading: updateRoleLoading }] = useMutationUpdateRole()

  const [friendlyName, setFriendlyName] = useState(initialState.friendlyName)
  const [description, setDescription] = useState(initialState.description)
  const [createsRoles, setCreatesRoles] = useState([])
  const [mentionsRoles, setMentionsRoles] = useState([])
  const [inheritsRoles, setInheritsRoles] = useState([])
  const [permissions, setPermissions] = useState([])

  const isEdit = id && id !== "new"

  useEffect(() => {
    if (data) {
      const { role: edit } = data

      setFriendlyName(edit.friendlyName)
      setDescription(edit.description)
      setCreatesRoles(edit.createsRoles)
      setMentionsRoles(edit.mentionsRoles)
      setInheritsRoles(edit.inheritsRoles)
      setPermissions([...edit.permissions.map((permission) => ({ ...permission }))])
    }
  }, [id, data])

  useEffect(() => {
    if (data?.role.scope === ROLE_SCOPE.COMMON) {
      onClose && onClose(ROLE_SCOPE.COMMON)
    }
  }, [data?.role.scope, onClose])

  const handleRolesChange = (event, prev, setRoles) => {
    if (event.target.value.length === 0) {
      setRoles([])
    } else {
      setRoles([...multipleSelectChange(prev, event)])
    }
  }

  const handleCancel = () => {
    handleClose(false)
  }

  const handleClose = () => {
    handleResetState
    onClose && onClose(ROLE_SCOPE.ORGANISATION)
  }

  const handleSubmit = async () => {
    if (formValid()) {
      const input = {
        friendlyName,
        description,
        createsRoles: mapToIds(createsRoles),
        mentionsRoles: mapToIds(mentionsRoles),
        inheritsRoles: mapToIds(inheritsRoles),
        permissions: permissions.map(({ key, value }) => ({ key, value })),
      }
      let result
      if (isEdit) {
        result = await updateRole({ variables: { id, input } })
      } else {
        result = await createRole({ variables: { input } })
      }
      if (!result?.data) {
        return
      }
      handleClose(true)
    }
  }

  const handleResetState = () => {
    setFriendlyName(initialState.name)
    setDescription(initialState.description)
  }

  const handlePermissionChange = (key) => {
    setPermissions((prev) => {
      const newPermissions = [...prev]
      const index = newPermissions.findIndex((permission) => permission.key === key)
      if (index === -1) {
        newPermissions.push({ key, value: true })
      } else {
        newPermissions[index].value = !newPermissions[index].value
      }
      return newPermissions
    })
  }

  const formValid = () => isValid(friendlyName) && isValid(description)

  const isFormValid = formValid()

  const submitLoading = createRoleLoading || updateRoleLoading

  const form = (
    <>
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          id="name"
          label={t`Name`}
          name="name"
          value={friendlyName}
          onChange={(e) => setFriendlyName(e.target.value)}
          required
          inputProps={{ "data-cy": `${cy}-TextField-friendlyName` }}
        />
      </Box>

      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          id="description"
          label={t`Description`}
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          inputProps={{ "data-cy": `${cy}-TextField-description` }}
        />
      </Box>

      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <RoleOutlinedSelect
              id="RoleCreator-RoleOutlinedSelect"
              label={t`Can create role(s)`}
              value={mapToIds(createsRoles)}
              onChange={(event) => handleRolesChange(event, createsRoles, setCreatesRoles)}
              data-cy={`${cy}-RoleOutlinedSelect-createsRoles`}
              multiple
            />
            <FormHelperText>
              <Trans>
                Enables users to create and manage the specified roles, when combined with the user creation
                permissions.
              </Trans>
            </FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <RoleOutlinedSelect
              id="RoleCreator-RoleOutlinedSelect"
              label={t`Can mention role(s)`}
              value={mapToIds(mentionsRoles)}
              onChange={(event) => handleRolesChange(event, mentionsRoles, setMentionsRoles)}
              data-cy={`${cy}-RoleOutlinedSelect-mentionsRoles`}
              multiple
            />
            <FormHelperText>
              <Trans>
                Enables the user to select users of the specified roles when creating and assigning resources.
              </Trans>
            </FormHelperText>
          </Grid>
        </Grid>
      </Box>

      <FieldSectionHeading size="large">
        <Trans>Permissions</Trans>
      </FieldSectionHeading>

      <Box mb={2}>
        <RoleOutlinedSelect
          id="RoleCreator-RoleOutlinedSelect"
          label={t`Inherit permissions from other role(s)`}
          value={mapToIds(inheritsRoles)}
          onChange={(event) => handleRolesChange(event, inheritsRoles, setInheritsRoles)}
          data-cy={`${cy}-RoleOutlinedSelect-inheritsRoles`}
          allLabel={t`No inheritance`}
          multiple
          filter={isEdit ? (role) => toId(role) !== id : undefined}
        />
      </Box>

      <Divider light />

      <PermissionsList
        rolesData={rolesData}
        permissions={permissions}
        inheritsRoles={inheritsRoles}
        onPermissionChange={handlePermissionChange}
        loading={loading}
      />

      <CreatorActions
        id="RoleCreator-CreatorActions"
        subject={t`Permission level`}
        submitLoading={submitLoading}
        onClose={handleCancel}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  return (
    <CreatorMaster
      open={open}
      loading={loading}
      subject={t`Permission level`}
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleCancel}
    />
  )
}

export { RoleCreator }

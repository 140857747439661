import React, { useState, useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { Box, Collapse, makeStyles, useTheme, useMediaQuery } from "@material-ui/core"
import Config from "react-global-configuration"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { Header, Footer, PrivateRoute, LoadingSpinner, NavLinkItem, ErrorBoundary } from "../components"
import {
  Login,
  LoginDevice,
  LoginDeviceBusy,
  LoginExternal,
  Logout,
  Password,
  Invite,
  Signup,
  LoginGoogle,
  LoginMicrosoft,
  ReauthDevice,
  LoginExternalResult,
  LogoutExternal,
} from "./public"
import Dashboard from "./dashboard"
import Jobs from "./jobs"
import Knowledge from "./knowledge"
import Labels from "./labels"
import Hub from "./hub"
import Calendar from "./calendar"
import People from "./people"
import Training from "./training"
import Templates from "./templates"
import Area from "./area"
import AreaTag from "./area/AreaTag"
import { AccountUser, AccountAdmin } from "./account"
import Suppliers from "./suppliers/SuppliersArea"
import Sensors from "./sensors"
import Reports from "./reports"
import Search from "./search"
import Notifications from "./notifications"
import { useAuth } from "../services"
import { useJobUtils, useMountEffect, useLocalStorage } from "../utils"
import { useAnalyticsInit } from "../utils/useAnalytics"
import { useDemonstration } from "../utils/useDemonstration"
import Assets from "./assets/AssetsArea.js"
import Locations from "./locations/index.js"
import { useVersionCheck } from "../components/VersionCheck/useVersionCheck.js"
import { dynamicActivate } from "../i18n.js"

const useStyles = makeStyles(() => ({
  spinner: {
    height: "100vh",
  },
}))

const usePublicStyles = makeStyles((theme) => ({
  public: {
    height: "100vh",
    paddingTop: ({ xs }) => (xs ? 0 : 50),
    backgroundColor: theme.palette.background.shaded,
  },
  cancel: {
    color: theme.palette.text.primary,
  },
  cancelIcon: {
    marginRight: theme.spacing(1),
  },
}))

const PublicContainer = ({
  withCancel = false,
  cancelText = <Trans>Cancel</Trans>,
  cancelIcon = "cancel",
  children,
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = usePublicStyles({ xs })

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={classes.public}>
      {children}
      {withCancel && (
        <Box display="flex" justifyContent="center" mt={6}>
          <NavLinkItem
            to="/"
            text={cancelText}
            icon={{ name: cancelIcon, className: classes.cancelIcon }}
            className={classes.cancel}
            cy="cancel"
          />
        </Box>
      )}
    </Box>
  )
}

const Master = () => {
  useVersionCheck()
  const client = useApolloClient()
  const auth = useAuth(client)
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const {
    authed,
    location,
    settings,
    refresh,
    syncLogout,
    addSyncLogoutListener,
    removeSyncLogoutListener,
    permissionGroups: { readJobs, createJobs, readKnowledge, readTemplates, readUsers, adminAccount, adminTraining },
    hasPermission,
    hasFeature,
  } = auth
  const [authing, setAuthing] = useState(true)
  const { getNavigateDateLink } = useJobUtils()
  const { profile: demonstration } = useDemonstration()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })
  const [storedLang, setStoredLang] = useLocalStorage("lang")
  useAnalyticsInit()

  const handleSyncLogout = (event) => {
    if (event.key === "logout") {
      console.log("[MASTER]", "Sync sign out from storage...")
      syncLogout()
    }
  }

  useEffect(() => {
    if (hasFeature("language")) {
      const userLang = settings?.preferences?.language
      if ((userLang && storedLang && userLang !== storedLang) || !storedLang) {
        setStoredLang(userLang)
        dynamicActivate(userLang)
      }
    }
  }, [hasFeature, setStoredLang, settings?.preferences?.language, storedLang])

  useMountEffect(() => {
    const syncAuth = async () => {
      if (!authed) {
        await refresh(client)
      }
      setTimeout(() => setAuthing(false), 700)
    }
    syncAuth()
    addSyncLogoutListener(handleSyncLogout)
    return () => removeSyncLogoutListener(handleSyncLogout)
  })

  useMountEffect(() => {
    if (kiosk || device) {
      document.body.classList.add(kiosk ? "kiosk" : "device")
    }
  })

  const jobsDayParamValidator = {
    isValid: ({ params }) => moment(params.day).isValid(),
    invalidRedirectTo: "/jobs",
  }

  const forceBilling = authed && settings.organisation.disabled

  const hasDemonstration = !!demonstration

  const hasAdminAccount = authed && hasPermission(adminAccount, "or")

  return (
    <>
      {!authing && <Header />}
      <ErrorBoundary>
        {(authing || !authed) && (
          <>
            <Collapse in={authing && !device}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={-10}
                alignItems="center"
                className={classes.spinner}
              >
                <Box display="flex" justifySelf="flex-start" alignSelf="center" mt={3}>
                  <LoadingSpinner size={85} />
                </Box>
              </Box>
            </Collapse>
            <Collapse in={!authing}>
              <Switch>
                {!kiosk && !device && <Route path="/signup/:provider" component={Signup} />}
                {!kiosk && !device && <Route exact path="/signup" component={Signup} />}
                <Route exact path="/password" component={Password} />
                {!kiosk && !device && <Route exact path="/google" component={LoginGoogle} />}
                {!kiosk && !device && <Route exact path="/microsoft" component={LoginMicrosoft} />}
                <Route path="/invite/:id/:token" component={Invite} />
                {!kiosk && !device && !authing && <Route path="/login/:id" component={LoginExternal} />}
                {!kiosk && !device && <Route path="/login-result/:id" component={LoginExternalResult} />}
                <Route path="/logout-external" component={LogoutExternal} />
                {!kiosk && !device && <Route exact path="/*" component={Login} />}
                {(kiosk || device) && <Route exact path="/login" component={LoginDevice} />}
                {(kiosk || device) && authing && <Route exact path="/*" component={LoginDeviceBusy} />}
                {device && !authing && <Route path="/*" component={ReauthDevice} />}
                {kiosk && !authing && <Route exact path="/*" component={Logout} />}
              </Switch>
            </Collapse>
          </>
        )}
        {!authing && authed && !forceBilling && !hasDemonstration && (
          <Switch>
            <Route path="/logout" component={Logout} />
            <Route path="/logout-external" component={LogoutExternal} />

            {!!location && <PrivateRoute exact path="/dashboard" component={Dashboard} />}
            {!!location && <PrivateRoute path="/dashboard/:type/new" requires={createJobs} component={Dashboard} />}

            {!!location && (
              <PrivateRoute
                exact
                path="/jobs"
                requires={readJobs}
                component={() => <Redirect to={getNavigateDateLink(moment().format(), location.timeZone)} />}
              />
            )}
            {!!location && (
              <PrivateRoute
                path="/jobs/:day/:type/:id/:location"
                requires={readJobs}
                component={Jobs}
                validator={({ params }) => moment(params.day).isValid()}
              />
            )}
            {!!location && (
              <PrivateRoute
                path="/jobs/:day/:type/:id"
                requires={readJobs}
                component={Jobs}
                validator={({ params }) => moment(params.day).isValid()}
              />
            )}
            {!!location && (
              <PrivateRoute path="/jobs/:day" requires={readJobs} component={Jobs} validator={jobsDayParamValidator} />
            )}

            <PrivateRoute exact path="/knowledge" requires={readKnowledge} operator="and" component={Knowledge} />
            <PrivateRoute
              path="/knowledge/:type/:id/:action"
              requires={readKnowledge}
              operator="and"
              component={Knowledge}
            />
            <PrivateRoute path="/knowledge/:type/:id" requires={readKnowledge} operator="and" component={Knowledge} />
            <PrivateRoute path="/knowledge/:type" requires={readKnowledge} operator="and" component={Knowledge} />

            {/* TODO Set up requirement permissions for the Labels area of the app */}
            <PrivateRoute exact path="/labels" component={Labels} operator="and" requires="label_read" />
            <PrivateRoute path="/labels/:type/:id/:action" component={Labels} operator="and" requires="label_read" />
            <PrivateRoute path="/labels/:type/:id/" component={Labels} operator="and" requires="label_read" />
            <PrivateRoute path="/labels/:type/" component={Labels} operator="and" requires="label_read" />

            <PrivateRoute path="/calendar/:view/:day/:groups" component={Calendar} feature="calendar" />
            <PrivateRoute path="/calendar" component={Calendar} feature="calendar" />

            {!!location && <PrivateRoute exact path="/hub/actions/:set" component={Hub} />}
            {!!location && <PrivateRoute exact path="/hub/actions/:set/:id" component={Hub} />}
            {!!location && <PrivateRoute exact path="/hub/:set" component={Hub} />}
            {!!location && <PrivateRoute exact path="/hub/:set/:id" component={Hub} />}
            {!!location && <PrivateRoute exact path="/hub/:set/:id/:action" component={Hub} />}
            {!!location && <PrivateRoute exact path="/hub" requires="post_read" component={Hub} />}
            {!!location && <PrivateRoute path="/hub/*" requires="post_read" component={Hub} />}

            <PrivateRoute exact path="/people" requires={readUsers} component={People} />
            <PrivateRoute path="/people/page/:page" requires={readUsers} component={People} />
            <PrivateRoute path="/people/:id/:action" requires={readUsers} component={People} />
            <PrivateRoute path="/people/:id" requires={readUsers} component={People} />

            <PrivateRoute exact path="/training" requires={adminTraining} feature="training" component={Training} />
            <PrivateRoute path="/training/:tab/:id" requires={adminTraining} feature="training" component={Training} />
            <PrivateRoute path="/training/:tab" requires={adminTraining} feature="training" component={Training} />

            <PrivateRoute path="/sensors/:id" requires="sensor_read" feature="sensors" component={Sensors} />
            <PrivateRoute path="/sensors" requires="sensor_read" feature="sensors" component={Sensors} />

            <PrivateRoute
              path="/templates/library/:category/:id/:action"
              requires={readTemplates}
              component={Templates}
            />
            <PrivateRoute path="/templates/library/:category" requires={readTemplates} component={Templates} />
            <PrivateRoute path="/templates/:set/:id/:action" requires={readTemplates} component={Templates} />
            <PrivateRoute path="/templates/:set" requires={readTemplates} component={Templates} />
            <PrivateRoute path="/templates" requires={readTemplates} component={Templates} />

            <PrivateRoute path="/area/:slug/:tag" requires="area_read" component={AreaTag} />
            <PrivateRoute path="/area/:slug" requires="area_read" component={Area} />

            <PrivateRoute path="/assets/:id/:action" requires="asset_read" component={Assets} />
            <PrivateRoute path="/assets/:id" requires="asset_read" component={Assets} />
            <PrivateRoute path="/assets" requires="asset_read" component={Assets} />

            <PrivateRoute
              path="/suppliers/:id/:action"
              requires="supplier_read"
              feature="suppliers"
              component={Suppliers}
            />
            <PrivateRoute path="/suppliers/:id" requires="supplier_read" feature="suppliers" component={Suppliers} />
            <PrivateRoute path="/suppliers" requires="supplier_read" feature="suppliers" component={Suppliers} />

            {!hasAdminAccount && <PrivateRoute exact path="/account" component={AccountUser} />}
            {!hasAdminAccount && <PrivateRoute path="/account/:page" component={AccountUser} excludes={adminAccount} />}

            {hasAdminAccount && <PrivateRoute exact path="/account" component={AccountAdmin} requires={adminAccount} />}
            {hasAdminAccount && <PrivateRoute path="/account/:page" component={AccountAdmin} requires={adminAccount} />}

            <PrivateRoute path="/reports/:page/:days/:locations/:groups" component={Reports} />
            <PrivateRoute path="/reports" component={Reports} />

            <PrivateRoute path="/search/:query" component={Search} />
            <PrivateRoute path="/search" component={Search} />

            <PrivateRoute exact path="/notifications" component={Notifications} />

            <PrivateRoute exact path="/locations/change" component={Locations} />

            {!!location && <Route exact path="/*" component={() => <Redirect to="/dashboard" />} />}
            {!location && <Route exact path="/*" component={() => <Redirect to="/knowledge" />} />}
          </Switch>
        )}
        {!authing && authed && forceBilling && (
          <Switch>
            <Route path="/logout" component={Logout} />
            <PrivateRoute exact path="/account/billing" component={AccountAdmin} />
            <Redirect to="/account/billing" />
          </Switch>
        )}
        {!authing && authed && !forceBilling && hasDemonstration && <DemonstrationSwitch profile={demonstration} />}
      </ErrorBoundary>

      <Footer />
    </>
  )
}

const DemonstrationSwitch = ({ profile }) => {
  switch (profile) {
    case "labels":
      return (
        <Switch>
          <Route path="/logout" component={Logout} />

          <PrivateRoute exact path="/labels" component={Labels} operator="and" requires="label_read" />
          <PrivateRoute path="/labels/:type/:id/:action" component={Labels} operator="and" requires="label_read" />
          <PrivateRoute path="/labels/:type/:id/" component={Labels} operator="and" requires="label_read" />
          <PrivateRoute path="/labels/:type/" component={Labels} operator="and" requires="label_read" />

          <Redirect to="/labels" />
        </Switch>
      )
    default:
      throw new Error(`Unknown demonstration profile: ${profile}`)
  }
}

export default Master
export { PublicContainer }

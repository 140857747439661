import React, { memo } from "react"
import { Tooltip, makeStyles } from "@material-ui/core"
import moment from "moment"
import { t } from "@lingui/macro"
import { TooltipIcon, LinearProgress, Icon } from ".."
import { TrainingScoreChip } from "../Chips/TrainingScoreChip"

const useStyles = makeStyles((theme) => ({
  iconRef: {
    display: "flex",
    justifyContent: "center",
  },
  none: {
    fill: theme.palette.text.faint,
  },
  progress: {
    width: 80,
  },
  expired: {
    fill: theme.palette.error.main,
  },
  complete: {
    fill: theme.palette.success.main,
  },
}))

const PersonTrainingCourseStatus = memo(function PersonTrainingCourseStatus({ course }) {
  const classes = useStyles()

  if (!course) {
    return <Icon name="none" className={classes.none} />
  }

  const {
    status: { score, percentComplete, completedAt },
    dueAt,
  } = course

  if (percentComplete === null) {
    return (
      <TooltipIcon
        tooltip={t`No training assigned`}
        name="none"
        className={classes.none}
        refClassName={classes.iconRef}
      />
    )
  }

  if (percentComplete < 100 && dueAt && moment().isAfter(moment(dueAt))) {
    return (
      <TooltipIcon
        tooltip={`Overdue ${moment(dueAt).format("MMM D, YYYY")}`}
        name="warning"
        className={classes.expired}
        refClassName={classes.iconRef}
      />
    )
  }

  if (percentComplete < 100) {
    return (
      <Tooltip title={`In progress ${percentComplete}%`}>
        <div>
          <LinearProgress value={percentComplete} title={`${percentComplete}%`} className={classes.progress} />
        </div>
      </Tooltip>
    )
  }

  if (score.total > 0) {
    return (
      <Tooltip title={`Completed ${moment(completedAt).format("MMM D, YYYY")}`}>
        <div>
          <TrainingScoreChip label={`${score.percent}%`} tooltip={null} />
        </div>
      </Tooltip>
    )
  }

  return (
    <TooltipIcon
      tooltip={`Completed ${moment(completedAt).format("MMM D, YYYY")}`}
      name="training-module-complete"
      className={classes.complete}
      refClassName={classes.iconRef}
    />
  )
})

export { PersonTrainingCourseStatus }

import React from "react"
import { PaperBox } from "../Boxes"
import { StatisticDisplay } from "../Charts/StatisticDisplay"
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"
import { useQueryReportTrainingLocationsChartDataV2 as useQueryReportLocationsTrainingChartData } from "../../data"

const TrainedStatistic = ({ locations, groups, link }) => {
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { reportData, staffCount, loading } = useQueryReportLocationsTrainingChartData({
    variables: {
      filter: {
        locations: locations.includes("all") ? mapToIds(userLocations) : locations,
        groups,
      },
    },
    skip: !locations || !groups,
  })

  return (
    <PaperBox>
      <StatisticDisplay
        icon="people-dark"
        label="Trained staff"
        breakdown={`Across all ${staffCount?.toLocaleString() || 0} staff`}
        value={`${Math.round(reportData?.percentComplete || 0)}%`}
        link={link}
        loading={loading}
      />
    </PaperBox>
  )
}

export { TrainedStatistic }

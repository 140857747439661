import React, { useState } from "react"
import { Box, Button, Divider, Grid, Tab, Tabs } from "@material-ui/core"
import moment from "moment"
import { useParams, useHistory } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import {
  NoItemsMessage,
  SearchInput,
  LoadingSpinner,
  Icon,
  RequirePermissions,
  LocationOutlinedSelect,
} from "../../components"
import { mapToIds, multipleSelectChange, toId, useMountEffect } from "../../utils"
import { useQueryTriggers } from "../../data/triggers/useQueryTriggers"
import { useMutationDeleteTrigger } from "../../data/triggers/useMutationTrigger"
import TriggerCreator from "../../components/Triggers/TriggerCreator"
import TriggerActionCard from "../../components/Triggers/TriggerActionCard"
import SettingsFooter from "../../components/Settings/SettingsFooter"
import { ListWindowVirtualizer } from "../../components/Lists/ListWindowVirtualizer"

const baseUri = "/account/triggers"

const Triggers = () => {
  const { type, id } = useParams()
  const history = useHistory()

  const [deleteTrigger] = useMutationDeleteTrigger()
  const [searchText, setSearchText] = useState("")
  const [locations, setLocations] = useState(["all"])

  const { data, refetch, loading } = useQueryTriggers({
    variables: {
      filter: {
        search: searchText,
        locations: locations.includes("all") ? [] : locations,
      },
    },
  })
  useMountEffect(() => {
    if (!type) {
      history.push(`${baseUri}/schedule`)
    }
  })

  const handleSearchText = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleLocationsChange = (event) => {
    setLocations([...multipleSelectChange(locations, event)])
  }

  const handleRegionChange = (newLocations) => {
    setLocations([...mapToIds(newLocations)])
  }

  const handleTabClick = (newType) => {
    history.push(`${baseUri}/${newType}`)
  }

  const handleCreate = () => {
    history.push(`${baseUri}/${type}/new`)
  }

  const handleClose = () => {
    refetch()
    history.push(`${baseUri}/${type}`)
  }

  const handleEdit = (editTrigger) => {
    history.push(`${baseUri}/${type}/${toId(editTrigger)}`)
  }

  const handleDelete = async (item) => {
    await deleteTrigger({ variables: { id: toId(item) } })
    refetch()
  }

  const typename = type === "event" ? "EventTrigger" : "ScheduleTrigger"

  const triggers =
    data?.triggers
      .filter((item) => item.__typename === typename)
      .sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1)) || []

  const hasFilter = !!searchText || locations.length > 0

  const open = Boolean(id)

  if (!type) {
    return null
  }

  return (
    <>
      {open && <TriggerCreator open={open} onClose={handleClose} id={id} />}

      <Tabs
        variant="standard"
        value={type}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Select trigger type"
      >
        <Tab label={<Trans>Scheduled</Trans>} value="schedule" onClick={() => handleTabClick("schedule")} />
        <Tab label={<Trans>Events</Trans>} value="event" onClick={() => handleTabClick("event")} />
      </Tabs>

      <Divider />

      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <SearchInput placeholder={t`Filter`} boxProps={{ pl: 0, pr: 0 }} onChange={handleSearchText} />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocationOutlinedSelect
              mode="admin"
              label={t`Which locations?`}
              value={locations || []}
              onChange={handleLocationsChange}
              onRegionChange={handleRegionChange}
              multiple
            />
          </Grid>
        </Grid>
      </Box>

      <Box mb={5} data-cy="Triggers-list">
        <ListWindowVirtualizer
          items={triggers}
          itemContent={(_, trigger) => (
            <TriggerActionCard key={toId(trigger)} trigger={trigger} onEdit={handleEdit} onDelete={handleDelete} />
          )}
          components={{
            Footer: () => <Box my={10}>&nbsp;</Box>,
          }}
        />

        {!data && loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}

        {triggers.length === 0 && !hasFilter && !loading && (
          <NoItemsMessage>
            <Trans>No {type} triggers created</Trans>
          </NoItemsMessage>
        )}
        {triggers.length === 0 && hasFilter && !loading && (
          <NoItemsMessage>
            <Trans>No matching {type} triggers</Trans>
          </NoItemsMessage>
        )}
      </Box>

      <RequirePermissions requires={["trigger_create", "trigger_create_locations"]}>
        <SettingsFooter>
          <Button variant="contained" color="primary" onClick={handleCreate} data-cy="Button-create-trigger">
            <Icon name="trigger" />{" "}
            <Box ml={1}>
              <Trans>Create trigger</Trans>
            </Box>
          </Button>
        </SettingsFooter>
      </RequirePermissions>
    </>
  )
}

export default Triggers

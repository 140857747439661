import React, { useState, useEffect, useMemo } from "react"
import { Grid, List, makeStyles, Typography, Box, useTheme, useMediaQuery } from "@material-ui/core"
import { useLocation, useParams } from "react-router-dom"
import { Trans } from "@lingui/macro"
import {
  LoadingSpinner,
  SubNavigation,
  Content,
  AreaSearch,
  KnowledgeCategory,
  ModuleHeading,
  IconMessage,
  HelpBanner,
  TrainingViewer,
} from "../../components"
import { useQueryCategories } from "../../data"
import { AllTrainingViewer } from "../../components/Training/AllTrainingViewer"

const useStyles = makeStyles((theme) => ({
  submenu: {
    padding: `${theme.spacing(2)}px`,
  },
  submenuTitle: {
    fontSize: 20,
    fontWeight: "600",
    lineHeight: "24px",
    marginLeft: theme.spacing(2),
  },
}))

const Categories = ({ groups, locations, tags, onEditCategory, onDeleteCategory, variant }) => {
  const theme = useTheme()
  const { id } = useParams()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const location = useLocation()

  const classes = useStyles({ xs })
  const { data, loading, subscribe } = useQueryCategories({ variables: { filter: { groups, locations, tags } } })
  const [unsubscribes, setUnsubscribes] = useState(null)

  const isTraining = location.pathname.endsWith(`/training/${id}`)
  const isAllTraining = ["/training", "/training/"].some((path) => location.pathname.endsWith(path))

  const hasFilters = useMemo(() => Boolean(groups?.length || locations?.length), [groups, locations])

  useEffect(() => {
    if (!unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [subscribe, unsubscribes, setUnsubscribes])

  if (variant === "submenu") {
    return (
      <SubNavigation className={classes.submenu}>
        <Typography variant="h2" className={classes.submenuTitle}>
          <Trans>Categories</Trans>
        </Typography>
        {loading && !data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner />
          </Box>
        )}
        {data && (
          <List>
            {data.categories.map((category) => (
              <KnowledgeCategory key={category.id} category={category} variant="submenu" />
            ))}
          </List>
        )}
      </SubNavigation>
    )
  }
  if (variant === "mobile-submenu") {
    return (
      <SubNavigation className={classes.submenu}>
        <Typography variant="h2" className={classes.submenuTitle}>
          <Trans>Categories</Trans>
        </Typography>
        {loading && !data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner />
          </Box>
        )}
        {data && (
          <List>
            {data.categories.map((category) => (
              <KnowledgeCategory key={category.id} category={category} variant="submenu" />
            ))}
          </List>
        )}
      </SubNavigation>
    )
  }
  return (
    <>
      {isTraining && <TrainingViewer isInline={xs} />}

      {isAllTraining && <AllTrainingViewer isInline={xs} />}

      {(!isTraining || !xs) && (
        <>
          <AreaSearch fullHeading={<Trans>Find the answers you're looking for</Trans>} />

          <Content start>
            <HelpBanner name="knowledge" />

            {Boolean(data?.categories.length) && (
              <ModuleHeading noMargin>
                <Trans>By category</Trans>
              </ModuleHeading>
            )}
            {!loading && !data?.categories.length && (
              <IconMessage
                name="empty-knowledge"
                text={hasFilters ? "No items match the selected filters." : "Your knowledge base is currently empty."}
              />
            )}
            <Box pt={xs ? 2 : 4}>
              {loading && !data && (
                <Box display="flex" justifyContent="center">
                  <LoadingSpinner size={60} />
                </Box>
              )}
              {data && (
                <Grid container direction="column">
                  <Grid container direction="row" spacing={xs ? 2 : 3}>
                    {data.categories.map((category) => (
                      <Grid item sm={6} xs={12} key={category.id}>
                        <KnowledgeCategory
                          category={category}
                          onEdit={onEditCategory}
                          onDelete={category.items.count ? null : onDeleteCategory}
                          variant="tile"
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Box>
          </Content>
        </>
      )}
    </>
  )
}

export default Categories

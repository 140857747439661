import React, { useEffect, useState } from "react"
import { Box, TextField, Link } from "@material-ui/core"
import moment from "moment-timezone"
import { Autocomplete } from "@material-ui/lab"
import Config from "react-global-configuration"
import { Trans } from "@lingui/macro"
import TimeZoneTextField from "../TextField/TimeZoneTextField"
import HumanReadableCron from "../Triggers/HumanReadableCron"
import { Caption } from "../Headings"

const initialState = {
  timeZone: moment.tz.guess(),
}

const examples = [
  { label: "Every day @ 8AM", expression: "0 8 * * *" },
  { label: "Every Monday @ 9AM", expression: "0 9 * * MON" },
  { label: "Every Monday & Thursday @ 11AM", expression: "0 11 * * MON,THU" },
  { label: "Monday to Friday @ 12PM", expression: "0 12 * * MON-FRI" },
  { label: "First day of every month @ 8:30AM", expression: "30 8 1 * *" },
  { label: "Last day of every month @ 10:00PM", expression: "0 22 L * *" },
  { label: "Every 4 hours on the hour", expression: "0 */4 * * *" },
  { label: "Every 14 days @ 5AM", expression: "0 5 */14 * *" },
]

const HumanIntervalScheduler = ({ value, onChange, onValidateExpression }) => {
  const [timeZone, setTimeZone] = useState(initialState.timeZone)
  const [text, setText] = useState(value.schedules[0])
  const [validation, setValidation] = useState({ error: false, message: "" })

  const configLinks = Config.get("links")

  useEffect(() => {
    if (value?.timeZone) {
      setTimeZone(value.timeZone)
    }
  }, [value.timeZone])

  const handleTextChange = (event, option) => {
    const newText = option?.expression || ""
    if (onValidateExpression) {
      setValidation(onValidateExpression(newText))
    }
    setText(newText)
    handleChange({ timeZone, schedules: [newText] })
  }

  const handleTextKeyUp = (event) => {
    const newText = event.target.value
    if (onValidateExpression) {
      setValidation(onValidateExpression(newText))
    }
    setText(newText)
    handleChange({ timeZone, schedules: [newText] })
  }

  const handleTimeZoneChange = (event, option) => {
    const newTimeZone = option?.key || initialState.timeZone
    setTimeZone(newTimeZone)
    handleChange({ timeZone: newTimeZone, schedules: [text] })
  }

  const handleChange = (data) => {
    onChange && onChange(data)
  }

  const optionValue = examples.find((item) => item.expression === text) || { label: text, expression: text }

  return (
    <>
      <Box mb={2}>
        <Box mb={0.5}>
          <Autocomplete
            freeSolo
            value={optionValue}
            getOptionLabel={(item) => item?.expression || ""}
            renderOption={(item) => item?.label || ""}
            getOptionSelected={(option) => option.expression === optionValue.expression}
            options={examples}
            groupBy={() => "Examples"}
            onChange={handleTextChange}
            onKeyUp={handleTextKeyUp}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Enter a schedule expression (cron)"
                placeholder="(S) M* H* D* M* W*"
                fullWidth
              />
            )}
          />
        </Box>
        <Caption mb={2.5} px={1}>
          {!text && (
            <Trans>
              View the{" "}
              <Link
                href={`${configLinks.help}/article/8-creating-notifications-using-triggers#Creating-a-scheduled-trigger-nVDug`}
                target="_blank"
                rel="noopener"
                underline="none"
              >
                schedule expressions guide
              </Link>
            </Trans>
          )}
          {!validation.error && <HumanReadableCron expression={text} timeZone={timeZone} />}
          {validation.error && <Box color="error.main">{validation.message}</Box>}
        </Caption>

        <Box mb={2}>
          <TimeZoneTextField label="In which time zone?" value={timeZone} onChange={handleTimeZoneChange} required />
        </Box>
      </Box>
    </>
  )
}

export default HumanIntervalScheduler

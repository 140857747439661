import { useMutation, gql } from "@apollo/client"
import moment from "moment"
import { v4 as uuid } from "uuid"
import { PROCESS_STEP_NON_RESPONSE_TYPES, useMakeOptimistic } from ".."
import { toId } from "../../utils"
import serializationKeys from "../serializationKeys"
import { JOB_FIELDS } from "./fragments/jobFieldsFragment"
import { JOB_STEP_FIELDS } from "./fragments/jobStepFieldsFragment"

const JOB_STEP_ADD_NOTE_MUTATION = gql`
  ${JOB_STEP_FIELDS}
  mutation JobStepAddNote($job: ID!, $step: ID!, $text: String!, $uploads: [ID!]) {
    job(id: $job) {
      step(id: $step) {
        addNote(text: $text, uploads: $uploads) {
          ...JobStepFields
        }
      }
    }
  }
`

const JOB_STEP_DELETE_NOTE_MUTATION = gql`
  mutation JobStepDeleteNote($job: ID!, $step: ID!, $note: ID!) {
    job(id: $job) {
      step(id: $step) {
        note(id: $note) {
          delete {
            ...JobStepFields
          }
        }
      }
    }
  }
  ${JOB_STEP_FIELDS}
`

const JOB_STEP_COMPLETE_MUTATION = gql`
  ${JOB_FIELDS}
  mutation JobStepComplete(
    $job: ID!
    $step: ID!
    $complete: Boolean!
    $skip: Boolean
    $response: String
    $responseUploads: [ID!]
    $responseSelections: ResponseSelectionsMutation
    $responseCustomSelections: [String!]
  ) {
    job(id: $job) {
      step(id: $step) {
        complete(
          complete: $complete
          skip: $skip
          response: $response
          responseUploads: $responseUploads
          responseSelections: $responseSelections
          responseCustomSelections: $responseCustomSelections
        ) {
          ...JobFields
        }
      }
    }
  }
`

const makeJobStepAddNoteOptimisticResponse = (variables, author) => {
  const { step, text, uploads } = variables

  const result = {
    __typename: "Mutation",
    job: {
      step: {
        addNote: {
          ...JSON.parse(JSON.stringify(step)),
        },
      },
    },
  }

  result.job.step.addNote.notes.push({
    id: uuid(),
    text,
    uploads,
    author,
    createdAt: moment().format(),
  })

  return result
}

const makeJobStepCompleteOptimisticResponse = (variables, completedBy) => {
  const { job, step, complete, skip, response, responseUploads } = variables

  const result = {
    __typename: "Mutation",
    job: {
      step: {
        complete: {
          ...JSON.parse(JSON.stringify(job)),
        },
      },
    },
  }

  const resultJob = result.job.step.complete

  const stepIndex = resultJob.status.steps.findIndex((item) => toId(item) === toId(step))
  if (stepIndex > -1) {
    resultJob.status.steps[stepIndex] = {
      ...resultJob.status.steps[stepIndex],
      response: response || "",
      responseUploads: responseUploads || [],
      skip: skip || false,
      completedAt: complete || skip ? moment().format() : null,
      completedBy,
    }
  }

  const steps = resultJob.status.steps.filter((item) => !PROCESS_STEP_NON_RESPONSE_TYPES.includes(item.responseType))
  const percentComplete = Math.floor((steps.filter((item) => item.completedAt).length / steps.length) * 100)

  resultJob.percentComplete = percentComplete
  resultJob.status.completed = percentComplete === 100
  resultJob.status.completedAt = percentComplete === 100 ? moment().format() : null

  return result
}

const useMakeJobStepOptimistic = () => {
  const { currentUser } = useMakeOptimistic()

  return {
    makeJobStepCompleteOptimisticResponse: (variables) =>
      makeJobStepCompleteOptimisticResponse(variables, currentUser()),
    makeJobStepAddNoteOptimisticResponse: (variables) => makeJobStepAddNoteOptimisticResponse(variables, currentUser()),
  }
}

const useMutationJobStepAddNote = (job, step) =>
  useMutation(JOB_STEP_ADD_NOTE_MUTATION, {
    context: {
      serializationKey: serializationKeys.jobStep(job, step),
    },
  })

const useMutationJobStepDeleteNote = (job, step) =>
  useMutation(JOB_STEP_DELETE_NOTE_MUTATION, {
    context: {
      serializationKey: serializationKeys.jobStep(job, step),
    },
    update(cache, { errors }, { variables: { note } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(note), __typename: "JobProcessStepNote" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

const useMutationJobDeleteNote = () =>
  useMutation(JOB_STEP_DELETE_NOTE_MUTATION, {
    update(cache, { errors }, { variables: { note } }) {
      if (errors) {
        return
      }
      const normalizedId = cache.identify({ id: toId(note), __typename: "JobProcessStepNote" })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

const useMutationJobStepComplete = (job, step) =>
  useMutation(JOB_STEP_COMPLETE_MUTATION, {
    context: {
      serializationKey: serializationKeys.jobStep(job, step),
    },
  })

export {
  useMutationJobStepAddNote,
  useMutationJobStepDeleteNote,
  useMutationJobDeleteNote,
  useMutationJobStepComplete,
  useMakeJobStepOptimistic,
}

import React, { useEffect, useMemo, useState } from "react"
import { Grid, Box, useTheme, useMediaQuery } from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import {
  BackToTop,
  Content,
  KnowledgeArticle,
  KnowledgeArticleCreator,
  LoadingSpinner,
  LanguageTranslator,
} from "../../components"
import { useQueryKnowledge } from "../../data"
import Forbidden from "./Forbidden"
import { useMountEffect } from "../../utils"
import { useAuth } from "../../services"

const Article = ({ onEditArticle, onDeleteArticle, onCopyArticle, ...rest }) => {
  const theme = useTheme()
  const history = useHistory()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const { id, action } = useParams()
  const { data, loading, subscribe, error } = useQueryKnowledge({ variables: { id }, errorPolicy: "all" })

  const [unsubscribes, setUnsubscribes] = useState(null)
  const [toc, setToc] = useState(false)
  const [editing, setEditing] = useState(null)
  const [top, setTop] = useState(undefined)
  const { hasFeature } = useAuth()

  useMountEffect(() => {
    window.addEventListener("scroll", scrollListener)
    return () => window.removeEventListener("scroll", scrollListener)
  })

  const scrollListener = () => {
    setTop(document.querySelector("header").clientHeight + 20)
  }

  useEffect(() => {
    if (!unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [subscribe, unsubscribes, setUnsubscribes])

  useEffect(() => {
    if (data) {
      setToc(data.knowledge.content.filter((c) => c.heading).length >= 2)
    }
  }, [data])

  useEffect(() => {
    if (data && action === "edit" && id && (!editing || editing.id !== id)) {
      setEditing({ ...data.knowledge })
    } else if (!id && editing) setEditing(null)
  }, [data, action, id, editing])

  const forbidden = useMemo(() => {
    if (!error?.graphQLErrors?.length) {
      return false
    }
    return error.graphQLErrors[0].extensions?.code === "FORBIDDEN"
  }, [error])

  const handleClose = () => {
    history.push(`/knowledge/article/${id}`)
  }

  return (
    <>
      <KnowledgeArticleCreator open={editing !== null} onClose={handleClose} isInline={xs} edit={editing} />

      {(!xs || (xs && !editing)) && (
        <Content full>
          {hasFeature("language") && <LanguageTranslator id="knowledge-content" />}
          {loading && !data && (
            <Box display="flex" justifyContent="center">
              <LoadingSpinner size={60} />
            </Box>
          )}
          {data && (
            <div id="knowledge-content">
              <Grid container direction="row">
                {!smDown && toc && (
                  <Grid item xs={12} md={3}>
                    <div style={{ position: "sticky", top }}>
                      <KnowledgeArticle variant="submenu" knowledge={data.knowledge} />
                      <BackToTop from={300} />
                    </div>
                  </Grid>
                )}
                <Grid item md={toc ? 9 : 12} xs={12}>
                  <KnowledgeArticle
                    variant="full"
                    knowledge={data.knowledge}
                    onEdit={onEditArticle}
                    onDelete={onDeleteArticle}
                    onCopy={onCopyArticle}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          {forbidden && <Forbidden />}
        </Content>
      )}
    </>
  )
}

export default Article

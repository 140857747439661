import React, { useEffect, useImperativeHandle, useMemo, useState } from "react"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core"
import moment from "moment"
import { Trans } from "@lingui/macro"
import {
  Icon,
  RowBox,
  FormatDateTimeCompact,
  Caption,
  FormatDateFormal,
  ErrorBoundary,
  ColumnBox,
  LoadingSpinner,
  NoItemsMessage,
  FormatBytes,
} from ".."
import { mapToIds, toId } from "../../utils"
import { useAuth } from "../../services"
import { ActionStatusChip } from "../Chips/ActionStatusChip"
import { ACTION_PRIORITY, ACTION_PRIORITY_LABELS, ACTION_STATUS } from "../../data"
import { useQueryReportActionsLocationsDetail } from "../../data/report/useQueryReportActionsLocationsDetail"
import ActionViewer from "../ActionList/ActionViewer"
import { TablePageIndicator } from "../Table/TablePageIndicator"
import { TooltipIconButton } from "../Buttons/TooltipIconButton"
import { SeparatorBarTableRow } from "../Table/SeparatorBarTableRow"
import { TablePagination } from "../Table/TablePagination"
import downloadActionExport from "../../data/report/downloadActionExport"
import { useSnackbar } from "../SnackbarProvider"

const useStyles = makeStyles(() => ({
  noBottomBorder: {
    borderBottom: "none",
  },
}))

const ActionsTableReportNext = ({
  reportRef,
  title,
  gt,
  lt,
  process,
  step,
  locations,
  groups,
  tags,
  statuses,
  priorities,
  defaultLimit = 50,
  showLocation = true,
  showTopIndicator = true,
  tools,
}) => {
  const classes = useStyles()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const theme = useTheme()
  const snackbar = useSnackbar()
  const [{ offset, limit }, setOffsetLimit] = useState({ offset: 0, limit: defaultLimit })

  const filterProcess = process === "all" ? null : process
  const filterProcessStep = step === "all" ? null : step
  const filterLocations = locations.includes("all") ? userLocations : locations
  const filterStatuses = statuses.includes("all") ? null : statuses
  const filterPriorities = priorities.includes("all") ? null : priorities
  const filterGroups = groups.includes("all") ? null : groups
  const filterTags = tags.includes("all") ? null : tags

  const baseQueryVariables = {
    input: {
      process: toId(filterProcess, true),
      processStep: toId(filterProcessStep, true),
      locations: mapToIds(filterLocations),
      groups: filterGroups,
      tags: filterTags,
      statuses: filterStatuses,
      priorities: filterPriorities,
      gt,
      lt,
    },
  }
  const { data: listData, loading: listLoading } = useQueryReportActionsLocationsDetail({
    variables: {
      ...baseQueryVariables,
    },
  })

  const [viewAction, setViewAction] = useState(null)

  useImperativeHandle(reportRef, () => ({
    downloadData: async () => {
      const busySnackbarConfig = {
        message: "Preparing your download",
        icon: <Icon name="document" />,
        chipProps: { style: { backgroundColor: theme.palette.scheduled.main } },
        autoHideDuration: 30000,
      }
      snackbar.showMessage(busySnackbarConfig)

      try {
        await downloadActionExport(baseQueryVariables, {
          onProgress: ({ loaded }) => {
            snackbar.showMessage({
              ...busySnackbarConfig,
              message: (
                <>
                  Downloading (<FormatBytes bytes={loaded} backets decimalPlaces={loaded > 1000000 ? 1 : 0} />)
                </>
              ),
            })
          },
        })
        snackbar.showMessage({ message: "Download ready", icon: <Icon name="download" /> })
      } catch (error) {
        snackbar.showMessage({ message: "Download failed", icon: <Icon name="download" />, color: "secondary" })
      }
    },
  }))

  useEffect(() => {
    setOffsetLimit({ offset: 0, limit: defaultLimit })
  }, [gt, lt, locations, groups, defaultLimit])

  const handlePageChange = (_, page) => {
    setOffsetLimit({
      offset: page * limit,
      limit,
    })
  }

  const items = useMemo(() => {
    if (!listData) {
      return []
    }

    const groupedByDate = listData?.report.actions.locations.detail.slice(offset, offset + limit).reduce(
      (acc, item) => {
        const itemDate = moment(item.createdAt).format("YYYY-MM-DD")

        if (acc.date !== itemDate) {
          acc.items.push({
            type: "separator",
            value: itemDate,
          })
          acc.date = itemDate
        }

        acc.items.push({
          type: "job",
          value: item,
        })

        return acc
      },
      {
        date: null,
        items: [],
      },
    )

    return groupedByDate.items
  }, [limit, listData, offset])

  const count = listData?.report.actions.locations.detail.length || 0
  const countLoading = listLoading

  const handleViewActionClose = () => {
    setViewAction(null)
  }

  return (
    <>
      <ActionViewer
        key={toId(viewAction)}
        open={Boolean(viewAction)}
        id={toId(viewAction)}
        onClose={handleViewActionClose}
      />

      <RowBox justifyContent="space-between" alignItems="flex-start">
        <Box>
          {Boolean(title) && <Box>{title}</Box>}

          {showTopIndicator && (
            <Box mb={2}>
              <Typography variant="body2" color="textSecondary" component="div">
                <TablePageIndicator
                  subject="actions"
                  loading={countLoading}
                  total={count}
                  limit={limit}
                  offset={offset}
                />
              </Typography>
            </Box>
          )}
        </Box>
        {tools}
      </RowBox>

      <Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Job</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Due</TableCell>
              <TableCell>Status</TableCell>
              {showLocation && <TableCell>Location</TableCell>}
              <TableCell>Updated By</TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(({ type, value }, index) => {
              if (type === "separator") {
                const valueDate = moment(value)
                const isToday = valueDate.isSame(moment(), "day")
                return (
                  <SeparatorBarTableRow key={value} colSpan={100}>
                    <FormatDateFormal value={valueDate} formats="alternate" />
                    {isToday && <strong> (Today)</strong>}
                  </SeparatorBarTableRow>
                )
              }

              const action = value

              const nextItem = items[index + 1]
              const cellClass = nextItem?.type === "separator" ? classes.noBottomBorder : undefined

              return (
                <ErrorBoundary key={toId(value)}>
                  <TableRow>
                    <TableCell className={cellClass}>{action.title}</TableCell>

                    <TableCell className={cellClass}>
                      <Box>{action.processName}</Box>
                      <Caption mb={0}>{action.processStepName}</Caption>
                    </TableCell>
                    <TableCell className={cellClass}>
                      <RowBox>
                        <Icon name={`priority-${action.priority || ACTION_PRIORITY.NORMAL}`} throwForUnmapped={false} />
                        <Box ml={1}>{ACTION_PRIORITY_LABELS[action.priority || ACTION_PRIORITY.NORMAL]}</Box>
                      </RowBox>
                    </TableCell>

                    <TableCell className={cellClass}>
                      {action.dueAt ? (
                        <FormatDateTimeCompact
                          value={action.dueAt}
                          compactTodayTimeZone={action.location?.timeZone || null}
                        />
                      ) : (
                        <span>&nbsp;</span>
                      )}
                    </TableCell>
                    <TableCell className={cellClass}>
                      <RowBox>
                        <ActionStatusChip status={action.status || ACTION_STATUS.UNRESOLVED} />
                        <Box ml={1}>{action.statusAt ? <FormatDateTimeCompact value={action.statusAt} /> : null}</Box>
                      </RowBox>
                    </TableCell>

                    {showLocation && <TableCell className={cellClass}>{action.location}</TableCell>}

                    <TableCell className={cellClass}>{action.statusBy?.fullName}</TableCell>

                    <TableCell className={cellClass}>
                      <RowBox justifyContent="flex-end">
                        <TooltipIconButton tooltip="View job" size="small" onClick={() => setViewAction(toId(action))}>
                          <Icon name="view" />
                        </TooltipIconButton>
                      </RowBox>
                    </TableCell>
                  </TableRow>
                </ErrorBoundary>
              )
            })}
          </TableBody>
        </Table>
        {listLoading && items.length === 0 && (
          <ColumnBox alignItems="center" justifyContent="center" py={2}>
            <LoadingSpinner size={60} />
          </ColumnBox>
        )}
        {!listLoading && items.length === 0 && (
          <NoItemsMessage>
            <Trans>No matching actions</Trans>
          </NoItemsMessage>
        )}
        <TablePagination
          count={count}
          rowsPerPage={limit}
          page={offset / limit}
          boxProps={{
            mt: 2,
          }}
          onPageChange={handlePageChange}
          disabled={listLoading}
        >
          <TablePageIndicator
            subject="actions"
            loading={countLoading}
            total={count}
            limit={limit}
            offset={offset}
            hideWhenNone
          />
        </TablePagination>
      </Box>
    </>
  )
}

export default ActionsTableReportNext

import React, { useState, useEffect, useRef } from "react"
import { makeStyles } from "@material-ui/styles"
import {
  DialogActions,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  IconButton,
  Divider,
  Box,
  Hidden,
  Collapse,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined"
import AlternateEmailOutlinedIcon from "@material-ui/icons/AlternateEmailOutlined"
import ScheduleIcon from "@material-ui/icons/Schedule"
import moment from "moment"
import { useParams } from "react-router-dom"
import pluralize from "pluralize"
import { Trans, t } from "@lingui/macro"
import {
  useMutationCreatePost,
  useMutationUpdatePost,
  makeCreatePostOptimisticResponse,
  useLazyQueryPotentialNotifications,
} from "../../data"
import {
  MentionTextInput,
  LinkButton,
  DialogDateTimePicker,
  UsersGroupsAssigner,
  DraftBlockquote,
  Uploader,
  UploadingList,
  UploadingImagesList,
} from ".."
import { usePostUtils, toId, mapToIds, useMountEffect, useFormUtils, useDraft, useAnalytics } from "../../utils"

import { CreatorMaster } from "../Creators"

import { RowBox } from "../Boxes"
import { useAuth } from "../../services"
import { LocationsAssigner, usersGroupsEveryoneItem } from "../Assigners"
import { LoadingSpinner } from "../LoadingSpinner"
import Checkbox from "../Checkbox"
import { TruncateNames } from "../DataDisplay/TruncateNames"

const useStyles = makeStyles((theme) => ({
  settings: {
    minHeight: 68,
  },
  scheduledText: {
    color: theme.palette.scheduled.main,
  },
  dividerSpacer: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    paddingTop: theme.spacing(1),
    fontSize: 14,
  },
  buttonText: {
    fontWeight: "600",
    fontSize: 14,
  },
  postOptions: {
    padding: theme.spacing(3),
  },
  visibleFrom: {},
  alertWarning: {
    backgroundColor: "#FFF4E5",
    "& .MuiAlert-icon": {
      color: theme.palette.warning.amber,
    },
  },
}))

const initialState = {
  title: "",
  content: null,
  job: null,
  knowledge: null,
  hasComments: true,
  hasConfirm: false,
  showPostOptions: false,
  showPostOptionsEl: null,
  locations: [],
  users: [],
  groups: [],
  uploads: [],
  resendNotifications: true,
}

const PostCreator = ({ open, onClose, edit, isInline }) => {
  const classes = useStyles()
  const { set } = useParams()
  const [createPost, { loading: createLoading }] = useMutationCreatePost({ set })
  const [updatePost, { loading: updateLoading }] = useMutationUpdatePost()
  const [loadCount, { data: countsData }] = useLazyQueryPotentialNotifications()
  const { isValid, touched } = useFormUtils()
  const { getScheduleFormattedDate, isDateInFuture } = usePostUtils()
  const analytics = useAnalytics()
  const {
    settings: { locations: userLocations, organisation },
    principal,
  } = useAuth()

  const [title, setTitle] = useState(initialState.title)
  const [content, setContent] = useState(initialState.content)
  const [job, setJob] = useState(initialState.job)
  const [knowledge, setKnowledge] = useState(initialState.knowledge)
  const [hasComments, setHasComments] = useState(initialState.hasComments)
  const [hasConfirm, setHasConfirm] = useState(initialState.hasConfirm)
  const [showPostOptions, setShowPostOptions] = useState(initialState.showPostOptions)
  const [showPostOptionsEl, setShowPostOptionsEl] = useState(initialState.showPostOptionsEl)
  const [showScheduler, setShowScheduler] = useState(false)
  const [hasEveryone, setHasEveryone] = useState(false)
  const [locations, setLocations] = useState(initialState.locations)
  const [users, setUsers] = useState(initialState.users)
  const [groups, setGroups] = useState(initialState.groups)
  const [count, setCount] = useState(0)
  const [uploads, setUploads] = useState(initialState.uploads)
  const [uploading, setUploading] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const contentRef = useRef(null)
  const [visibleFrom, setVisibleFrom] = useState(null)

  const [locationNames, setLocationNames] = useState([])
  const [initialPicked, setInitialPicked] = useState(null)

  const [draft, setDraft, removeDraft, draftUntouched] = useDraft("post_creator", null)
  const [resendNotifications, setResendNotifications] = useState(initialState.resendNotifications)

  const isEdit = edit != null
  const scheduledInFuture = isDateInFuture(visibleFrom)
  const shouldShowResendNotifications = isEdit && !scheduledInFuture

  useMountEffect(() => {
    if (edit) {
      const editLocationIds = mapToIds(edit.locations)

      const noUsersOrGroups = !edit.users.length && !edit.groups.length

      setTitle(edit.title)
      setContent(edit.content)
      setJob(toId(edit.job, true))
      setKnowledge(toId(edit.knowledge, true))
      setHasComments(edit.hasComments)
      setHasConfirm(edit.hasConfirm)
      setLocations([
        ...edit.locations.map((location) => {
          const item = userLocations.find((ul) => toId(ul) === toId(location))
          if (item) return item
          return { id: toId(location), name: "(Hidden)", noAccess: true }
        }),
      ])
      setUsers(edit.users.map((user) => toId(user)))
      setGroups(edit.groups.map((group) => toId(group)))
      setUploads(edit.uploads)
      setHasEveryone(!edit.users.length && !edit.groups.length)
      setVisibleFrom(edit.visibleFrom)

      setLocationNames(
        userLocations.filter((userLocation) => editLocationIds.includes(toId(userLocation))).map(({ name }) => name),
      )
      setInitialPicked(noUsersOrGroups ? [usersGroupsEveryoneItem] : [...edit.users, ...edit.groups])
    } else {
      setLocations(!userLocations.length || userLocations.length > 1 ? [] : [userLocations[0]])
      setLocationNames(!userLocations.length || userLocations.length > 1 ? [] : [userLocations[0].name])
      setInitialPicked([])

      if (draft) {
        draft.title && setTitle(draft.title)
        draft.content && setContent(draft.content)
        draft.uploads && setUploads(draft.uploads)
        draft.visibleFrom && setVisibleFrom(draft.visibleFrom)
      }
    }
  }, [edit, userLocations])

  useEffect(() => {
    loadCount({
      variables: {
        filter: {
          locations: locations.map((location) => toId(location)) || [],
          groups: groups || [],
          users: users || [],
        },
      },
    })
  }, [groups, users, loadCount, locations, organisation])

  useEffect(() => {
    if (countsData) {
      setCount(countsData?.potentialNotificationsCount)
    }
  }, [countsData])

  const handleToggleCreateMenu = ({ currentTarget }) => {
    setShowPostOptionsEl(!showPostOptions ? currentTarget : null)
    setShowPostOptions(!showPostOptions)
  }

  const handleToggleScheduler = () => {
    setShowScheduler(!showScheduler)
    setShowPostOptions(false)
  }

  const handleSchedulerPickedDate = (date) => {
    setVisibleFrom(date.format())
    setShowScheduler(false)
  }

  const handleClose = () => {
    onClose(true)
  }

  const handlePostNow = (e) => {
    handleSubmit(e, moment().add(-1, "seconds").format())
  }
  const handlePostLater = (e) => {
    handleSubmit(e, visibleFrom)
  }

  const handleSubmit = async (e, visibleFromValue) => {
    e.preventDefault()
    if (formValid()) {
      const usersGroups = { users: hasEveryone ? [] : users, groups: hasEveryone ? [] : groups }
      const uploadIds = uploads.map((upload) => upload.id)
      const variables = {
        title,
        content,
        job,
        knowledge,
        hasComments,
        hasConfirm,
        visibleFrom: visibleFromValue,
        locations: locations.map((location) => toId(location)),
        ...usersGroups,
        uploads: uploadIds,
        resendNotifications: shouldShowResendNotifications ? resendNotifications : false,
      }
      if (isEdit) {
        await updatePost({
          variables: {
            id: edit.id,
            ...variables,
          },
        })
      } else {
        const optimisticResponse = makeCreatePostOptimisticResponse({
          post: variables,
          principal,
        })
        await createPost({
          variables,
          optimisticResponse,
        })
        removeDraft()
        analytics.track("Created Post")
      }
      handleClose()
    }
  }

  const handleChange = (setter, name, newValue) => {
    setter(newValue)
    setDraftProperty(name, newValue)
  }

  const setDraftProperty = (prop, value) => {
    if (edit) return
    setDraft((prev) => ({
      ...prev,
      [prop]: value,
    }))
  }

  const handleDiscardDraft = () => {
    removeDraft()
    setTitle(initialState.title)
    setContent(initialState.content)
    setUploads(initialState.uploads)
    setVisibleFrom(null)
  }

  const handleLocationsPickedChanged = (picked) => {
    const pickedLocationIds = mapToIds(picked)

    setLocations([...pickedLocationIds])

    setLocationNames(
      userLocations.filter((userLocation) => pickedLocationIds.includes(toId(userLocation))).map(({ name }) => name),
    )
  }

  const handleUsersGroupsPickedChanged = (picked) => {
    const newHasEveryone = picked.find((p) => p.__typename === "Everyone")
    setHasEveryone(newHasEveryone)
    setUsers(newHasEveryone ? [] : [...mapToIds(picked.filter((p) => p.__typename === "User"))])
    setGroups(newHasEveryone ? [] : [...mapToIds(picked.filter((p) => p.__typename === "Group"))])
  }

  const handleAddMention = (id) => {
    const parts = id.split(":")
    if (parts.length >= 2) {
      const newId = parts[1]
      switch (parts[0]) {
        case "User":
          if (!users.includes(newId)) setUsers([...users, newId])
          break
        case "Group":
          if (!groups.includes(newId)) setGroups([...groups, newId])
          break
        case "MentionableTask":
        case "MentionableProcessJob":
          setJob(newId)
          break
        case "Knowledge":
        case "KnowledgeV2":
          setKnowledge(newId)
          break
        default:
      }
    }
  }

  const handleAtClick = () => {
    setContent(content ? `${content.trimEnd()} @` : "@")
    contentRef.current.focus()
  }

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }
  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }
  const handleItemUploaded = (item) => {
    setUploads((state) => {
      const result = [...state, item]
      setDraftProperty("uploads", [...result])
      return result
    })
  }
  const handleRemoveUpload = (id) => {
    setUploads((state) => {
      const result = [...state.filter((upload) => upload.id !== id)]
      setDraftProperty("uploads", [...result])
      return result
    })
  }
  const handleNotUploading = () => {
    setIsUploading(false)
  }

  const formValid = () => isValid(title, content) && (hasEveryone || users.length || groups.length) && !isUploading

  const isError = (val) => (val != null ? val.length === 0 : false)
  const errorMessage = (val, message) => isError(val) && message

  const submitLoading = createLoading || updateLoading

  const assignToDisabled = !locations || !locations.length

  const isFormValid = formValid()

  const showDraft = !edit && !!draft && draftUntouched

  const showWarning = locations.length >= 3

  const hasCountAndLocations = locations.length > 0 && count > 0

  const TextLocations = () => {
    if (locations.length === 1) return <b>{locationNames[0]}</b>
    return (
      <>
        <b>{locations.length} locations</b> at {organisation?.name}
      </>
    )
  }

  const countAssignee = hasEveryone ? "all" : count

  const form = (
    <>
      <DraftBlockquote show={showDraft} subject="Post" onDiscard={handleDiscardDraft} />

      {locations && userLocations.length > 1 && (
        <LocationsAssigner
          initialPicked={locations}
          onPickedChanged={handleLocationsPickedChanged}
          requireTimeZoneMatch={false}
          cy="PostCreator-locations"
        />
      )}
      {initialPicked && (
        <UsersGroupsAssigner
          title={t`Assign to`}
          placeholder={assignToDisabled ? t`No locations selected` : t`Nobody selected`}
          allStaffLabel={
            <>
              All staff (<TruncateNames names={locationNames} max={3} />)
            </>
          }
          locations={locations.map((location) => toId(location))}
          topBorder={userLocations.length <= 1}
          initialPicked={initialPicked}
          onPickedChanged={handleUsersGroupsPickedChanged}
          disabled={assignToDisabled}
          cy="PostCreator-to"
        />
      )}
      <Box mb={1}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="title"
          label={t`Post title`}
          name="title"
          value={title}
          required
          onChange={(event) => handleChange(setTitle, "title", event.target.value)}
          inputProps={{ "data-cy": "PostCreator-title" }}
        />
      </Box>
      <MentionTextInput
        inputRef={contentRef}
        label={t`Post content`}
        value={content}
        locations={locations}
        height={190}
        onChange={(event) => handleChange(setContent, "content", event.target.value)}
        onAddMention={handleAddMention}
        fullWidth
        multiline
        required
        onBlur={() => touched(content, setContent)}
        error={isError(content)}
        helperText={errorMessage(content, t`Please enter content`)}
        cy="PostCreator-content"
      />
      {uploads && uploads.length > 0 && (
        <Box my={1}>
          <UploadingImagesList
            uploaded={uploads}
            uploading={uploading}
            onRemoveUpload={handleRemoveUpload}
            height={100}
            gridItemProps={{
              xs: 3,
            }}
          />
          <UploadingList uploaded={uploads} uploading={uploading} images={false} onRemoveUpload={handleRemoveUpload} />
        </Box>
      )}
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.settings}>
        <Grid item sm={3} xs={12}>
          <IconButton onClick={handleAtClick} title={t`Link this jobs, articles or people to this post`}>
            <AlternateEmailOutlinedIcon />
          </IconButton>
          <Uploader
            onBeforeUpload={handleBeforeUpload}
            onProgress={handleUploadProgress}
            onItemUploaded={handleItemUploaded}
            onAfterUpload={handleNotUploading}
            onError={handleNotUploading}
            images={false}
            disabled={isUploading}
            title={t`Attach files to this post`}
          />
          <Uploader
            onBeforeUpload={handleBeforeUpload}
            onProgress={handleUploadProgress}
            onItemUploaded={handleItemUploaded}
            onAfterUpload={handleNotUploading}
            onError={handleNotUploading}
            icon={<AddPhotoAlternateOutlinedIcon />}
            documents={false}
            disabled={isUploading}
            title={t`Attach photos / images to this post`}
          />
        </Grid>
        <Grid item sm={9} xs={12} container direction="row" justify-sm="flex-end" justify-xs="flex-start">
          <FormControlLabel
            control={
              <Switch
                checked={hasComments}
                onChange={() => setHasComments(!hasComments)}
                value={hasComments}
                color="primary"
              />
            }
            label={t`Allow comments`}
          />
          <FormControlLabel
            control={
              <Switch
                checked={hasConfirm}
                onChange={() => setHasConfirm(!hasConfirm)}
                value={hasConfirm}
                color="primary"
              />
            }
            label={t`Require confirmation from users`}
          />
        </Grid>
      </Grid>
      <Divider className={classes.dividerSpacer} />
      {shouldShowResendNotifications && (
        <Box mb={2}>
          <Checkbox
            color="primary"
            type="label"
            label={t`Resend notifications to users`}
            checked={resendNotifications}
            onChange={() => setResendNotifications(!resendNotifications)}
          />
        </Box>
      )}
      {/* TODO - enable translation */}
      {!shouldShowResendNotifications && (
        <Box mb={1}>
          <Collapse in={!!isFormValid && hasCountAndLocations && showWarning}>
            <Box>
              <Alert severity="warning" className={classes.alertWarning}>
                <AlertTitle>Heads up!</AlertTitle>
                You're about to post to{" "}
                <b>
                  {countAssignee} staff {pluralize("member", count)}
                </b>{" "}
                at <TextLocations />.{" "}
                {hasConfirm ? (
                  <>
                    All staff that this post is shared with will be required to mark that they have seen and read the
                    post.
                  </>
                ) : null}{" "}
                Are you sure you want to do this?
              </Alert>
            </Box>
          </Collapse>
          <Collapse in={!!isFormValid && hasCountAndLocations && !showWarning}>
            <Box>
              <Alert severity="info">
                <AlertTitle>Post summary</AlertTitle>
                You're about to post to{" "}
                <b>
                  {countAssignee} staff {pluralize("member", count)}
                </b>{" "}
                at <TextLocations />.{" "}
                {hasConfirm ? (
                  <>
                    All staff that this post is shared with will be required to mark that they have seen and read the
                    post.
                  </>
                ) : null}
              </Alert>
            </Box>
          </Collapse>
        </Box>
      )}

      <Hidden smUp>
        <Grid container direction="row" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            {/* TODO make mobile friendly version */}
            {scheduledInFuture && (
              <RowBox mr="auto" className={classes.scheduledText}>
                <ScheduleIcon />
                <Box ml={1}>
                  <Trans>
                    Post scheduled for <strong>{getScheduleFormattedDate(visibleFrom)}</strong>
                  </Trans>
                </Box>
              </RowBox>
            )}
          </Grid>
          <Grid item xs={12}>
            <RowBox>
              <Box mr={2}>
                <LinkButton
                  onClick={handleClose}
                  className={`${classes.button} ${classes.buttonText}`}
                  disabled={submitLoading}
                >
                  <Trans>Cancel</Trans>
                </LinkButton>
              </Box>
              <Box ml="auto">
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  aria-label={scheduledInFuture ? t`Post as scheduled` : t`Post now`}
                  className={classes.button}
                >
                  <Button
                    onClick={scheduledInFuture ? handlePostLater : handlePostNow}
                    className={classes.buttonText}
                    disabled={!isFormValid || submitLoading}
                  >
                    {!submitLoading && (
                      <>{scheduledInFuture ? <Trans>Post as scheduled</Trans> : <Trans>Post now</Trans>}</>
                    )}
                    {submitLoading && <LoadingSpinner size="24px" delay={false} />}
                  </Button>
                  <Button
                    size="small"
                    onClick={handleToggleCreateMenu}
                    className={classes.buttonText}
                    disabled={!isFormValid || submitLoading}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Menu anchorEl={showPostOptionsEl} keepMounted open={showPostOptions} onClose={handleToggleCreateMenu}>
                  {scheduledInFuture && <MenuItem onClick={handleToggleScheduler}>Reschedule</MenuItem>}
                  {scheduledInFuture && <MenuItem onClick={handlePostNow}>Post now</MenuItem>}
                  {!scheduledInFuture && <MenuItem onClick={handleToggleScheduler}>Schedule </MenuItem>}
                </Menu>
              </Box>
            </RowBox>

            <DialogDateTimePicker
              open={showScheduler}
              variant="dialog"
              value={visibleFrom || moment().add(1, "days")}
              onChange={handleSchedulerPickedDate}
              onClose={handleToggleScheduler}
              className={classes.visibleFrom}
              TextFieldComponent={() => null}
              showTodayButton
            />
          </Grid>
        </Grid>
      </Hidden>

      <Hidden xsDown>
        <DialogActions className={classes.actions}>
          {scheduledInFuture && (
            <RowBox mr="auto" className={classes.scheduledText}>
              <ScheduleIcon />
              <Box ml={1}>
                <Trans>
                  Post scheduled for <strong>{getScheduleFormattedDate(visibleFrom)}</strong>
                </Trans>
              </Box>
            </RowBox>
          )}

          <Box mr={2}>
            <LinkButton
              onClick={handleClose}
              className={`${classes.button} ${classes.buttonText}`}
              disabled={submitLoading}
            >
              <Trans>Cancel</Trans>
            </LinkButton>
          </Box>

          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label={scheduledInFuture ? t`Post as scheduled` : t`Post now`}
            className={classes.button}
          >
            <Button
              onClick={scheduledInFuture ? handlePostLater : handlePostNow}
              className={classes.buttonText}
              disabled={!isFormValid || submitLoading}
              data-cy="PostCreator-dialog-post"
            >
              {!submitLoading && <>{scheduledInFuture ? <Trans>Post as scheduled</Trans> : <Trans>Post now</Trans>}</>}
              {submitLoading && <LoadingSpinner size="24px" delay={false} />}
            </Button>
            <Button
              size="small"
              onClick={handleToggleCreateMenu}
              className={classes.buttonText}
              disabled={!isFormValid || submitLoading}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Menu anchorEl={showPostOptionsEl} keepMounted open={showPostOptions} onClose={handleToggleCreateMenu}>
            {scheduledInFuture && (
              <MenuItem onClick={handleToggleScheduler}>
                <Trans>Reschedule</Trans>
              </MenuItem>
            )}
            {scheduledInFuture && (
              <MenuItem onClick={handlePostNow}>
                <Trans>Post now</Trans>
              </MenuItem>
            )}
            {!scheduledInFuture && (
              <MenuItem onClick={handleToggleScheduler}>
                <Trans>Schedule</Trans>
              </MenuItem>
            )}
          </Menu>

          <DialogDateTimePicker
            open={showScheduler}
            variant="dialog"
            value={visibleFrom || moment().add(1, "days")}
            onChange={handleSchedulerPickedDate}
            onClose={handleToggleScheduler}
            className={classes.visibleFrom}
            TextFieldComponent={() => null}
            showTodayButton
          />
        </DialogActions>
      </Hidden>
    </>
  )

  return (
    <CreatorMaster
      id="PostCreator-dialog"
      open={open}
      subject={t`Post`}
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export default PostCreator

import { gql } from "@apollo/client"

const ALL_JOBS_LIST_FIELDS = gql`
  fragment AllJobsListFields on Job {
    id
    type
    title
    process {
      id
      name
    }
    availableFrom
    dueAt
    isOverdue @client
    availableUntil {
      type
      at
    }
    scheduleName
    processName
    displayName
    repeat {
      type
    }
    percentComplete
    hasSubmit
    status {
      id
      completed
      completedAt
      completedBy {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      submitted
      submittedAt
      submittedBy {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      steps {
        id
        completed @client
        completedAt
      }
    }
    numberOfActions
    numberOfJobActions
    numberOfNotes
    location {
      id
      name
      timeZone
    }
    createdAt
    updatedAt
  }
`

export { ALL_JOBS_LIST_FIELDS }

import React from "react"
import { Box, List } from "@material-ui/core"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { NoItemsMessage, LoadingSpinner, FieldSectionHeading, FormatDate, Caption } from "../../components"
import { useQueryUserTrainings } from "../../data"
import { TrainingScoreChip } from "../../components/Chips/TrainingScoreChip"
import { toId } from "../../utils"
import { useAuth } from "../../services"
import { TrainingCurriculum } from "../../components/Training/TrainingCurriculum"

const YourTraining = () => {
  const {
    principal: { userID: user },
  } = useAuth()
  const { data, loading } = useQueryUserTrainings({ variables: { user, filter: { withCompleted: true } } })

  return (
    <Box mb={5}>
      {data && (
        <List disablePadding>
          {[...data.userTrainings.list]
            .sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1))
            .map((userTraining) => (
              <React.Fragment key={toId(userTraining)}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <FieldSectionHeading mb={0.5}>{userTraining.name}</FieldSectionHeading>
                    <Caption>
                      <FormatDate value={userTraining.createdAt} />
                    </Caption>
                  </Box>

                  {userTraining.showUserScore && userTraining.status.score.total > 0 && (
                    <Box ml={1}>
                      <TrainingScoreChip tooltip="Course score" label={`${userTraining.status.score.percent}%`} />
                    </Box>
                  )}
                </Box>

                <TrainingCurriculum training={userTraining} />
              </React.Fragment>
            ))}
        </List>
      )}

      {!data && loading && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}

      {data?.userTrainings.list.length === 0 && (
        <NoItemsMessage>
          <Trans>No training found</Trans>
        </NoItemsMessage>
      )}
    </Box>
  )
}

export default YourTraining

import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Button, Popover, Grid } from "@material-ui/core"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import PopupState, { anchorRef, bindTrigger, bindMenu } from "material-ui-popup-state"
import { t } from "@lingui/macro"
import { GroupOutlinedSelect, LocationOutlinedSelect } from ".."
import { multipleSelectChange, useMountAwareReactiveVar } from "../../utils"
import { hubFiltersVar } from "../../data/hubFiltersVar"
import { HubFiltersCountBox } from "./HubFiltersCount"
import TagsField from "../TextField/TagsField"

const useFiltersPopoverStyles = makeStyles((theme) => ({
  root: {
    width: 512,
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const HubFiltersButton = () => {
  const filtersPopoverClasses = useFiltersPopoverStyles()
  const { groups, locations, tags } = useMountAwareReactiveVar(hubFiltersVar)

  const handleGroupsChanged = (event) => {
    updateState({ groups: [...multipleSelectChange(groups, event)] })
  }

  const handleLocationsChanged = (event) => {
    updateState({ locations: [...multipleSelectChange(locations, event)] })
  }

  const handleRegionChange = (regionLocations) => {
    updateState({ locations: [...multipleSelectChange(locations, regionLocations)] })
  }

  const handleTagsChanged = (_, newTags) => {
    updateState({ tags: [...multipleSelectChange(tags, newTags)] })
  }

  const updateState = (data) => {
    hubFiltersVar({ ...hubFiltersVar(), ...data })
  }

  const tagsValue = tags.includes("all") ? [] : tags

  return (
    <PopupState variant="popover" popupId="bulk-actions">
      {(popupState) => (
        <>
          <Button variant="contained" color="primary" {...bindTrigger(popupState)} ref={anchorRef(popupState)}>
            <FilterListIcon />
            <HubFiltersCountBox ml={0.5} />
          </Button>
          <Popover
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={filtersPopoverClasses}
          >
            <Box p={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                  <GroupOutlinedSelect
                    value={groups}
                    onChange={handleGroupsChanged}
                    multiple
                    usersGroupsOnly
                    data-cy="HubFiltersButton-groups"
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocationOutlinedSelect
                    value={locations}
                    onChange={handleLocationsChanged}
                    onRegionChange={handleRegionChange}
                    multiple
                    data-cy="HubFiltersButton-locations"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TagsField
                    label={tagsValue.length > 0 ? t`Tags` : null}
                    value={tagsValue}
                    onChange={handleTagsChanged}
                    data-cy="HubFiltersButton-tags"
                  />
                </Grid>
              </Grid>
            </Box>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

export { HubFiltersButton }

import { Box } from "@material-ui/core"
import fileDownload from "js-file-download"
import React, { useEffect, useMemo, useState } from "react"
import { DayStepCompletionChart, ReportSectionActionCard, RowBox } from ".."
import { useLazyQueryReportJobsDaysOverview } from "../../data/report/useQueryReportJobsDaysOverview"
import { useAuth } from "../../services"
import { mapToIds, useDateUtils } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"

const sortData = (sort, a, b) => (sort === "day" ? 0 : a.rank > b.rank ? 1 : -1)

const JobStepsDaysOverviewReport = ({ gt, lt, locations, groups, containerHeight = 200, onDayClick }) => {
  const [sort, setSort] = useState("day")
  const { scheduleDateToMoment } = useDateUtils()
  const [load, { data, loading }] = useLazyQueryReportJobsDaysOverview()
  const [fullscreen, setFullscreen] = useState(false)

  const {
    settings: { locations: userLocations },
  } = useAuth()

  useEffect(() => {
    if (!gt) {
      return
    }

    const filterLocations = locations.includes("all") ? mapToIds(userLocations) : locations
    const filterGroups = groups.includes("all") ? null : groups

    load({
      variables: { locations: filterLocations, groups: filterGroups, gt, lt },
    })
  }, [groups, gt, load, locations, lt, userLocations])

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const handleDayClick = ({ argument }) => {
    onDayClick && onDayClick(argument)
  }

  const handleSort = () => {
    setSort(sort === "day" ? "rank" : "day")
  }

  const chartData = useMemo(
    () =>
      data?.reportJobsDaysOverview
        ?.map(
          ({
            day,
            stepsCompleted: completed,
            stepsOverdue: late,
            stepsSkipped: skipped,
            stepsUncompleted: uncompleted,
          }) => ({
            argument: scheduleDateToMoment(day).format("DD MMM YY"),
            completed,
            late,
            uncompleted,
            skipped,
            rank: completed + late + skipped,
          }),
        )
        .filter(({ completed, late, skipped }) => completed || late || skipped)
        .reverse()
        .sort((a, b) => sortData(sort, a, b)) || [],
    [data, scheduleDateToMoment, sort],
  )

  const handleDownloadData = () => {
    const header = ["Day", "On-time", "Late", "Skipped", "Uncompleted"]
    const rows = [...chartData]
      .reverse()
      .sort((a, b) => sortData(sort, a, b))
      .map(({ argument, completed, late, skipped, uncompleted }) =>
        [argument, completed, late, skipped, uncompleted].join(","),
      )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "step-completion-by-day.csv", "text/csv")
  }

  const rotated = chartData?.length > 13

  return (
    <ReportSectionActionCard
      title="Job step completion by day"
      detail="When are job steps being competed?"
      loading={loading}
      onFullscreen={handleFullscreen}
      tools={
        <RowBox>
          <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
          <Box ml={1}>
            <ReportToolsIconButton
              title={sort === "day" ? "Sort by completions #" : "Sort by day"}
              icon={sort === "day" ? "rank" : "calendar"}
              onClick={handleSort}
              disabled={loading}
            />
          </Box>
        </RowBox>
      }
    >
      <DayStepCompletionChart
        data={chartData}
        rotated={rotated}
        barWidth={rotated ? 0.75 : 0.5}
        argumentLabels
        height={rotated ? chartData.length * 32 : containerHeight}
        containerHeight={fullscreen ? null : containerHeight}
        onClick={handleDayClick}
      />
    </ReportSectionActionCard>
  )
}

export default JobStepsDaysOverviewReport

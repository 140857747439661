import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { NoItemsMessage, ActionList } from "../../components"
import { useMutationDeleteAction } from "../../data"
import { toId, useMountEffect } from "../../utils"
import { useLazyQueryAction } from "../../data/actions/useQueryAction"

const SingleAction = ({ onMenuClick }) => {
  const history = useHistory()
  const [deleteAction] = useMutationDeleteAction()

  const [editAction, setEditAction] = useState(null)
  const [loadAction, actionResult] = useLazyQueryAction()
  const [unsubscribes, setUnsubscribes] = useState(null)
  const { set, id, action } = useParams()

  const isSingleDeleted = id === "null"
  const baseUri = `/hub/actions/${set}`

  useMountEffect(() => {
    if (isSingleDeleted) return
    loadAction({ variables: { id } })
  })

  useEffect(() => {
    if (!editAction && id && action === "edit" && actionResult.data) setEditAction(actionResult.data.action.items[0])
  }, [action, editAction, id, actionResult.data])

  useEffect(() => {
    if (actionResult.error) history.push("/hub/actions/unresolved")
  })

  useEffect(() => {
    if (editAction && action !== "edit") setEditAction(null)
  }, [action, editAction])

  useEffect(() => {
    const { called, subscribe } = actionResult
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [actionResult, unsubscribes])

  const handleEditAction = (post) => {
    history.push(`${baseUri}/${toId(post)}/edit`)
  }
  const handleDeleteAction = async ({ id: deleteId }) => {
    await deleteAction({
      variables: {
        id: deleteId,
      },
    })
  }

  const handleToggleSubNav = (event) => {
    onMenuClick && onMenuClick(event)
  }

  const getTitle = () => {
    const item = actionResult.data?.action
    return item ? `Action from ${item.author.fullName}` : actionResult.loading ? "Action from..." : "Action not found"
  }

  return (
    <>
      {!isSingleDeleted && (
        <ActionList
          title={getTitle()}
          actions={actionResult.data?.action ? [actionResult.data?.action] : []}
          {...actionResult}
          onEdit={handleEditAction}
          onDelete={handleDeleteAction}
          onOpenSubNav={handleToggleSubNav}
        />
      )}
      {isSingleDeleted && (
        <NoItemsMessage>
          <Trans context="Deleted or resolved">This action has been removed</Trans>
        </NoItemsMessage>
      )}
    </>
  )
}

export { SingleAction }

import React, { useEffect, useMemo } from "react"
import { Dialog, DialogTitle, DialogContent, Box, Hidden, Chip, makeStyles } from "@material-ui/core"
import { NavLink, useHistory } from "react-router-dom"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { InlineTitleCloser } from "../Creators"
import { ActionCard, Icon, InlineTitle, LinearProgress } from ".."
import { ColumnBox, RowBox } from "../Boxes"
import { useQueryUserTrainings } from "../../data"
import { useAuth } from "../../services"
import { toId, useTraining } from "../../utils"

const closePath = "/knowledge"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: "500",
    color: theme.palette.text.primary,
  },
  caption: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  rightChildren: {
    height: 40,
    overflow: "visible",
  },
  progress: {
    width: 140,
  },
}))

const AllTrainingViewer = ({ isInline }) => {
  const classes = useStyles()
  const { getActiveModuleIndex } = useTraining()
  const history = useHistory()
  const {
    principal: { userID: user },
    hasFeature,
  } = useAuth()
  const linear = !hasFeature("training_nonlinear")
  const { data } = useQueryUserTrainings({ variables: { user } })
  const uncompletedItems = useMemo(
    () => data?.userTrainings.list.filter((userTraining) => !userTraining.status.completedAt),
    [data],
  )

  useEffect(() => {
    const close = () => history.push(closePath)

    if (!user) {
      return close()
    }

    if (!data) {
      return
    }

    const getActiveTraining = () => {
      return data?.userTrainings.list.find((t) => !t.status.completedAt)
    }

    const forwardToTraining = (item) => {
      history.push(`/knowledge/training/${toId(item)}`)
    }

    // no id -> forward to active training
    if (linear) {
      return forwardToTraining(getActiveTraining())
    }
  }, [data, history, user, linear])

  const handleClose = () => {
    history.push(closePath)
  }

  if (!uncompletedItems) {
    return null
  }

  const display = uncompletedItems?.map((userTraining, index) => {
    const { id, name, dueAt, status } = userTraining
    const overdue = dueAt ? moment(dueAt).isBefore(moment()) : false
    const activeModuleIndex = getActiveModuleIndex(userTraining)
    const activeModule = activeModuleIndex > -1 ? status.modules[activeModuleIndex] : null

    return (
      <ActionCard
        key={id}
        cardProps={{
          component: NavLink,
          to: {
            pathname: linear ? `/knowledge/training/${toId(uncompletedItems[0])}` : `/knowledge/training/${toId(id)}`,
          },
        }}
        overdue={overdue}
        isScheduled={index > 0 && linear}
        title={
          <RowBox className={classes.title} alignItems="flex-start">
            <Icon name="training" />
            <ColumnBox ml={1}>
              <Box mb={0.5}>{name}</Box>
              <Box className={classes.caption}>
                {activeModuleIndex + 1} of {status.modules.length}: {activeModule ? activeModule.name : "Complete"}
              </Box>
            </ColumnBox>
          </RowBox>
        }
        rightChildren={
          <Box
            mr="auto"
            display="flex"
            flexDirection="row"
            alignSelf="center"
            alignItems="center"
            justifyItems="center"
            className={classes.rightChildren}
          >
            <Hidden xsDown>
              {index === 0 && linear && (
                <Box className={classes.progress} mr={1}>
                  <LinearProgress value={status.percentComplete} showValue overdue={overdue} />
                </Box>
              )}

              {!linear && (
                <Box className={classes.progress} mr={1}>
                  <LinearProgress value={status.percentComplete} showValue overdue={overdue} />
                </Box>
              )}

              {index > 0 && linear && <Chip label={<Trans context="Next in list">Coming up</Trans>} size="small" />}
            </Hidden>
          </Box>
        }
      >
        <Hidden smUp>
          {index === 0 && linear && <LinearProgress value={status.percentComplete} showValue overdue={overdue} />}

          {!linear && <LinearProgress value={status.percentComplete} showValue overdue={overdue} />}

          {index > 0 && linear && <Chip label={<Trans context="Next in list">Coming up</Trans>} size="small" />}
        </Hidden>
      </ActionCard>
    )
  })

  if (isInline) {
    return (
      <>
        <InlineTitle>
          <Trans>Your Training</Trans>
          <InlineTitleCloser onClose={handleClose} />
        </InlineTitle>
        <Box px={2} py={2} data-cy="TrainingViewer-display">
          {display}
        </Box>
      </>
    )
  }
  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        <RowBox>
          <Box>
            <Trans>Your Training</Trans>
          </Box>
        </RowBox>
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Box pb={1}>{display}</Box>
      </DialogContent>
    </Dialog>
  )
}

export { AllTrainingViewer }

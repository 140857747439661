import React, { useState, useEffect } from "react"
import Config from "react-global-configuration"
import { Box, Divider, TextField, Grid, Button, makeStyles, Hidden, useTheme, useMediaQuery } from "@material-ui/core"
import { AccountBox, EmailOutlined } from "@material-ui/icons"
import { Alert } from "@material-ui/lab"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { useAuth } from "../../../services"
import {
  FieldSectionHeading,
  LinkButton,
  UploaderProfileImage,
  useSnackbar,
  LoadingSpinner,
  UserPanel,
  PinInput,
} from "../../../components"
import { useMutationUpdateSelf, useQueryAdminableUser } from "../../../data"
import { useMutationCancelUsernameChange } from "../../../data/users/useMutationCancelUsernameChange"
import { useMountAwareReactiveVar } from "../../../utils"
import { userStateVar } from "../../../data/users/userStateVar"
import useUnmount from "../../../utils/useUnmount"
import EmailChangeButton from "./EmailChangeButton"
import { useMutationResendUsernameChange } from "../../../data/users/useMutationResendUsernameChange"
import SuccessfulEmailChangeDialog from "./SuccessfulEmailChangeDialog"
// import { useFormUtils } from '../../utils'

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: 80,
    height: 80,
    fontSize: 24,
  },
  image: {
    backgroundColor: theme.palette.shaded.main,
    borderRadius: 4,
  },
  xsUserPanel: {
    width: "100% !important",
  },
}))

const Profile = () => {
  const classes = useStyles()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const store = useMountAwareReactiveVar(userStateVar)
  // const { isValid, isError, getErrorMessage, debounceTouched } = useFormUtils()
  const { principal, updatePrincipal } = useAuth()
  const { pinLength } = Config.get("auth")
  const { data, loading: adminableUserLoading } = useQueryAdminableUser({
    variables: { id: principal.userID, organisation: principal.organisation },
  })
  const [updateSelf] = useMutationUpdateSelf()
  const [cancelUsernameChange, { loading: cancelUsernameChangeLoading }] = useMutationCancelUsernameChange()
  const [resendUsernameChange, { loading: resendUsernameChangeLoading }] = useMutationResendUsernameChange()
  const snackbar = useSnackbar()

  const { external } = principal

  const [loading, setLoading] = useState(false)
  const [touched, setTouched] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [avatar, setAvatar] = useState(null)
  const [pin, setPin] = useState("")
  const [clearPin, setClearPin] = useState(false)
  const [isHidden, setIsHidden] = useState(store?.openEmailChangeDialog ?? false)
  const [openSuccessfulDialog, setOpenSuccessfulDialog] = useState(false)

  const hideAlert = () => {
    if (store?.usernameChange?.status) {
      userStateVar({
        ...store,
        usernameChange: {},
      })
    }
  }

  const handleSave = async () => {
    try {
      setLoading(true)
      const variables = { firstName, lastName, avatar: avatar && avatar.id }
      if (pin) variables.pin = pin
      await updateSelf({ variables })
      updatePrincipal({ firstName, lastName, avatar: avatar && avatar.key })
      setLoading(false)
      setTouched(false)
      setPin("")
      setClearPin(true)
      snackbar.showMessage({ message: "Profile updated", icon: <AccountBox /> })
    } catch (error) {
      snackbar.showMessage({ message: error.message })
    }
    hideAlert()
  }

  const handleCancel = () => {
    const { adminableUser: user } = data
    setFirstName(user.firstName)
    setLastName(user.lastName)
    setEmail(user.email)
    setAvatar(user.avatar)
    setPin("")
    setTouched(false)
  }

  const handleChange = (set, oldValue, newValue) => {
    if (newValue !== oldValue) {
      if (!touched) setTouched(true)
      set(newValue)
    }
  }

  const handleAvatarChange = (file) => {
    if (file) {
      setAvatar(file.upload)
    } else {
      setAvatar(null)
    }
    setTouched(true)
  }

  const handleOpenEmailChangeDialog = () => {
    if (xs) {
      setIsHidden(true)
    } else {
      userStateVar({
        ...store,
        openEmailChangeDialog: true,
        ...(store?.usernameChange?.status && { usernameChange: {} }),
      })
    }
  }

  const handleCancelUsernameChange = async () => {
    try {
      await cancelUsernameChange()
      userStateVar({
        ...store,
        isPendingUsernameChange: false,
      })
      snackbar.showMessage({ message: "Your email address change has been cancelled.", icon: <EmailOutlined /> })
    } catch (error) {
      snackbar.showMessage({ message: error.message })
    }
  }

  const handleResendVerification = async () => {
    const resp = await resendUsernameChange()
    if (!resp?.errors?.length) {
      setOpenSuccessfulDialog(true)
    }
  }

  useEffect(() => {
    if (data) {
      const { adminableUser: user } = data
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setAvatar(user.avatar)
      if (user?.usernameChange) {
        const { sentAt, expiresAt } = user.usernameChange
        const isExpired = moment().isAfter(expiresAt)
        userStateVar({
          ...store,
          isPendingUsernameChange: sentAt && !isExpired,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (clearPin) setClearPin(false)
  }, [clearPin])

  useUnmount(() => !xs && hideAlert())

  useEffect(() => {
    if (xs && isHidden) {
      userStateVar({
        ...store,
        openEmailChangeDialog: true,
        ...(store?.usernameChange?.status && { usernameChange: {} }),
      })
    }

    return () => {
      if (xs && !isHidden) {
        hideAlert()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden, xs])

  return (
    <>
      <SuccessfulEmailChangeDialog open={openSuccessfulDialog} onClose={() => setOpenSuccessfulDialog(false)} />

      <Box mb={5}>
        {((!xs && !data) || (xs && adminableUserLoading)) && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {((!xs && data) || (xs && !adminableUserLoading)) && (
          <>
            {Object.entries(store?.usernameChange).length > 0 && (
              <Box pb={1}>
                <Alert severity={store.usernameChange?.status ?? "success"}>{store.usernameChange?.message}</Alert>
              </Box>
            )}

            <Hidden lgUp xsDown>
              <UserPanel user={data.adminableUser} layout="landscape" mb={2} />
            </Hidden>
            <Hidden smUp>
              <UserPanel user={data.adminableUser} layout="portrait" mb={2} className={classes.xsUserPanel} />
            </Hidden>

            <FieldSectionHeading size="large">
              <Trans>Personal information</Trans>
            </FieldSectionHeading>
            <Box mb={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={<Trans>First name</Trans>}
                    value={firstName}
                    onChange={(event) => handleChange(setFirstName, firstName, event.target.value)}
                    className={classes.textField}
                    required
                    disabled={loading || external}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={<Trans>Last name</Trans>}
                    value={lastName}
                    onChange={(event) => handleChange(setLastName, lastName, event.target.value)}
                    className={classes.textField}
                    required
                    disabled={loading || external}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={<Trans>Email address</Trans>}
                    value={email}
                    onChange={(event) => handleChange(setEmail, email, event.target.value)}
                    className={classes.textField}
                    required
                    disabled={loading || true}
                  />
                  {!external && (
                    <EmailChangeButton
                      isPendingUsernameChange={store?.isPendingUsernameChange}
                      onChangeEmailAddress={handleOpenEmailChangeDialog}
                      onCancelEmailAddressChange={handleCancelUsernameChange}
                      onResendVerification={handleResendVerification}
                      isLoading={cancelUsernameChangeLoading || resendUsernameChangeLoading}
                    />
                  )}
                  {external && (
                    <small>
                      <Trans>Your user is managed externally, editing is disabled for some fields</Trans>
                    </small>
                  )}
                </Grid>
              </Grid>
            </Box>

            <FieldSectionHeading size="large">
              <Trans>Profile image</Trans>
            </FieldSectionHeading>
            <UploaderProfileImage
              avatarProps={{ firstName, lastName, avatar }}
              subject="photo"
              onChange={handleAvatarChange}
            />

            <FieldSectionHeading size="large">
              <Trans>Change tablet PIN</Trans>
            </FieldSectionHeading>
            <Box mb={3}>
              <PinInput
                length={pinLength}
                onChange={(value) => handleChange(setPin, pin, value)}
                className={classes.pin}
                allowShow
                password
                clear={clearPin}
                display={null}
                justifyContent={null}
              />
            </Box>
          </>
        )}
      </Box>

      <Divider />

      <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mt={2}>
        <Box mr={3}>
          <LinkButton
            onClick={handleCancel}
            className={`${classes.button} ${classes.buttonText}`}
            disabled={!touched || loading}
          >
            <Trans>Cancel</Trans>
          </LinkButton>
        </Box>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={!touched || loading}>
          <Box px={2}>
            <Trans>Save</Trans>
          </Box>
        </Button>
      </Box>
    </>
  )
}

export default Profile

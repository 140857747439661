import React from "react"
import { Tabs, Grid, Divider } from "@material-ui/core"
import { Redirect, useParams, Switch } from "react-router"
import { Trans } from "@lingui/macro"
import { AreaHeader, Content, DisplayLimiter, NavLinkTab, PrivateRoute } from "../../components"
import { useQuerySettings } from "../../data"
import { Overview } from "./next/Overview"
import Jobs from "./next/Jobs"
import Actions from "./next/Actions"
import People from "./next/People"
import Training from "./next/Training"
import Audits from "./next/Audits"
import { useAuth } from "../../services"

import OldOverview from "./OldOverview"
import OldJobs from "./OldJobs"
import OldActions from "./OldActions"
import OldPeople from "./OldPeople"
import OldTraining from "./OldTraining"
import OldAudits from "./OldAudits"

const Reports = () => {
  const { page, days, locations, groups } = useParams()
  const { lang } = useQuerySettings()
  const { hasFeature } = useAuth()

  const filters = `/${days || 7}/${locations || "all"}/${groups || "all"}`

  const NavTab = ({ value }) => (
    <NavLinkTab label={getHeading(value)} base="/reports/" value={value} filters={filters} fullWidth={false} />
  )

  const hasReportsNext = hasFeature("reports_next")

  const OverviewComponent = hasReportsNext ? Overview : OldOverview
  const JobsComponent = hasReportsNext ? Jobs : OldJobs
  const ActionsComponent = hasReportsNext ? Actions : OldActions
  const AuditsComponent = hasReportsNext ? Audits : OldAudits
  const PeopleComponent = hasReportsNext ? People : OldPeople
  const TrainingComponent = hasReportsNext ? Training : OldTraining

  return (
    <DisplayLimiter>
      <AreaHeader title={lang.area.reports} titleIcon="reports-light" beta />

      <Content full start mb={5}>
        <Grid item xs>
          <Tabs
            variant="standard"
            value={page || "overview"}
            indicatorColor="primary"
            textColor="primary"
            aria-label="Select reporting area"
          >
            <NavTab value="overview" />
            <NavTab value="jobs" />
            <NavTab value="actions" />
            {hasFeature("audit_reports") && <NavTab value="audits" />}
            <NavTab value="people" />
            {hasFeature("training") && <NavTab value="training" />}
          </Tabs>

          <Divider />

          <Switch>
            <PrivateRoute path="/reports/overview/:days/:locations/:groups/:tags" component={OverviewComponent} />
            <PrivateRoute path="/reports/overview/:days/:locations/:groups" component={OverviewComponent} />
            <PrivateRoute path="/reports/jobs/:days/:locations/:groups/:process/:tags" component={JobsComponent} />
            <PrivateRoute path="/reports/jobs/:days/:locations/:groups/:process" component={JobsComponent} />
            <PrivateRoute path="/reports/jobs/:days/:locations/:groups" component={JobsComponent} />
            <PrivateRoute exact path="/reports/jobs" component={JobsComponent} />
            <PrivateRoute
              path="/reports/actions/:days/:locations/:groups/:process/:tags"
              component={ActionsComponent}
            />
            <PrivateRoute path="/reports/actions/:days/:locations/:groups/:process" component={ActionsComponent} />
            <PrivateRoute path="/reports/actions/:days/:locations/:groups" component={ActionsComponent} />
            <PrivateRoute exact path="/reports/actions" component={ActionsComponent} />
            <PrivateRoute path="/reports/audits/:days/:locations/:groups/:process/:tags" component={AuditsComponent} />
            <PrivateRoute path="/reports/audits/:days/:locations/:groups/:process" component={AuditsComponent} />
            <PrivateRoute path="/reports/audits/:days/:locations/:groups" component={AuditsComponent} />
            <PrivateRoute exact path="/reports/audits" component={AuditsComponent} />
            <PrivateRoute path="/reports/people/:days/:locations/:groups/:user/:tags" component={PeopleComponent} />
            <PrivateRoute path="/reports/people/:days/:locations/:groups/:user" component={PeopleComponent} />
            <PrivateRoute path="/reports/people/:days/:locations/:groups" component={PeopleComponent} />
            <PrivateRoute exact path="/reports/people" component={PeopleComponent} />
            <PrivateRoute
              exact
              path="/reports/training/:days/:locations/:groups/:tags"
              component={TrainingComponent}
              feature="training"
            />
            <PrivateRoute
              exact
              path="/reports/training/:days/:locations/:groups"
              component={TrainingComponent}
              feature="training"
            />
            <Redirect to="/reports/overview/7/all/all/all" />
          </Switch>
        </Grid>
      </Content>
    </DisplayLimiter>
  )
}

const getHeading = (page) => {
  switch (page) {
    case "overview":
      return <Trans>Overview</Trans>
    case "locations":
      return <Trans>Locations</Trans>
    case "jobs":
      return <Trans>Jobs</Trans>
    case "actions":
      return <Trans>Actions</Trans>
    case "audits":
      return <Trans>Scoring</Trans>
    case "groups":
      return <Trans>Areas of work</Trans>
    case "people":
      return <Trans>People</Trans>
    case "training":
      return <Trans>Training</Trans>
    default:
      return <Trans>Loading...</Trans>
  }
}

export default Reports

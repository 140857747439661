import React, { useEffect, useState } from "react"
import { Grid, Box } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import { LabelsCategory, LoadingSpinner, NoItemsMessage } from "../../components"
import { useQueryLabelCategories, useQueryLabels } from "../../data"
import { toId } from "../../utils"
import SearchInput from "../../components/SearchInput"
import { useAuth } from "../../services"
import { LabelsItemList } from "../../components/Labels/LabelsItemList"

const CategoryLabels = ({ searchText, onEditItem, onDeleteItem }) => {
  const { location } = useAuth()
  const params = useParams()

  const { data, loading, subscribe } = useQueryLabels({
    variables: { filter: { location: toId(location), category: params.id } },
  })

  const [unsubscribes, setUnsubscribes] = useState(null)

  useEffect(() => {
    if (!unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [subscribe, unsubscribes, setUnsubscribes])

  const filteredLabels =
    data?.labels.filter((item) => {
      const isSearchMatch = !searchText || item.title.toLowerCase().includes(searchText)
      const isCategoryMatch = toId(item.category) === params.id
      return isSearchMatch && isCategoryMatch
    }) || []

  return (
    <>
      {loading && !data && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}
      <Grid item>
        <LabelsItemList items={filteredLabels} onEditItem={onEditItem} onDeleteItem={onDeleteItem} />
      </Grid>
      {!loading && filteredLabels.length === 0 && (
        <Grid item sm={12} xs={12}>
          <Box>
            <NoItemsMessage>
              <Trans>No matching labels at this location</Trans>
            </NoItemsMessage>
          </Box>
        </Grid>
      )}
    </>
  )
}

const Category = ({ kiosk, device, onEditItem, onDeleteItem, onEditCategory, onDeleteCategory }) => {
  const { id } = useParams()
  const { data, loading } = useQueryLabelCategories({ pollInterval: 30000 })
  const [category, setCategory] = useState(null)
  const [searchText, setSearchText] = useState("")

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  useEffect(() => {
    if (data && id) {
      const result = data.labelCategories.find((c) => c.id === id)
      if (result) setCategory(result)
    }
  }, [data, id])

  return (
    <>
      {category && (
        <LabelsCategory
          kiosk={kiosk}
          device={device}
          category={category}
          onEdit={onEditCategory}
          onDelete={onDeleteCategory}
        />
      )}

      <Grid container direction="row">
        <Grid item sm={12} xs={12}>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Box flexGrow={1}>
              <SearchInput
                placeholder={t`Search labels`}
                onChange={handleFilter}
                debounce={300}
                boxProps={{ pl: 0, pr: 0, mb: 1 }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container direction="column">
        {loading && !data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        <CategoryLabels
          searchText={searchText}
          onHandleFilter={handleFilter}
          onEditItem={onEditItem}
          onDeleteItem={onDeleteItem}
        />
      </Grid>
    </>
  )
}

export default Category

import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/styles"
import { TableCell, TableRow, Box, IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import { EditOutlined } from "@material-ui/icons"
import { SpeedDialAction } from "@material-ui/lab"
import { useParams, NavLink, useHistory } from "react-router-dom"
import useAsyncEffect from "use-async-effect"
import fileDownload from "js-file-download"
import { parse } from "json2csv"
import moment from "moment"
import { Trans, t } from "@lingui/macro"
import {
  AreaHeader,
  Content,
  Checkbox,
  SearchInput,
  LoadingSpinner,
  FloatingSpeedDial,
  Icon,
  UserStatusChip,
  PersonViewer,
  PersonCreator,
  MultiplePersonCreator,
  NoItemsMessage,
  DisplayLimiter,
  RowBox,
  HelpBanner,
  PersonTrainingStatus,
  PersonAccreditationStatus,
  DesktopPeopleButton,
  UserAvatar,
  FiltersPanel,
  TooltipIcon,
  useSnackbar,
  TimeAgo,
  ColumnBox,
  Caption,
  ErrorBoundary,
} from "../../components"
import {
  useQueryAdminableUserCounts,
  useMutationUpdateUsers,
  USER_STATUS,
  useMutationResendInvites,
  useLazyQueryAdminableUser,
  useLazyQueryAdminableUsersAndCount,
  USER_ACCREDITATIONS_STATUS,
  USER_ACCREDITATIONS_STATUS_LABELS,
} from "../../data"
import { useAuth } from "../../services"
import { toId } from "../../utils"
import { TruncateNames } from "../../components/DataDisplay/TruncateNames"
import { ItemCountHeader } from "../../components/AreaHeader/ItemCountHeader"
import { TableVirtualizer } from "../../components/Lists/TableVirtualizer"

const useStyles = makeStyles((theme) => ({
  fullName: {
    fontSize: 16,
    color: theme.palette.text.primary,
    lineHeight: "20px",
  },
  roles: {
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
  tableHead: {
    position: "sticky",
    top: theme.dimensions.header.height,
    backgroundColor: "white",
    zIndex: 1000,
  },
  tableCellLeft: {
    whiteSpace: "nowrap",
  },
  tableCellCenter: {
    textAlign: "center",
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
  supportInfo: {
    fontSize: 20,
    cursor: "pointer",
  },
  supportInfoRef: {
    display: "flex",
    alignSelf: "center",
  },
}))

const initialState = {
  filters: [
    { type: "status", value: "active" },
    { type: "status", value: "invited" },
  ],
}

const compileFilters = (searchText, filters, status = []) => ({
  filter: {
    searchText,
    locations: [],
    roles: [],
    groups: [],
    status,
    trainingStatus: [],
    ...filters.reduce((acc, filter) => {
      acc[filter.type] = [...(acc[filter.type] || []), filter.value]
      return acc
    }, {}),
  },
})

const People = () => {
  const { id, action } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [loadAdminableUsers, { data, loading: adminableUsersLoading, refetch, loadMore }] =
    useLazyQueryAdminableUsersAndCount()
  const { data: countsData, refetch: refetchCounts } = useQueryAdminableUserCounts()
  const [loadAdminableUser] = useLazyQueryAdminableUser()
  const theme = useTheme()
  const isInline = useMediaQuery(theme.breakpoints.down("md"))
  const { hasFeature } = useAuth()

  const [updateUsers] = useMutationUpdateUsers()
  const [resendInvites] = useMutationResendInvites()

  const [callRefetch, setCallRefetch] = useState(false)
  const [openSpeedDial, setOpenSpeedDial] = useState(false)
  const [all, setAll] = useState(false)
  const [allCount, setAllCount] = useState(0)
  const [some, setSome] = useState(false)
  const [checked, setChecked] = useState({})
  const [checkedCount, setCheckedCount] = useState(0)
  const [items, setItems] = useState([])
  const [view, setView] = useState(null)
  const [edit, setEdit] = useState(null)

  const [searchText, setSearchText] = useState("")
  const [searchTextLoading, setSearchTextLoading] = useState(false)
  const [filters, setFilters] = useState(initialState.filters)

  const [bulkAction, setBulkAction] = useState("")
  const [bulkActionParams, setBulkActionParams] = useState([])

  useEffect(() => {
    if (data?.adminableUsers) {
      const users = data.adminableUsers.list.map((user) => ({ ...user }))
      setChecked((prev) => {
        users.forEach((user) => (prev[toId(user)] = prev[toId(user)] || false))
        return { ...prev }
      })
      setItems([...users])
      setAllCount(data.adminableUsers.count)
    }
  }, [data, id, action])

  useAsyncEffect(async () => {
    await loadAdminableUsers({
      variables: {
        ...compileFilters(searchText, filters),
      },
    })
    if (searchTextLoading) {
      setSearchTextLoading(false)
    }
  }, [filters, loadAdminableUsers, searchText])

  useEffect(() => {
    if (callRefetch) {
      setCallRefetch(false)
      refetch({
        ...compileFilters(searchText, filters),
      })
      refetchCounts()
    }
  }, [callRefetch, filters, refetch, refetchCounts, searchText])

  useAsyncEffect(async () => {
    if (id && !id.startsWith("new") && data) {
      let user = data.adminableUsers.list.find((u) => toId(u) === id)
      if (!user) {
        const loadResult = await loadAdminableUser({ variables: { id } })
        if (loadResult?.data) {
          user = loadResult.data.adminableUser
        }
      }
      if (user) {
        if (action === "edit") {
          setView(null)
          setEdit(user)
        } else {
          setView(toId(user))
        }
      }
    }
  }, [action, data, id])

  useEffect(() => {
    const newAll = items.every((item) => checked[toId(item)])
    if (newAll !== all) {
      setAll(newAll)
    }
  }, [all, checked, items])

  useEffect(() => {
    const newSome = !items.every((item) => checked[toId(item)]) && items.some((item) => checked[toId(item)])
    if (some !== newSome) {
      setSome(newSome)
    }
  }, [checked, items, some])

  useEffect(() => {
    const newCheckedCount = items.filter((item) => checked[toId(item)]).length
    setCheckedCount(newCheckedCount)
  }, [checked, items])

  const hasTraining = hasFeature("training")
  const hasAccreditation = hasFeature("accreditation")

  const handleToggleSpeedDial = () => {
    setOpenSpeedDial(!openSpeedDial)
  }

  const handleCreate = (type) => {
    if (type === "single") history.push("/people/new")
    if (type === "multiple") history.push("/people/new-multiple")
  }

  const handleClose = (isBack) => {
    if (!view && !edit) {
      setCallRefetch(true)
      return history.push("/people")
    }
    if (view) {
      setView(null)
      return history.push("/people")
    }
    if (edit) {
      const editId = toId(edit)
      setEdit(null)
      if (isBack) {
        return history.push(`/people/${editId}`)
      }
      return history.push("/people")
    }
  }

  const handleChangeSearchText = (text) => {
    if (text === searchText) {
      return
    }
    setSearchTextLoading(true)
    setSearchText(text)
    clearBulkAction()
  }

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value)
    setBulkActionParams([])
  }

  const handleBulkActionParamsChange = (event) => {
    setBulkActionParams(event.target.value)
  }

  const handleBulkActionApply = async () => {
    const ids = items.filter((item) => checked[toId(item)]).map((item) => item.id)
    let result = null
    switch (bulkAction) {
      case "inactive":
      case "active":
        result = await updateUsers({
          variables: { ids, input: { status: bulkAction === "active" ? USER_STATUS.ACTIVE : USER_STATUS.INACTIVE } },
        })
        break
      case "invites":
        result = await resendInvites({ variables: { ids } })
        break
      default:
        result = await updateUsers({ variables: { ids, input: { [bulkAction]: bulkActionParams } } })
    }

    // clear bulk action if no errors
    if (result && !result.errors) {
      clearBulkAction()
    }
  }

  const clearBulkAction = () => {
    if (bulkAction) setBulkAction("")
    if (bulkActionParams.length) setBulkActionParams([])
    handleUncheckAll()
  }

  const handleDownload = () => {
    const trainingFields = hasTraining
      ? ["trainingStatus", "trainingPercentComplete", "trainingDueAt", "trainingLastActiveAt"]
      : []

    const accreditationFields = hasAccreditation
      ? ["accreditationStatus", "accreditationStatusAt", "accreditationStatusLabel"]
      : []

    const fields = [
      "id",
      "firstName",
      "lastName",
      "email",
      "roles",
      "locations",
      "areasOfWork",
      ...trainingFields,
      ...accreditationFields,
      "status",
      "activeAt",
    ]

    const selectedItems = items
      .filter((item) => checked[toId(item)])
      .map(({ roles, locations, groups, training, accreditation, ...rest }) => ({
        roles: roles.length ? roles.map((item) => item.friendlyName).join(", ") : "User",
        locations: locations.map((item) => item.name).join(", "),
        areasOfWork: groups.map((item) => item.name).join(", "),
        trainingStatus: training.status,
        trainingPercentComplete: training.percentComplete,
        trainingDueAt: training.dueAt,
        trainingLastActiveAt: training.lastActiveAt,
        accreditationStatus: accreditation.status,
        accreditationStatusAt: accreditation.statusAt,
        accreditationStatusLabel: accreditationStatusLabel(accreditation),
        ...rest,
      }))

    try {
      const csv = parse(selectedItems, { fields })
      fileDownload(csv, "download.csv", "text/csv")
    } catch (error) {
      snackbar.showMessage({ message: error.message, color: "secondary", icon: <Icon name="download-failed" /> })
    }
  }

  const accreditationStatusLabel = ({ status, statusAt }) => {
    const label = USER_ACCREDITATIONS_STATUS_LABELS[status]

    switch (status) {
      case USER_ACCREDITATIONS_STATUS.WARNING:
      case USER_ACCREDITATIONS_STATUS.EXPIRED:
      case USER_ACCREDITATIONS_STATUS.AWAITING:
        return `${label} ${moment(statusAt).fromNow()}`
      case USER_ACCREDITATIONS_STATUS.COMPLIANT:
        return "Up to date"
      default:
        return "No accreditations"
    }
  }

  const handleCheckAll = () => {
    if (items) {
      setChecked((prev) => {
        if (all) items.forEach((user) => (prev[toId(user)] = false))
        else items.forEach((user) => (prev[toId(user)] = true))
        return { ...prev }
      })
    }
  }

  const handleUncheckAll = () => {
    if (items) {
      setChecked((prev) => {
        items.forEach((user) => (prev[toId(user)] = false))
        return { ...prev }
      })
    }
  }

  const handleCheckUser = (user) => {
    const newChecked = !checked[toId(user)]
    setChecked((prev) => {
      prev[toId(user)] = newChecked
      return { ...prev }
    })
  }

  const handleFilterChange = (type, values) => {
    setFilters((prev) => {
      const next = prev.filter((filter) => filter.type !== type)
      next.push(...values.map((value) => ({ type, value })))
      return next
    })
    handleUncheckAll()
  }

  const handleClearFilters = () => {
    setFilters([])
    handleUncheckAll()
  }

  const handleFilterDelete = ({ type, value }) => {
    setFilters((prev) => prev.filter((filter) => !(filter.type === type && filter.value === value)))
    handleUncheckAll()
  }

  const handleTrainingClick = (user) => {
    history.push(`/people/${toId(user)}/training`)
  }

  const handleAccreditationsClick = (user) => {
    history.push(`/people/${toId(user)}/accreditation`)
  }

  const any = all || some

  const loading = adminableUsersLoading

  return (
    <DisplayLimiter>
      <div>
        {Boolean(view) && <PersonViewer id={view} isInline={isInline} onClose={handleClose} />}

        {edit && (
          <PersonCreator onClose={handleClose} edit={edit} isInline={isInline} onRefetch={() => setCallRefetch(true)} />
        )}

        {id === "new" && <PersonCreator onClose={handleClose} isInline={isInline} />}

        {id === "new-multiple" && <MultiplePersonCreator onClose={handleClose} isInline={isInline} />}

        {(!isInline || (isInline && !id)) && (
          <>
            <AreaHeader
              title={<Trans>People</Trans>}
              titleIcon="people-light"
              desktopButtons={<DesktopPeopleButton onCreate={handleCreate} />}
              mobileButtons={
                !id && (
                  <FloatingSpeedDial
                    onClose={handleToggleSpeedDial}
                    onOpen={handleToggleSpeedDial}
                    open={openSpeedDial}
                    data-cy="Button-create-person"
                  >
                    <SpeedDialAction
                      tooltipTitle={t`Single person`}
                      icon={<Icon name="person" />}
                      tooltipOpen
                      onClick={() => handleCreate("single")}
                    />
                    <SpeedDialAction
                      tooltipTitle={t`Multiple people`}
                      icon={<Icon name="people" />}
                      tooltipOpen
                      onClick={() => handleCreate("multiple")}
                    />
                  </FloatingSpeedDial>
                )
              }
            />

            <Content full start>
              <HelpBanner name="people" />

              <Box mb={4}>
                <ItemCountHeader
                  loading={!countsData}
                  count={countsData?.adminableUserCounts?.active}
                  subject="active staff"
                />

                <SearchInput
                  placeholder={t`Search people`}
                  initialValue={searchText}
                  debounce={300}
                  onChange={handleChangeSearchText}
                  loading={searchTextLoading}
                />

                <FiltersPanel
                  filters={filters}
                  onChange={handleFilterChange}
                  onClear={handleClearFilters}
                  onDelete={handleFilterDelete}
                  any={!loading && any}
                  bulkAction={bulkAction}
                  bulkActionParams={bulkActionParams}
                  itemCount={checkedCount}
                  allCount={allCount}
                  checked={checked}
                  onBulkActionApply={handleBulkActionApply}
                  onBulkActionChange={handleBulkActionChange}
                  onBulkActionParamsChange={handleBulkActionParamsChange}
                  onDownload={handleDownload}
                />
                {loading && !data && (
                  <Box display="flex" justifyContent="center">
                    <LoadingSpinner size={60} />
                  </Box>
                )}
                {data && (
                  <>
                    <TableVirtualizer
                      useWindowScroll
                      items={items}
                      endReached={loadMore}
                      overscan={50}
                      fixedHeaderContent={() => (
                        <TableRow>
                          <TableCell padding="checkbox">
                            {items?.length > 0 && (
                              <Checkbox
                                color="primary"
                                checked={all || some}
                                onClick={handleCheckAll}
                                indeterminate={some}
                              />
                            )}
                          </TableCell>
                          <TableCell className={classes.tableCellLeft}>
                            <Trans>Name &amp; Role</Trans>
                          </TableCell>
                          <TableCell>
                            <Trans>Locations</Trans>
                          </TableCell>
                          <TableCell className={classes.tableCellLeft}>
                            <Trans>Areas of Work</Trans>
                          </TableCell>
                          {hasTraining && (
                            <TableCell className={classes.tableCellCenter}>
                              <Trans>Training</Trans>
                            </TableCell>
                          )}
                          {hasAccreditation && (
                            <TableCell className={classes.tableCellCenter}>
                              <Trans>Accreditations</Trans>
                            </TableCell>
                          )}
                          <TableCell className={classes.tableCellCenter}>
                            <Trans>Status</Trans>
                          </TableCell>
                          <TableCell className={classes.tableCellCenter}>
                            <Trans>Edit</Trans>
                          </TableCell>
                        </TableRow>
                      )}
                      itemContent={(_, user) => {
                        const operandio = user?.email?.endsWith("@operandio.com")

                        return (
                          <ErrorBoundary key={toId(user)}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={checked[toId(user)]}
                                onClick={() => handleCheckUser(user)}
                              />
                            </TableCell>
                            <TableCell>
                              <NavLink to={`/people/${toId(user)}`}>
                                <Box display="flex" flexDirection="row">
                                  <UserAvatar {...user} />
                                  <Box ml={1}>
                                    <Box className={classes.fullName}>{user.fullName}</Box>
                                    <Box className={classes.roles}>
                                      {!operandio && user.roles?.map((role) => role.friendlyName).join(", ")}
                                      {!operandio && user.roles?.length === 0 && "User"}
                                      {operandio && (
                                        <RowBox>
                                          <Box mr={0.5}>Operandio Support</Box>
                                          <TooltipIcon
                                            name="information"
                                            tooltip={t`This user does not count towards your billing`}
                                            className={classes.supportInfo}
                                            refClassName={classes.supportInfoRef}
                                          />
                                        </RowBox>
                                      )}
                                    </Box>
                                  </Box>
                                </Box>
                              </NavLink>
                            </TableCell>
                            <TableCell>
                              <TruncateNames names={user.locations?.map((location) => location.name)} max={3} />
                            </TableCell>
                            <TableCell>
                              <TruncateNames names={user.groups?.map((group) => group.name)} max={3} />
                            </TableCell>
                            {hasTraining && (
                              <TableCell className={classes.tableCellCenter}>
                                <PersonTrainingStatus user={user} onClick={handleTrainingClick} />
                              </TableCell>
                            )}
                            {hasAccreditation && (
                              <TableCell className={classes.tableCellCenter}>
                                <PersonAccreditationStatus user={user} onClick={handleAccreditationsClick} />
                              </TableCell>
                            )}
                            <TableCell className={classes.tableCellCenter}>
                              <ColumnBox alignItems="center">
                                <UserStatusChip user={user} />
                                {Boolean(user.activeAt) && (
                                  <Caption mt={0.5} mb={0} tiny>
                                    <TimeAgo date={user.activeAt} compact />
                                  </Caption>
                                )}
                              </ColumnBox>
                            </TableCell>
                            <TableCell className={classes.tableCellCenter}>
                              <IconButton
                                component={NavLink}
                                to={`/people/${toId(user)}`}
                                data-cy={`Person-edit-${toId(user)}`}
                              >
                                <EditOutlined />
                              </IconButton>
                            </TableCell>
                          </ErrorBoundary>
                        )
                      }}
                    />
                    {!loading && items && !items.length && (
                      <NoItemsMessage>
                        <Trans>No matching items</Trans>
                      </NoItemsMessage>
                    )}
                  </>
                )}
              </Box>
            </Content>
          </>
        )}
      </div>
    </DisplayLimiter>
  )
}

export default People
export { compileFilters as compileAdminableUsersFilters }

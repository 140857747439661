import React, { Fragment, memo, useEffect, useRef, useState } from "react"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Box,
  Tooltip,
  makeStyles,
  Divider,
  Typography,
  Button,
} from "@material-ui/core"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import moment from "moment-timezone"
import useDragScroll from "@binhqd777/use-drag-scroll"
import { useHistory, useParams } from "react-router-dom"
import pluralize from "pluralize"
import { formatJobTitle, getStepFormattedUnit, toId, useJobUtils } from "../../utils"
import { PROCESS_STEP_RESPONSE_TYPE, RESPONSE_SET_DISPLAY_AS, useLazyQueryAdminableUser } from "../../data"
import {
  FlexBox,
  FormatDateFormal,
  FormatHumanTime,
  FormatTime,
  Icon,
  ImagesViewer,
  PaperBox,
  RowBox,
  TimestampUserPanel,
} from ".."
import { NestedProcessStatusChip } from "../Chips/NestedProcessStatusChip"
import { titleCase } from "../../utils/string"

const useTooltipStyles = makeStyles((theme) => ({
  tooltipButton: {
    display: "inline-block",
    alignItems: "center",
    textDecoration: "none",
    backgroundColor: "transparent",
    border: "none",
    fontWeight: "normal",
    fontFamily: theme.typography.fontFamily,
    fontSize: "inherit",
    lineHeight: "inherit",
    cursor: "pointer",
    outline: "none",
    margin: 0,
    padding: 0,
  },
  valueButton: {
    color: theme.palette.text.secondary,
  },
  tooltipContent: {
    color: theme.palette.text.secondary,
    maxWidth: 550,
    fontSize: 14,
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
  },
  tooltipResponseValue: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "14px",
    fontWeight: "normal",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
  jobTitle: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "500",
    lineHeight: "20px",
  },
  tooltip: {
    backgroundColor: "Transparent",
    maxWidth: "none",
  },
  anchor: {
    left: "8px !important",
    top: "0px !important",
    transform: "none !important",
  },
}))

const useTableStyles = makeStyles((theme) => ({
  stepCell: {
    position: "sticky",
    left: 0,
    maxWidth: 400,
    backgroundColor: "white",
    overflowWrap: "break-word",
  },
  stepName: {
    maxWidth: 400,
    minWidth: 200,
    overflowWrap: "break-word",
  },
  nestedIcon: {
    fill: theme.palette.text.faint,
  },
  valueCell: {},
  valueResponse: {
    whiteSpace: "nowrap",
    maxWidth: 120,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  templatedValueResponse: {
    maxWidth: 120,
    overflow: "hidden",
    textTransform: "uppercase",
  },
  photosResponse: {
    width: 120,
  },
  signatureResponse: {
    width: 120,
  },
  signature: {
    width: 120,
  },
  valueEmpty: {
    fontStyle: "italic",
    fontWeight: "normal",
    color: theme.palette.text.faint,
  },
  notes: {
    color: theme.palette.text.secondary,
    fontSize: 11,
    fontStyle: "italic",
  },
  jobName: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
  },
}))

const UserTooltip = ({ jobStep, children, anchor }) => {
  const classes = useTooltipStyles()
  const { days, locations, groups } = useParams()
  const history = useHistory()
  const { isStepValueResponseType } = useJobUtils()
  const [loadUser, { data }] = useLazyQueryAdminableUser()
  const [open, setOpen] = useState(false)
  const {
    step: { responseType, response, skip, completedAt, completedBy, format },
  } = jobStep

  useEffect(() => {
    if (open) {
      loadUser({ variables: { id: completedBy } })
    }
  }, [completedBy, loadUser, open])

  const handleOpen = () => {
    if (!open) setOpen(true)
  }

  const handleUserClick = () => {
    history.push(`/reports/people/${days || 7}/${locations || "all"}/${groups || "all"}/${toId(data.adminableUser)}`)
  }

  return (
    <Tooltip
      title={
        <PaperBox className={classes.tooltipContent} onClick={handleUserClick}>
          {isStepValueResponseType(responseType) && !skip && (
            <>
              <Box p={2} className={classes.tooltipResponseValue}>
                {format?.unit ? getStepFormattedUnit(response, format) : response}
              </Box>
              <Divider />
            </>
          )}
          {data && (
            <TimestampUserPanel
              user={data.adminableUser}
              timestamp={completedAt}
              hoverText={
                <Box mt={1}>
                  <Button variant="outlined" size="small">
                    Reports&nbsp;
                    <Icon name="forward" fontSize="small" />
                  </Button>
                </Box>
              }
            />
          )}
        </PaperBox>
      }
      classes={{ tooltip: classes.tooltip, popper: anchor ? classes.anchor : null }}
      enterDelay={1000}
      enterTouchDelay={1000}
      leaveTouchDelay={2000}
      onOpen={handleOpen}
      interactive
      PopperProps={{
        popperOptions: {
          positionFixed: true,
        },
      }}
    >
      <button type="button" className={`${classes.tooltipButton} ${classes.valueButton}`}>
        {children}
      </button>
    </Tooltip>
  )
}

const JobStepDataTableRow = memo(function JobStepDataTableRow({
  step: { name, responseType: processStepResponseType },
  jobs,
  nested,
}) {
  const classes = useTableStyles()
  const theme = useTheme()
  const [viewing, setViewing] = useState(false)
  const { getStepStatus, isStepValueResponseType, isStepValueNonResponseType, getSelectionResponseText } = useJobUtils()

  const handleOpen = () => {
    setViewing(true)
  }

  const handleClose = () => {
    setViewing(false)
  }

  const renderMultipleChoiceResponse = ({ step, response, responseType }) => {
    const responseSet = step?.responseSet
    const isIcon = responseSet?.displayAs === RESPONSE_SET_DISPLAY_AS.ICON
    if (isIcon) {
      const responseDetail = responseSet?.responses?.find((item) => {
        return Number(item.value) === Number(response)
      })
      const responseText = responseDetail?.text
      const iconName = responseText?.replace("_", "-")

      return (
        <Box>
          <Icon name={iconName} />
          <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
            {titleCase(responseText).replace("_", " ")}
          </Typography>
        </Box>
      )
    }

    return (
      <Box
        className={
          responseType === PROCESS_STEP_RESPONSE_TYPE.MULTIPLE_CHOICE
            ? classes.templatedValueResponse
            : classes.valueResponse
        }
      >
        {response}
        {responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER_RATING_5 && <>/5</>}
        {responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER_RATING_10 && <>/10</>}
      </Box>
    )
  }

  return (
    <TableRow>
      <TableCell className={classes.stepCell}>
        <RowBox className={classes.stepName} title={name}>
          {nested && (
            <Box mr={0.5}>
              <Icon name="nested" className={classes.nestedIcon} />
            </Box>
          )}
          <Box>
            {processStepResponseType === PROCESS_STEP_RESPONSE_TYPE.SECTION && (
              <>
                <strong>{name}</strong>
              </>
            )}
            {processStepResponseType !== PROCESS_STEP_RESPONSE_TYPE.SECTION && <>{name}</>}
          </Box>
        </RowBox>
      </TableCell>
      {jobs.map((jobStep) => {
        const {
          id,
          step: { responseType: stepResponseType, response, responseUploads, notes, format },
        } = jobStep
        const responseType = stepResponseType || processStepResponseType
        const status = getStepStatus(jobStep)
        const userStatus = ["complete", "overdue", "skipped"].includes(status)
        const valueResponseType = isStepValueResponseType(responseType)
        const nonResponseType = isStepValueNonResponseType(responseType)
        const overdue = status === "overdue"

        const displayValueStatus = ["complete", "overdue"].includes(status)

        return (
          <TableCell
            key={id}
            align="center"
            className={classes.valueCell}
            style={{ cursor: "grab", width: 150, minWidth: 150 }}
          >
            {userStatus && (
              <UserTooltip jobStep={jobStep} status={status} anchor={viewing}>
                <>
                  {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.CHECKBOX && (
                    <Box>
                      <CheckBox style={{ fill: overdue ? theme.palette.error.light : theme.palette.success.main }} />
                    </Box>
                  )}
                  {status === "skipped" && !nonResponseType && (
                    <Box>
                      <Icon name="skip" style={{ fill: theme.palette.grey[400] }} />
                    </Box>
                  )}
                  {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.PHOTOS && (
                    <FlexBox className={classes.photosResponse} justifyContent="center">
                      <ImagesViewer
                        uploads={responseUploads}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        max={3}
                        width={50}
                        height={50}
                      />
                    </FlexBox>
                  )}
                  {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.FILES && (
                    <Box>{pluralize("file", responseUploads.length, true)}</Box>
                  )}
                  {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.SIGNATURE && (
                    <FlexBox className={classes.signatureResponse} justifyContent="center">
                      <img src={response} alt="signature" className={classes.signature} />
                    </FlexBox>
                  )}
                  {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.DATE_TIME && (
                    <Box className={classes.valueResponse}>
                      {moment(response).format("D MMM YYYY")}
                      <br />
                      <FormatTime value={moment(response)} />
                    </Box>
                  )}

                  {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER && (
                    <Box className={classes.valueResponse}>
                      {format?.unit ? getStepFormattedUnit(response, format) : response}
                    </Box>
                  )}

                  {displayValueStatus &&
                    valueResponseType &&
                    ![PROCESS_STEP_RESPONSE_TYPE.DATE_TIME, PROCESS_STEP_RESPONSE_TYPE.NUMBER].includes(
                      responseType,
                    ) && <>{renderMultipleChoiceResponse({ step: jobStep?.step, response, responseType })}</>}

                  {displayValueStatus && responseType === PROCESS_STEP_RESPONSE_TYPE.SELECTION && (
                    <Box>{getSelectionResponseText(jobStep?.step)}</Box>
                  )}
                </>
              </UserTooltip>
            )}
            {!userStatus && (
              <Box title="Uncompleted">
                {!valueResponseType &&
                  !nonResponseType &&
                  ![
                    PROCESS_STEP_RESPONSE_TYPE.PHOTOS,
                    PROCESS_STEP_RESPONSE_TYPE.FILES,
                    PROCESS_STEP_RESPONSE_TYPE.PROCESS,
                    PROCESS_STEP_RESPONSE_TYPE.SIGNATURE,
                  ].includes(responseType) && <CheckBoxOutlineBlank style={{ fill: theme.palette.grey[400] }} />}
                {(valueResponseType ||
                  [
                    PROCESS_STEP_RESPONSE_TYPE.PHOTOS,
                    PROCESS_STEP_RESPONSE_TYPE.FILES,
                    PROCESS_STEP_RESPONSE_TYPE.SIGNATURE,
                  ].includes(responseType)) && <Box className={classes.valueEmpty}>&lt;empty&gt;</Box>}
                {responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && jobStep?.step?.job && (
                  <NestedProcessStatusChip job={jobStep.step.job} />
                )}
              </Box>
            )}
            {notes && (
              <Box className={classes.notes}>
                {notes.map((note) => (
                  <Tooltip key={toId(note)} title={`${note.author?.fullName}`} placement="right">
                    <Box>&ldquo;{note.text}&rdquo;</Box>
                  </Tooltip>
                ))}
              </Box>
            )}
          </TableCell>
        )
      })}
    </TableRow>
  )
})

const JobTooltip = memo(function JobTooltip({ job, location, children }) {
  const classes = useTooltipStyles()
  const [open, setOpen] = useState(false)
  const { availableFrom, dueAt } = job

  const handleOpen = () => {
    if (!open) setOpen(true)
  }

  return (
    <Tooltip
      title={
        <PaperBox className={classes.tooltipContent}>
          <Box p={2}>
            <Typography component="h1" className={classes.jobTitle}>
              {formatJobTitle(job)}
            </Typography>
            <Box>
              <FormatDateFormal value={moment(availableFrom).tz(location.timeZone)} />
            </Box>
            {dueAt && (
              <Box mt={1}>
                Due at <FormatHumanTime value={moment(dueAt).tz(location.timeZone)} />
              </Box>
            )}
          </Box>
        </PaperBox>
      }
      classes={{ tooltip: classes.tooltip }}
      enterTouchDelay={100}
      leaveTouchDelay={2000}
      onOpen={handleOpen}
    >
      <button type="button" className={classes.tooltipButton}>
        {children}
      </button>
    </Tooltip>
  )
})

const TitleJob = memo(function TitleJob({ job }) {
  const classes = useTableStyles()

  return <Typography className={classes.jobName}>{formatJobTitle(job)}</Typography>
})

const JobStepDataTable = memo(function JobStepDataTable({ data, location }) {
  const tableRef = useRef()

  useDragScroll({
    sliderRef: tableRef,
    momentumVelocity: 0,
  })

  if (!data?.process) {
    return
  }

  const {
    process: { steps },
    jobs,
  } = data

  const getJobSteps = (step) =>
    jobs.map(({ id, dueAt, steps: jobSteps }) => ({
      id,
      dueAt,
      step: jobSteps.find((jobStep) => toId(jobStep.step) === toId(step)) || {},
    }))

  const getNestedJobSteps = (step, jobSteps) => {
    return jobSteps
      .filter((jobStep) => jobStep.step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && jobStep.step.job)
      .map(
        ({
          step: {
            job: { id, steps: nestedJobSteps },
          },
        }) => ({
          id,
          step: nestedJobSteps.find((jobStep) => toId(jobStep.step) === toId(step)) || {},
        }),
      )
  }

  return (
    <div ref={tableRef} style={{ overflow: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ position: "sticky", left: 0, backgroundColor: "white" }}>&nbsp;</TableCell>
            {jobs.map((job) => {
              const { id, availableFrom } = job
              return (
                <TableCell key={id} align="center" style={{ width: 150, minWidth: 150, verticalAlign: "top" }}>
                  <JobTooltip job={job} location={location}>
                    <Box display="flex" flexDirection="column">
                      <Box>{moment(availableFrom).tz(location.timeZone).format("D MMM YY")}</Box>
                      <TitleJob job={job} />
                    </Box>
                  </JobTooltip>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {steps
            .filter((step) => step.responseType !== PROCESS_STEP_RESPONSE_TYPE.INSTRUCTION)
            .map((step) => {
              const jobSteps = getJobSteps(step)

              const row = <JobStepDataTableRow step={step} jobs={jobSteps} />

              // get nested rows
              const nestedRows = []
              if (step.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && step.process?.steps) {
                // get nested steps
                for (const nestedStep of step.process.steps.filter(
                  (item) => item.__typename !== "ProcessProcessStep",
                )) {
                  const nestedJobSteps = getNestedJobSteps(nestedStep, jobSteps)

                  nestedRows.push(
                    <JobStepDataTableRow key={toId(nestedStep)} step={nestedStep} jobs={nestedJobSteps} nested />,
                  )
                }
              }

              return (
                <Fragment key={toId(step)}>
                  {row}
                  {nestedRows}
                </Fragment>
              )
            })}
        </TableBody>
      </Table>
    </div>
  )
})

export { JobStepDataTable }

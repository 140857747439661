import React from "react"
import { Chart, BarSeries, ArgumentAxis, ValueAxis, Tooltip } from "@devexpress/dx-react-chart-material-ui"
import { ValueScale, Stack, EventTracker, HoverState } from "@devexpress/dx-react-chart"
import { useTheme, Box, makeStyles } from "@material-ui/core"
import { stackOffsetExpand } from "d3-shape"
import { FiberManualRecord } from "@material-ui/icons"
import pluralize from "pluralize"
import { Trans } from "@lingui/macro"
import { NoItemsMessage, RowBox } from ".."
import { useStringUtils } from "../../utils"

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: ({ containerHeight }) => containerHeight,
    overflowY: "auto",
  },
  fullscreenContainer: {
    height: "calc(100vh - 180px)",
    overflowY: "auto",
  },
  chartLegendLabel: {
    fontSize: 11,
  },
  chartLegendDot: {
    fontSize: 15,
  },
  barSeries: {
    cursor: "pointer",
  },
  tooltipContent: {},
  tooltipProcessName: {
    fontSize: 16,
    fontWeight: "600",
    color: theme.palette.text.secondary,
  },
  tooltipStepName: {
    fontSize: 14,
    fontWeight: "600",
    color: theme.palette.text.secondary,
  },
}))

const format = (scale) => scale.tickFormat(null, "%")

const ProcessStepActionsChart = ({
  data,
  argumentLabels = true,
  barWidth = 0.5,
  rotated = false,
  height,
  onClick,
  containerHeight = null,
  truncateLabelAt = 30,
  variant = "full-stacked-bar",
}) => {
  const classes = useStyles({ containerHeight })
  const theme = useTheme()
  const { truncateInMiddle } = useStringUtils()

  const seriesConfig = ["Unresolved", "In progress", "Blocked", "Resolved", "Cancelled"]

  const HorizontalLegend = ({ children, mb = 1, mt, ...props }) => (
    <RowBox mb={mb} mt={mt} {...props} justifyContent="flex-end" mr={2}>
      {children}
    </RowBox>
  )

  const HorizontalLegendItem = ({ text, className, dotStyle = {} }) => (
    <RowBox py={0.5} px={1}>
      <FiberManualRecord className={`${classes.chartLegendDot} ${className}`} style={dotStyle} />
      <Box ml={0.5} className={classes.chartLegendLabel}>
        {text}
      </Box>
    </RowBox>
  )

  const LabelContent = ({ text, ...rest }) => {
    const processStepName = data.find(({ argument }) => argument === text)?.processStepName || text
    return <ArgumentAxis.Label text={truncateInMiddle(processStepName, truncateLabelAt)} {...rest} />
  }

  const handleClick = (event) => {
    if (!event.targets?.[0]) {
      return
    }

    const index = event.targets[0].point
    onClick && onClick(data[index], event)
  }

  const TooltipOverlayComponent = ({ children, ...props }) => (
    <Tooltip.Overlay {...props} style={{ zIndex: 999999 }}>
      {children}
    </Tooltip.Overlay>
  )

  const TooltipContent = ({ targetItem: { point, series } }) => {
    const { processStepName, processName, total } = data[point]
    const propName = series === "In progress" ? "inProgress" : series.toLowerCase()
    const value = data[point][propName]
    const percentage = Math.floor((value / total) * 100)
    return (
      <Box className={classes.tooltipContent}>
        <Box className={classes.tooltipProcessName}>{processName}</Box>
        <Box className={classes.tooltipStepName}>{processStepName}</Box>
        <Box>
          <strong>{series}</strong>
        </Box>
        <Box>
          {percentage}% ({pluralize("action", value, true)})
        </Box>
      </Box>
    )
  }

  if (!seriesConfig)
    return (
      <NoItemsMessage>
        <Trans>Initialising...</Trans>
      </NoItemsMessage>
    )

  if (!data?.length)
    return (
      <NoItemsMessage>
        <Trans>No data</Trans>
      </NoItemsMessage>
    )

  return (
    <Box>
      <HorizontalLegend>
        <HorizontalLegendItem text="Unresolved" dotStyle={{ color: theme.palette.status.unresolved }} />
        <HorizontalLegendItem text="In progress" dotStyle={{ color: theme.palette.status.in_progress }} />
        <HorizontalLegendItem text="Blocked" dotStyle={{ color: theme.palette.status.blocked }} />
        <HorizontalLegendItem text="Resolved" dotStyle={{ color: theme.palette.status.resolved }} />
        <HorizontalLegendItem text="Cancelled" dotStyle={{ color: theme.palette.status.cancelled }} />
      </HorizontalLegend>
      <Box className={containerHeight ? classes.container : classes.fullscreenContainer}>
        <Chart data={data} rotated={rotated} height={Math.max(height, 80)}>
          <ValueScale name="Total" />
          <ArgumentAxis showLabels={argumentLabels} labelComponent={LabelContent} />
          <ValueAxis tickFormat={format} />
          <BarSeries
            name="Unresolved"
            valueField="unresolved"
            argumentField="argument"
            scaleName="Total"
            color={theme.palette.status.unresolved}
            className={classes.barSeries}
            barWidth={barWidth}
          />
          <BarSeries
            name="In progress"
            valueField="inProgress"
            argumentField="argument"
            scaleName="Total"
            color={theme.palette.status.in_progress}
            className={classes.barSeries}
            barWidth={barWidth}
          />
          <BarSeries
            name="Blocked"
            valueField="blocked"
            argumentField="argument"
            scaleName="Total"
            color={theme.palette.status.blocked}
            className={classes.barSeries}
            barWidth={barWidth}
          />
          <BarSeries
            name="Resolved"
            valueField="resolved"
            argumentField="argument"
            scaleName="Total"
            color={theme.palette.status.resolved}
            className={classes.barSeries}
            barWidth={barWidth}
          />
          <BarSeries
            name="Cancelled"
            valueField="cancelled"
            argumentField="argument"
            scaleName="Total"
            color={theme.palette.status.cancelled}
            className={classes.barSeries}
            barWidth={barWidth}
          />
          <Stack
            stacks={[{ series: seriesConfig || [] }]}
            offset={variant.startsWith("full-") ? stackOffsetExpand : null}
          />
          <EventTracker onClick={handleClick} />
          <Tooltip contentComponent={TooltipContent} overlayComponent={TooltipOverlayComponent} />
          <HoverState />
        </Chart>
      </Box>
    </Box>
  )
}

export { ProcessStepActionsChart }

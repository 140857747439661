import React from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink } from "react-router-dom/cjs/react-router-dom.min"
import { Trans } from "@lingui/macro"
import { useActionUtils } from "../../utils"
import { useQueryAction } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { NoItemsMessage } from "../Lists"
import { DialogTitleCloser } from "../Creators"
import { Icon } from "../Icon"
import { Action } from "./Action"
import { CenteredWrapper } from "../Viewers/CenteredWrapper"

const useStyles = makeStyles((theme) => ({
  action: {
    marginBottom: theme.spacing(3),
    padding: 0,
    cursor: "pointer",
  },
  content: {
    paddingTop: 0,
    "&:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  section: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #EFEFEF",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    "&:last-child": {
      borderBottom: "none",
    },
  },
  compactSection: {
    borderBottom: "1px solid #EFEFEF",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sectionHeader: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const ActionViewer = ({ open, id, onClose }) => {
  const classes = useStyles()
  const { getNavigateToLink } = useActionUtils()
  const isInline = useMediaQuery((theme) => theme.breakpoints.down("md"))

  const { data, loading } = useQueryAction({ variables: { id }, skip: !id || !open })

  const action = data?.action

  const handleClose = () => {
    onClose && onClose()
  }

  const RenderDelayLoading = () => (
    <CenteredWrapper>
      <LoadingSpinner size={85} delay={false} />
    </CenteredWrapper>
  )

  if (open && isInline && !action && loading) {
    return <RenderDelayLoading />
  }

  const display = (
    <>
      {!!action && <Action action={action} />}
      {!action &&
        (loading ? (
          <RenderDelayLoading />
        ) : (
          <CenteredWrapper delay>
            <NoItemsMessage>
              <Trans>Action not found, you may not have access</Trans>
            </NoItemsMessage>
          </CenteredWrapper>
        ))}
    </>
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="form-dialog-title">
        <Trans>Action details</Trans>
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>{display}</DialogContent>
      <DialogActions>
        <Button component={NavLink} to={getNavigateToLink(id)}>
          <Box mr={0.5}>
            <Trans>View in Actions</Trans>
          </Box>
          <Icon name="forward" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ActionViewer

import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Box, useMediaQuery, useTheme } from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroll-component"
import pluralize from "pluralize"
import useAsyncEffect from "use-async-effect"
import { Trans } from "@lingui/macro"
import {
  IconMessage,
  JobCreator,
  LoadingSpinner,
  NoItemsMessage,
  ProcessTemplateActionCard,
  ScrollToTop,
  SearchInput,
  TemplateCreator,
  TemplateCopyCreator,
  TemplateViewer,
} from "../../components"
import {
  useMutationDeleteProcess,
  useMutationUpdateProcessInactive,
  useQueryProcessSetCounts,
  useQueryProcesses,
} from "../../data"
import { toId, useProcessUtils } from "../../utils"
import { FiltersPanel } from "./FiltersPanel"
import { useAuth } from "../../services"

const initialFilterState = {
  all: [],
  types: null,
  groups: null,
  locations: null,
  schedules: null,
  tags: null,
}

const makeFilters = (all) => {
  const types = all.filter((filter) => filter.type === "types").map((filter) => filter.value)
  const locations = all.filter((filter) => filter.type === "locations").map((filter) => filter.value)
  const groups = all.filter((filter) => filter.type === "groups").map((filter) => filter.value)
  const schedules = all.filter((filter) => filter.type === "schedules").map((filter) => filter.value)
  const tags = all.filter((filter) => filter.type === "tags").map((filter) => filter.value)

  return {
    types: types.length > 0 ? types : null,
    locations: locations.length > 0 ? locations : null,
    groups: groups.length > 0 ? groups : null,
    schedules: schedules.length > 0 ? schedules : null,
    tags: tags.length > 0 ? tags : null,
  }
}

const Organisation = () => {
  const theme = useTheme()
  const displayInline = useMediaQuery(theme.breakpoints.down("xs"))
  const { set, id, action, category } = useParams()
  const history = useHistory()
  const { getNavigateToTemplateLink, getFriendlySetName } = useProcessUtils()
  const [searchText, setSearchText] = useState(null)
  const [filters, setFilters] = useState(initialFilterState)
  const {
    data: processesData,
    loading,
    refetch: refetchProcesses,
    loadMore,
  } = useQueryProcesses({
    variables: {
      filter: {
        set,
        types: filters.types,
        locations: filters.locations,
        groups: filters.groups,
        schedules: filters.schedules,
        tags: filters.tags,
        searchText,
      },
    },
  })
  const { refetch: refetchCounts } = useQueryProcessSetCounts()
  const [updateProcessInactive] = useMutationUpdateProcessInactive()
  const [deleteProcess] = useMutationDeleteProcess()
  const { hasFeature } = useAuth()

  const baseUri = `/templates/${set}`

  useAsyncEffect(async () => {
    if (id) {
      return
    }
    await refetchProcesses()
  }, [id])

  useAsyncEffect(async () => {
    if (id) {
      return
    }
    await refetchCounts()
  }, [id])

  const handleEdit = (process) => {
    history.push(getNavigateToTemplateLink(process, set || "library", category, "edit"))
  }

  const handleCopy = (process) => {
    history.push(getNavigateToTemplateLink(process, set || "library", category, "copy"))
  }

  const handleToggleInactive = async (process, navigateToTemplate) => {
    const item = await updateProcessInactive({ variables: { id: toId(process), value: !process.inactive } })
    await refetchCounts()
    if (navigateToTemplate && item.data) {
      history.push(getNavigateToTemplateLink(item.data.process.inactive, "", "", "view"))
    }
  }

  const handleDelete = async (process) => {
    await deleteProcess({ variables: { id: toId(process) } })
  }

  const handleUsed = async (process) => {
    const inactiveSet = !set ? "inactive-oneoff" : set.startsWith("active") ? `in${set}` : set
    history.push(`/templates/${inactiveSet}/${toId(process)}/edit`)
  }

  const handleClose = async () => {
    history.push(baseUri)
  }

  const handleChangeSearchText = async (text) => {
    setSearchText(text || null)
  }

  const handleFilterChange = (type, values) => {
    setFilters((prev) => {
      const all = prev.all.filter((filter) => filter.type !== type)
      all.push(...values.map((value) => ({ type, value })))
      return {
        all,
        ...makeFilters(all),
      }
    })
  }

  const handleClearFilters = () => {
    setFilters(initialFilterState)
  }

  const handleFilterDelete = ({ type, value }) => {
    setFilters((prev) => {
      const all = prev.all.filter((filter) => !(filter.type === type && filter.value === value))
      return {
        all,
        ...makeFilters(all),
      }
    })
  }

  const hasFilters = Boolean(searchText) || filters.all.length > 0

  const hasSchedules = set?.includes("recurring")

  const hasAreas = hasFeature("areas")

  const heading = getFriendlySetName(set)

  const openNew = id === "new" && action

  const scrollToTop = displayInline && openNew

  const data = processesData?.processes.list || []

  const count = processesData?.processes.count

  return (
    <>
      {scrollToTop && <ScrollToTop />}

      <JobCreator open={openNew} onClose={handleClose} />

      <TemplateCopyCreator
        template={action === "copy" && id ? id : null}
        onClose={handleClose}
        displayInline={displayInline}
      />

      <TemplateCreator
        template={action === "edit" && id ? id : null}
        onClose={handleClose}
        displayInline={displayInline}
      />

      <TemplateViewer
        process={action === "view" && id ? id : null}
        onUsed={handleUsed}
        onDisable={handleToggleInactive}
        onClose={handleClose}
      />

      {(!displayInline || (displayInline && !action && !openNew)) && (
        <>
          <SearchInput
            placeholder={`Search ${heading.toLowerCase()}`}
            debounce={300}
            onChange={handleChangeSearchText}
            loading={loading}
            boxProps={{ pl: 0, pr: 0, mb: displayInline ? 2 : 1 }}
          />

          <FiltersPanel
            filters={filters.all}
            hasSchedules={hasSchedules}
            hasAreas={hasAreas}
            onChange={handleFilterChange}
            onClear={handleClearFilters}
            onDelete={handleFilterDelete}
          />

          {loading && data.length === 0 && (
            <Box display="flex" justifyContent="center">
              <LoadingSpinner size={60} />
            </Box>
          )}
          <InfiniteScroll
            dataLength={data.length}
            next={loadMore}
            hasMore={count > data.length}
            loader={
              loading && (
                <Box display="flex" justifyContent="center">
                  <LoadingSpinner size={60} />
                </Box>
              )
            }
            endMessage={
              !loading &&
              count > 0 && (
                <NoItemsMessage>
                  {count || "No"} matching {pluralize("item", data.length)}
                </NoItemsMessage>
              )
            }
            style={{ overflow: "visible" }}
          >
            <Box data-cy="Box-templates">
              {data.map((process) => (
                <Box key={toId(process)} px={0}>
                  <ProcessTemplateActionCard
                    process={process}
                    set={set || "library"}
                    category={category}
                    onEdit={handleEdit}
                    onCopy={handleCopy}
                    onDisable={handleToggleInactive}
                    onDelete={handleDelete}
                  />
                </Box>
              ))}
            </Box>
          </InfiniteScroll>
          {!loading && !data.length && !hasFilters && (
            <IconMessage
              name="empty-templates"
              text={`You have no ${heading.toLowerCase()}.${
                !!set && !set.startsWith("inactive") ? " Why not add one?" : ""
              }`}
            />
          )}
          {!loading && !data.length && hasFilters && (
            <NoItemsMessage>
              <Trans>No items match your search</Trans>
            </NoItemsMessage>
          )}
        </>
      )}
    </>
  )
}

export default Organisation

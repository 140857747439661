import React, { useState, useEffect } from "react"
import { useTheme, useMediaQuery, Grid, Hidden, IconButton } from "@material-ui/core"
import moment from "moment-timezone"
import { useHistory, useParams } from "react-router-dom"
import { FilterList } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import {
  AreaHeader,
  DateNavigatorBar,
  JobViewer,
  JobsFilters,
  JobsSearch,
  DesktopRunJobButton,
  MobileRunJobButton,
  FormatDateFormal,
  RequirePermissions,
  ModuleHeading,
  Content,
  JobCreator,
  ScrollToTop,
  IconMessage,
  DeviceGroupsDisplay,
  BackToTop,
  JobList,
  RowBox,
} from "../../components"
import { JOBS_SET, jobFiltersStateVar, useJobFiltersStore, useLazyQueryJobs, useQuerySettings } from "../../data"
import { mapToIds, toId, useDateUtils, useJobUtils, useMountEffect } from "../../utils"
import { useAuth } from "../../services"
import { DesktopRaiseActionButton } from "../../components/Buttons/RaiseActionButton"

const Jobs = () => {
  const theme = useTheme()
  const history = useHistory()
  const { day, type, id, location: switchLocation } = useParams()
  const isViewerInline = useMediaQuery(theme.breakpoints.down("md"))
  const { getNavigateDateLink, isAfterToday } = useJobUtils()
  const { momentToScheduleDate } = useDateUtils()
  const { jobsFiltersStore } = useJobFiltersStore()
  const {
    location,
    setLocation,
    principal: { groups: usersGroups },
    settings: { deviceGroups },
  } = useAuth()
  const { lang } = useQuerySettings()
  const [editJob, setEditJob] = useState(null)
  const [date, setDate] = useState(null)
  const [unsubscribes, setUnsubscribes] = useState(null)
  const [loadJobs, jobsResult] = useLazyQueryJobs()
  const { called, data, refetch, subscribe, loading } = jobsResult
  const [locationId, setLocationId] = useState(null)
  const [openFilter, setOpenFilter] = useState(false)
  const [backToTopOffset, setBackToTopOffset] = useState(null)

  useMountEffect(() => {
    setBackToTopOffset(document.querySelector("#date-navigator-bar").clientHeight + 20)
  })

  useEffect(() => {
    if (location?.id && !locationId) {
      setLocationId(location.id)
    }
  }, [location.id, locationId])

  useEffect(() => {
    if (moment(day).isValid() && !moment.tz(day, location.timeZone).isSame(date)) {
      const newDate = moment.tz(day, location.timeZone).startOf("day")
      setDate(newDate)
    }
  }, [date, day, location.timeZone])

  useEffect(() => {
    if (!date || !jobsFiltersStore) {
      return
    }

    const filtersIsMe = jobsFiltersStore.tab === "me"

    let jobsSet
    if (filtersIsMe) {
      jobsSet = JOBS_SET.SELF
    } else {
      jobsSet = jobsFiltersStore.toHasEveryone ? JOBS_SET.ALL_USERS : JOBS_SET.OTHERS
    }

    const makeQueryFilters = () => {
      return {
        filter: {
          location: toId(location),
          date: momentToScheduleDate(date),
          set: jobsSet,
          toGroups: filtersIsMe ? null : mapToIds(jobsFiltersStore.toGroups),
          toUsers: filtersIsMe ? null : mapToIds(jobsFiltersStore.toUsers),
          toDeviceGroups: mapToIds(deviceGroups),
        },
      }
    }

    if (called) {
      refetch({
        ...makeQueryFilters(),
      })
    } else {
      loadJobs({
        variables: {
          ...makeQueryFilters(),
        },
      })
    }
  }, [called, date, deviceGroups, jobsFiltersStore, loadJobs, location, momentToScheduleDate, refetch, usersGroups])

  useEffect(() => {
    if (!jobsFiltersStore) {
      return
    }

    if (moment(day).isValid() && !moment.tz(day, location.timeZone).isSame(date)) {
      const newDate = moment.tz(day, location.timeZone).startOf("day")

      jobFiltersStateVar({
        ...jobsFiltersStore,
        isFuture: isAfterToday(newDate),
      })
    }
  }, [date, day, isAfterToday, jobsFiltersStore, location, locationId])

  useEffect(() => {
    if (switchLocation && switchLocation !== toId(location)) {
      setLocation(switchLocation)
    }
  }, [location, setLocation, switchLocation])

  useEffect(() => {
    if (called && !unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [called, subscribe, unsubscribes, setUnsubscribes])

  const handleEditJob = (post) => {
    setEditJob(post)
  }

  const handleCreate = (createType) => {
    history.push(`${getNavigateDateLink(date, location.timeZone)}/${createType}/new`)
  }
  const handleCreateClose = () => {
    history.push(getNavigateDateLink(date, location.timeZone))
  }

  const handleViewJobClose = () => {
    history.push(getNavigateDateLink(date, location.timeZone))
  }

  const handleViewJobDelete = () => {
    history.push(getNavigateDateLink(date, location.timeZone))
    refetch({ location: locationId, date: momentToScheduleDate(date) })
  }

  const handleDateNavigate = (move) => {
    let newDate = moment.tz(day, location.timeZone)
    if (move === -1) newDate.subtract(1, "days")
    else if (move === 1) newDate.add(1, "days")
    else newDate = move

    history.push(getNavigateDateLink(newDate, location.timeZone))
  }

  const handleFilterTextChange = (filterText) => {
    jobFiltersStateVar({
      ...jobsFiltersStore,
      filterText,
    })
  }

  const handleSortChange = (sort) => {
    jobFiltersStateVar({
      ...jobsFiltersStore,
      sort,
    })
  }

  const handleToggleFilter = () => {
    setOpenFilter(!openFilter)
  }

  const openCreate = id === "new" && type

  const scrollToTop = isViewerInline && id

  const { isFuture } = jobsFiltersStore || {}

  const previewJob = isFuture && id && data?.jobs.list.find((job) => toId(job) === id)

  return (
    <>
      {scrollToTop && <ScrollToTop />}

      <JobCreator open={openCreate} onClose={handleCreateClose} edit={editJob} />

      <JobViewer
        key={id}
        open={!!id && id !== "new"}
        id={id === "new" ? null : id}
        scheduleDate={date ? momentToScheduleDate(date) : null}
        preview={previewJob}
        onClose={handleViewJobClose}
        onDelete={handleViewJobDelete}
        loading={loading}
      />

      {(!isViewerInline || (isViewerInline && !id)) && (
        <>
          <AreaHeader
            title={
              isViewerInline ? (
                <>
                  Jobs for <FormatDateFormal value={date} />
                </>
              ) : (
                lang.area.jobs
              )
            }
            subTitle={<DeviceGroupsDisplay />}
            titleIcon="jobs-light"
            desktopButtons={
              <RowBox>
                <RequirePermissions requires="action_create">
                  <DesktopRaiseActionButton boxProps={{ mr: 1 }} />
                </RequirePermissions>
                <DesktopRunJobButton onCreateClick={handleCreate} />
              </RowBox>
            }
            mobileButtons={<MobileRunJobButton onCreateClick={handleCreate} />}
          />

          <DateNavigatorBar date={day} onDateNavigate={handleDateNavigate} />

          <Content full>
            <Grid container direction="row">
              <Hidden mdDown>
                <Grid item lg="auto" md={12}>
                  <JobsFilters open={openFilter} onClose={handleToggleFilter} fullWidth={isViewerInline} />
                  {Boolean(backToTopOffset) && <BackToTop from={300} offset={backToTopOffset} />}
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <JobsFilters open={openFilter} onClose={handleToggleFilter} fullWidth={isViewerInline} />
              </Hidden>
              <Grid item xs={12} lg>
                <Hidden mdDown>
                  <ModuleHeading>
                    <Trans>
                      Jobs for <FormatDateFormal value={date} />
                    </Trans>
                  </ModuleHeading>
                </Hidden>
                <JobsSearch
                  onTextChange={handleFilterTextChange}
                  onSortChange={handleSortChange}
                  textValue={jobsFiltersStore?.filterText || ""}
                  sortValue={jobsFiltersStore?.sort || ""}
                  textRight={
                    isViewerInline && (
                      <IconButton title="Filters" onClick={handleToggleFilter}>
                        <FilterList />
                      </IconButton>
                    )
                  }
                />
                {jobsFiltersStore && (
                  <JobList
                    {...jobsResult}
                    onEdit={handleEditJob}
                    filters={jobsFiltersStore}
                    date={date}
                    empty={
                      <IconMessage
                        name="empty-activejobs"
                        text={
                          isFuture ? (
                            <Trans>There's no jobs scheduled for this day.</Trans>
                          ) : (
                            <Trans>There's no active jobs currently listed for this day.</Trans>
                          )
                        }
                      />
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Content>
        </>
      )}
    </>
  )
}

export default Jobs

import { useApolloClient } from "@apollo/client"
import { Box, Container, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { useParams } from "react-router"
import { Trans } from "@lingui/macro"
import { LoadingSpinner, LogoFull, PaperBox } from "../../components"
import { useAuth } from "../../services"
import { useMountEffect } from "../../utils"
import { PublicContainer } from "../Master"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    padding: 0,
  },
  wrapper: {
    textAlign: "center",
    overflowWrap: "break-word",
  },
  logo: {
    marginBottom: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  external: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.buttons.light.background,
  },
  externalLogo: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
  },
}))

const LoginExternalResult = () => {
  const classes = useStyles()
  const { processExternalSigninResponse } = useAuth(useApolloClient())
  const { id } = useParams()
  const [error, setError] = useState()

  useMountEffect(() => {
    const handleProcessSigninResponse = async () => {
      try {
        const result = await processExternalSigninResponse(id)
        if (result.errors) throw new Error(result.errors[0].message)
      } catch (err) {
        console.log("[LoginExternalResult]", "Failed", err.name, err.message, err.error, err.error_description)
        switch (err.error) {
          case "interaction_required":
            setError({
              message:
                "Interaction is required at your identity provider, however the application is configured not to allow interaction. This is usually due to the availability of multiple identities.",
            })
            break
          case "login_required":
            setError({
              message:
                "Sign in failed, or no user is signed in with your identity provider and the application is configured to require an existing session.",
            })
            break
          case "consent_required":
            setError({
              message: "Consent is required to access the application.",
            })
            break
          case "account_selection_required":
            setError({
              message: "Account selection is required to access the application.",
            })
            break
          default:
            if (err.error_description) {
              setError({
                message: err.error_description,
              })
            } else {
              setError(err)
            }
            break
        }
      }
    }
    handleProcessSigninResponse()
  })

  return (
    <PublicContainer withCancel={error != null} cancelIcon="back" cancelText="Back to login">
      <PaperBox p={5}>
        <Container component="main" className={classes.root}>
          <div className={classes.wrapper}>
            <Box mb={2}>
              <LogoFull width="197" />
            </Box>
            <Typography paragraph align="center">
              <strong>
                <Trans>Signing in</Trans>
              </strong>
            </Typography>
            {!error && <LoadingSpinner size={60} />}
            {error && (
              <>
                <Trans>An error occurred while trying to sign you in:</Trans>
                <br />
                <em>{error.message}</em>
              </>
            )}
          </div>
        </Container>
      </PaperBox>
    </PublicContainer>
  )
}

export { LoginExternalResult }

import React from "react"
import { Grid, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { TimeAgo, Avatar, AuthorTools } from ".."
import { Icon } from "../Icon"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75),
  },
  icon: {
    color: theme.palette.raised.secondary,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  avatarName: {
    fontSize: 16,
    fontWeight: "600",
    lineHeight: "20px",
  },
  ageAndRecipients: {
    fontSize: 14,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
  },
}))

const NoteHeader = ({ note, isLoading = false, onDelete, showIcon = true, showToolbar = true }) => {
  const classes = useStyles()
  const {
    principal: { userID },
  } = useAuth()
  const { author, createdAt } = note

  const handleDelete = (event) => {
    onDelete && onDelete(event)
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      {showIcon && (
        <Box mr={2} display={{ xs: "none", sm: "flex" }}>
          <Icon name="note-added" className={classes.icon} />
        </Box>
      )}
      <Box mr={1} display="flex">
        <Avatar className={classes.avatar} {...author} />
      </Box>
      <Grid item xs>
        <div className={classes.avatarName}>{author.fullName}</div>
        <div className={classes.ageAndRecipients}>
          <TimeAgo date={createdAt} />
        </div>
      </Grid>
      {showToolbar && (
        <Box ml="auto" display="flex" alignItems="center" flexDirection="row">
          <AuthorTools item={note} author={userID} subject="note" onDelete={handleDelete} loading={isLoading} />
        </Box>
      )}
    </Grid>
  )
}

export { NoteHeader }

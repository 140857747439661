import moment from "moment-timezone"
import Config from "react-global-configuration"
import {
  getRepeatDisplayText as getProcessRepeatDisplayText,
  getRepeatDisplayShortText as getProcessRepeatDisplayShortText,
} from "./useProcessUtils"
import { useAuth } from "../services"
import { toId } from "./data"
import { PROCESS_REPEAT } from "../data"
import {
  PROCESS_SCHEDULE_UNTIL,
  PROCESS_STEP_RESPONSE_TYPE,
  PROCESS_STEP_NON_RESPONSE_TYPES,
  PROCESS_STEP_SELECTION_SOURCES,
  PROCESS_REPEAT_RECURRING,
} from "../data/enums"
import { processFormats } from "../data/processes/processFormats"

const {
  CHECKBOX,
  NUMBER,
  TEXT,
  LONG_TEXT,
  DATE_TIME,
  PHOTOS,
  FILES,
  MULTIPLE_CHOICE,
  NUMBER_RATING_5,
  NUMBER_RATING_10,
  SIGNATURE,
  SECTION,
  INSTRUCTION,
  SELECTION,
} = PROCESS_STEP_RESPONSE_TYPE

const timeZoneFallback = moment.tz.guess()

const hasExport = (job) => job?.status.completed && job?.repeat?.type === PROCESS_REPEAT.ADHOC

const formatJobTitle = (job) => {
  const {
    title,
    scheduleName,
    repeat: { type },
  } = job

  if (PROCESS_REPEAT_RECURRING.includes(type)) {
    return scheduleName
  }

  if (type === PROCESS_REPEAT.ADHOC) {
    return title
  }

  return ""
}

const getJobPinningTypeDescription = (type) => {
  switch (type) {
    case PROCESS_SCHEDULE_UNTIL.COMPLETED:
      return "Until completed"
    case PROCESS_SCHEDULE_UNTIL.NEXT_INSTANCE:
      return "Until next instance"
    default:
      return null
  }
}

const jobsHaveResponseUploads = (jobs) => {
  if (!jobs || jobs.length === 0) {
    return false
  }

  return jobs.some((job) => jobHasResponseUploads(job))
}

const jobHasResponseUploads = (job) => {
  if (!job?.status?.steps) {
    return false
  }

  return job.status.steps.some((step) => step.responseUploads.length > 0)
}

const isStepValueResponseType = (responseType) =>
  [TEXT, LONG_TEXT, NUMBER, DATE_TIME, NUMBER_RATING_5, NUMBER_RATING_10, MULTIPLE_CHOICE].includes(responseType)

const isStepValueNonResponseType = (responseType) => [SECTION, INSTRUCTION].includes(responseType)

const isPrefixFormat = (format) => {
  const allowedUnits = ["currency", "acidity"]
  return allowedUnits.some((unit) => format.startsWith(unit))
}

const getStepFormattedUnit = (response, format) => {
  const unit = processFormats.find((item) => item.name === format.unit)
  if (!unit) {
    return response
  }
  if (isPrefixFormat(unit.name)) {
    return `${unit.symbol}${response}`
  }
  return `${response}${unit.symbol}`
}

const getResponseStepsCount = (steps) => {
  if (!steps) {
    return 0
  }
  return steps.filter((step) => !PROCESS_STEP_NON_RESPONSE_TYPES.includes(step.responseType)).length
}

const getNavigateToLink = (job, dayDate) => {
  if (!job) {
    return "/jobs"
  }

  return `${getNavigateDateLink(
    job.availableUntil?.type !== PROCESS_SCHEDULE_UNTIL.NONE && dayDate ? dayDate : job.availableFrom,
    job.location?.timeZone,
  )}/process/${toId(job)}/${toId(job.location)}`
}

const getNavigateDateLink = (date, timeZone) => `/jobs/${moment.tz(moment(date), timeZone).format("YYYY-MM-DD")}`

const getNavigateToLinkForTypeLocationDayAndID = (type, locationDay, locationId, id) =>
  `/jobs/${locationDay}/process/${id}/${locationId}`

const getNavigateToLinkForLocationDay = (job, locationDay, location) =>
  `/jobs/${locationDay}/process/${toId(job)}/${toId(location || job.location)}`

const isJobOverdue = ({ dueAt, status: { completed } }) => dueAt && !completed && moment(dueAt).isSameOrBefore(moment())

const useJobUtils = (apolloClient) => {
  const { location, settings, principal: currentUser } = useAuth(apolloClient)
  const { clientLinkBaseUri, clientBaseUri, clientKiosk: kiosk } = Config.get()

  const getFullNavigateToLink = (job, dayDate) =>
    `${clientLinkBaseUri || clientBaseUri}${getNavigateToLink(job, dayDate)}`

  const canNavigateToJob = (job) =>
    toId(job.location) === toId(location) || (!kiosk && settings?.locations.some((l) => toId(l) === toId(job.location)))

  const canDeleteNote = (note) => {
    if (toId(note?.author, true) === currentUser?.userID) {
      return true
    }
    if (currentUser?.permissions.job_update_all) {
      return true
    }
    return false
  }

  const getStepStatus = ({ dueAt, step: { step, responseType, skip, completedAt } }) => {
    if (
      !!step &&
      [
        CHECKBOX,
        TEXT,
        LONG_TEXT,
        NUMBER,
        DATE_TIME,
        NUMBER_RATING_5,
        NUMBER_RATING_10,
        MULTIPLE_CHOICE,
        PHOTOS,
        FILES,
        SIGNATURE,
        SELECTION,
      ].includes(responseType) &&
      !!completedAt &&
      !skip
    ) {
      if (!!dueAt && !!completedAt && moment(completedAt).isAfter(moment(dueAt))) return "overdue"
      return "complete"
    }
    if (!!step && skip) {
      return "skipped"
    }
    if (!!step && !completedAt) {
      return "uncomplete"
    }
    return "none"
  }

  const getRepeatDisplayText = (repeat) => {
    return getProcessRepeatDisplayText(repeat)
  }

  const getRepeatDisplayShortText = (repeat) => {
    return getProcessRepeatDisplayShortText(repeat)
  }

  const getScheduleFormattedDate = (availableFrom) => {
    const date = moment(availableFrom)
    return date
      .tz(location.timeZone)
      .format(`D MMM YYYY${date.hours() > 0 || date.minutes() > 0 ? " [at] h:mm A" : ""} z`)
  }

  const isAfterToday = (date) => isJobAfterToday(date)
  const isJobAfterToday = (availableFrom) =>
    availableFrom &&
    moment.tz(moment(availableFrom), location?.timeZone || timeZoneFallback).isSameOrAfter(
      moment
        .tz(moment(), location?.timeZone || timeZoneFallback)
        .add(1, "day")
        .startOf("day"),
    )

  const getSelectionResponseText = (step) => {
    if (!step?.selection) {
      return
    }

    const { selectFrom, allowMultiple } = step?.selection

    if (selectFrom !== PROCESS_STEP_SELECTION_SOURCES.CUSTOM_ITEMS) {
      const responseSelectionNames = step?.responseSelections?.map((responseSelection) => responseSelection.name)
      return allowMultiple ? responseSelectionNames.join(", ") : responseSelectionNames?.[0]
    }

    return allowMultiple ? step?.responseCustomSelections.join(", ") : step?.responseCustomSelections?.[0]
  }

  return {
    canNavigateToJob,
    canDeleteNote,

    getNavigateToLink,
    getFullNavigateToLink,
    getNavigateDateLink,
    getNavigateToLinkForTypeLocationDayAndID,
    getRepeatDisplayText,
    getRepeatDisplayShortText,
    getScheduleFormattedDate,
    getJobPinningTypeDescription,
    getSelectionResponseText,

    getStepStatus,
    isStepValueResponseType,
    isStepValueNonResponseType,

    isAfterToday,
    isJobAfterToday,
    isJobOverdue,

    formatJobTitle,
  }
}

export {
  useJobUtils,
  isStepValueResponseType,
  isPrefixFormat,
  getNavigateToLink,
  getNavigateToLinkForLocationDay,
  getStepFormattedUnit,
  getResponseStepsCount,
  isJobOverdue,
  hasExport,
  jobsHaveResponseUploads,
  jobHasResponseUploads,
  formatJobTitle,
}

import React, { useRef } from "react"
import { Box, Dialog, DialogContent, DialogTitle, useMediaQuery } from "@material-ui/core"
import { toId } from "../../utils"
import { useMutationDeleteAction, useQueryActions } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { NoItemsMessage } from "../Lists"
import { DialogTitleCloser } from "../Creators"
import { Action } from "./Action"
import { ListWindowVirtualizer } from "../Lists/ListWindowVirtualizer"
import { CenteredWrapper } from "../Viewers/CenteredWrapper"

const ActionsViewer = ({ open, job, onDeleted, onClose }) => {
  const isInline = useMediaQuery((theme) => theme.breakpoints.down("md"))
  const dialogScrollerRef = useRef(null)

  const { data, loading, loadMore } = useQueryActions({ variables: { filter: { job } }, skip: !open })
  const [deleteAction] = useMutationDeleteAction()

  const handleDelete = async (id) => {
    await deleteAction({
      variables: {
        id,
      },
    })
    onDeleted && onDeleted(job)
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const actions = data?.actions?.list
  const hasActions = actions?.length > 0

  const RenderDelayLoading = () => (
    <CenteredWrapper height="auto">
      <LoadingSpinner size={85} delay={false} />
    </CenteredWrapper>
  )

  if (open && isInline && !actions && loading) {
    return <RenderDelayLoading />
  }

  const display = (
    <Box mb={1}>
      {hasActions && (
        <>
          <ListWindowVirtualizer
            items={actions}
            itemContent={(_, action) => (
              <Box data-cy="Box-actions">
                <Action key={toId(action)} action={action} onDelete={() => handleDelete(toId(action))} />
              </Box>
            )}
            endReached={loadMore}
            components={{
              Footer: () => (
                <Box pt={3} pb={7}>
                  {loading && (
                    <Box display="flex" justifyContent="center">
                      <LoadingSpinner size={60} />
                    </Box>
                  )}
                  {!loading && <> &nbsp;</>}
                </Box>
              ),
            }}
            customScrollParent={dialogScrollerRef.current}
          />
        </>
      )}
      {!hasActions && loading && <RenderDelayLoading />}
      {!hasActions && !loading && (
        <CenteredWrapper delay>
          <NoItemsMessage>Actions not found, you may not have access</NoItemsMessage>
        </CenteredWrapper>
      )}
    </Box>
  )

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        Actions
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent ref={(ref) => (dialogScrollerRef.current = ref)}>{display}</DialogContent>
    </Dialog>
  )
}

export default ActionsViewer

import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { t } from "@lingui/macro"
import { Avatar } from "../Avatar"
import { RowBox } from "../Boxes"
import { TooltipIcon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  rowBox: {
    cursor: "pointer",
  },
  fullName: {
    fontSize: 16,
    color: theme.palette.text.primary,
    lineHeight: "20px",
    maxWidth: 280,
    overflow: "ellipsis",
    width: 280,
  },
  roles: {
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
  },
}))

const UserTableRowCell = ({ user, onClick }) => {
  const classes = useStyles()
  const operandio = user.email?.endsWith("@operandio.com")

  return (
    <RowBox className={classes.rowBox} onClick={onClick}>
      <Avatar {...user} />
      <Box ml={1}>
        <Box className={classes.fullName}>{user.fullName}</Box>
        <Box className={classes.roles}>
          {!operandio && user.roles.map((role) => role.friendlyName).join(", ")}
          {!operandio && user.roles.length === 0 && "User"}
          {operandio && (
            <RowBox>
              <Box mr={0.5}>Operandio Support</Box>
              <TooltipIcon
                name="information"
                tooltip={t`This user does not count towards your billing`}
                className={classes.supportInfo}
                refClassName={classes.supportInfoRef}
              />
            </RowBox>
          )}
        </Box>
      </Box>
    </RowBox>
  )
}

export { UserTableRowCell }

import React, { useEffect, useMemo, useState } from "react"
import { Box, makeStyles, Grid, Button, MenuItem } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router"
import { Trans } from "@lingui/macro"
import {
  LocationOutlinedSelect,
  TemplateOutlinedSelect,
  DateRangeOutlinedSelect,
  RowBox,
  OutlinedSelect,
} from "../../components"
import { mapToIds, multipleSelectChange, useDateUtils, useReportUtils } from "../../utils"
import { useAuth } from "../../services"
import LocationsActionsReport from "../../components/Reports/LocationsActionsReport"
import ProcessStepActionsOverviewReport from "../../components/Reports/ProcessStepActionsOverviewReport"
import { ActionPriorityOutlinedSelect } from "../../components/OutlinedSelect/ActionPriorityOutlinedSelect"
import { TagOutlinedSelect } from "../../components/OutlinedSelect/TagOutlinedSelect"
import { ACTION_STATUS, ACTION_STATUS_LABELS } from "../../data"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
    maxWidth: 300,
  },
  reports: {
    width: 932 - theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
}))

const Actions = () => {
  const classes = useStyles()
  const { days, locations, groups, process, tags } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToActionsLink, getNavigateToLink, filterChange } = useReportUtils()
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const [priorities, setPriorities] = useState(["all"])
  const [statuses, setStatuses] = useState(["all"])
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()

  const daysValue = days || 7
  const groupIds = groups?.split("-") || ["all"]
  const locationIds = useMemo(() => locations?.split("-") || ["all"], [locations])
  const processValue = process || "all"
  const tagIds = useMemo(() => tags?.split("-") || ["all"], [tags])

  useEffect(() => {
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      setGt(momentToScheduleDate(moment(daysParts[0])))
      setLt(momentToScheduleDate(moment(daysParts[1])))
    } else {
      setGt(momentToScheduleDate(moment().subtract(days, "days")))

      let newLt = null
      // If not "Today"
      if (days !== "1") {
        // Set end of yesterday (23h59)
        newLt = momentToScheduleDate(moment().subtract(1, "days").endOf("day"))
      }

      setLt(newLt)
    }
  }, [days, locations, momentToScheduleDate, process, userLocations])

  const handleDaysChange = (event) => {
    history.push(getNavigateToActionsLink(event.target.value, locationIds, groupIds, processValue, tagIds))
  }

  const handleLocationsChange = (event) => {
    history.push(getNavigateToActionsLink(daysValue, filterChange(locationIds, event), groupIds, processValue, tagIds))
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToActionsLink(daysValue, mapToIds(regionLocations), groupIds, processValue, tagIds))
  }

  const handleJobChange = (event) => {
    history.push(getNavigateToActionsLink(daysValue, locationIds, groupIds, event.target.value, tagIds))
  }

  const handleTagsChange = (event) => {
    history.push(getNavigateToActionsLink(daysValue, locationIds, groupIds, processValue, filterChange(tagIds, event)))
  }

  // const handleJobClick = (newProcess) => {
  //   history.push(getNavigateToActionsLink(days, locationIds, groupIds, newProcess))
  // }

  const handleStatusesChange = (event) => {
    setStatuses([...multipleSelectChange(statuses, event)])
  }

  const handlePrioritieshange = (event) => {
    setPriorities([...multipleSelectChange(priorities, event)])
  }

  const handleReset = () => {
    history.push(getNavigateToLink("actions", 7, ["all"], ["all"]))
  }

  return (
    <RowBox alignItems="flex-start">
      <Box p={3} className={classes.filter} mt={3} mb={3}>
        <Box mb={2}>
          <DateRangeOutlinedSelect value={daysValue} onChange={handleDaysChange} className={classes.filterSelect} />
        </Box>
        <Box mb={2}>
          <LocationOutlinedSelect
            value={locationIds}
            className={classes.filterSelect}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        <Box mb={2}>
          <TemplateOutlinedSelect
            label="Job"
            allLabel="All jobs"
            value={processValue}
            className={classes.filterSelect}
            hasAll
            onChange={handleJobChange}
          />
        </Box>
        <Box mb={2}>
          <OutlinedSelect
            label="Status"
            native={false}
            value={statuses}
            className={classes.filterSelect}
            onChange={handleStatusesChange}
            multiple
            hasAll
          >
            <MenuItem value="all">All statuses</MenuItem>
            <MenuItem value={ACTION_STATUS.UNRESOLVED}>{ACTION_STATUS_LABELS[ACTION_STATUS.UNRESOLVED]}</MenuItem>
            <MenuItem value={ACTION_STATUS.IN_PROGRESS}>{ACTION_STATUS_LABELS[ACTION_STATUS.IN_PROGRESS]}</MenuItem>
            <MenuItem value={ACTION_STATUS.BLOCKED}>{ACTION_STATUS_LABELS[ACTION_STATUS.BLOCKED]}</MenuItem>
            <MenuItem value={ACTION_STATUS.RESOLVED}>{ACTION_STATUS_LABELS[ACTION_STATUS.RESOLVED]}</MenuItem>
            <MenuItem value={ACTION_STATUS.CANCELLED}>{ACTION_STATUS_LABELS[ACTION_STATUS.CANCELLED]}</MenuItem>
          </OutlinedSelect>
        </Box>
        <Box mb={2}>
          <ActionPriorityOutlinedSelect
            value={priorities}
            className={classes.filterSelect}
            onChange={handlePrioritieshange}
            multiple
            hasAll
          />
        </Box>
        <Box>
          <TagOutlinedSelect
            value={tagIds}
            className={classes.filterSelect}
            onChange={handleTagsChange}
            multiple
            hasAll
          />
        </Box>
        <RowBox mt={2} justifyContent="flex-end">
          <Button variant="contained" onClick={handleReset}>
            <Trans>Reset</Trans>
          </Button>
        </RowBox>
      </Box>
      <Box flexGrow={1}>
        <Grid container spacing={1} className={classes.reports}>
          <Grid item xs={12}>
            <LocationsActionsReport
              gt={gt}
              lt={lt}
              process={process}
              locations={locationIds}
              groups={groupIds}
              tags={tagIds}
              statuses={statuses}
              priorities={priorities}
            />
          </Grid>
          <Grid item xs={12}>
            <ProcessStepActionsOverviewReport
              gt={gt}
              lt={lt}
              process={process}
              locations={locationIds}
              groups={groups}
              tags={tagIds}
              statuses={statuses}
              priorities={priorities}
            />
          </Grid>
        </Grid>
      </Box>
    </RowBox>
  )
}

export default Actions

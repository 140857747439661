import React from "react"
import { Button, Box } from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { Trans } from "@lingui/macro"

const DesktopRaiseActionButton = ({ boxProps = {}, ...buttonProps }) => {
  return (
    <Box {...boxProps}>
      <Button component={NavLink} to="/hub/actions/unresolved/new" variant="contained" color="primary" {...buttonProps}>
        <Trans>Raise action</Trans>
      </Button>
    </Box>
  )
}

export { DesktopRaiseActionButton }

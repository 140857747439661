import React, { useEffect, useMemo, useState } from "react"
import { Grid, useTheme, useMediaQuery, Box } from "@material-ui/core"
import { useParams, useHistory } from "react-router-dom"
import { Trans } from "@lingui/macro"
import {
  Content,
  KnowledgeCategory,
  KnowledgeArticle,
  LoadingSpinner,
  KnowledgeCategoryCreator,
  NoItemsMessage,
  BackToTop,
} from "../../components"
import Categories from "./Categories"
import { useQueryCategories, useQueryKnowledges } from "../../data"
import { toId } from "../../utils"
import Forbidden from "./Forbidden"

const CategoryKnowledge = ({ groups, locations, tags, onEditArticle, onDeleteArticle, onCopyArticle }) => {
  const params = useParams()
  const { data, loading, subscribe } = useQueryKnowledges({
    variables: { filter: { category: params.id, groups, locations, tags } },
  })
  const [unsubscribes, setUnsubscribes] = useState(null)

  useEffect(() => {
    if (!unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [subscribe, unsubscribes, setUnsubscribes])

  return (
    <>
      {data?.knowledges
        .filter((article) => toId(article.category) === params.id)
        .map((article) => (
          <KnowledgeArticle
            key={article.id}
            knowledge={article}
            onEdit={onEditArticle}
            onDelete={onDeleteArticle}
            onCopy={onCopyArticle}
          />
        ))}
      {!loading && !data?.knowledges.length && (
        <NoItemsMessage>
          <Trans>No articles found</Trans>
        </NoItemsMessage>
      )}
    </>
  )
}

const Category = ({
  groups,
  locations,
  tags,
  onEditArticle,
  onDeleteArticle,
  onCopyArticle,
  onEditCategory,
  onDeleteCategory,
}) => {
  const theme = useTheme()
  const history = useHistory()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const { id, action } = useParams()
  const { data, loading, subscribe, error } = useQueryCategories({ variables: { filter: { groups, locations } } })

  const [category, setCategory] = useState(null)
  const [unsubscribes, setUnsubscribes] = useState(null)
  const [editing, setEditing] = useState(null)

  useEffect(() => {
    if (data && id) {
      const result = data.categories.find((c) => c.id === id)
      if (result) setCategory(result)
    }
  }, [data, id])

  useEffect(() => {
    if (!unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [subscribe, unsubscribes, setUnsubscribes])

  useEffect(() => {
    if (action === "edit" && id && (!editing || editing.id !== id)) {
      setEditing(category)
    } else if (!id && editing) setEditing(null)
  }, [action, id, editing, category])

  const forbidden = useMemo(() => {
    if (!error?.graphQLErrors?.length) {
      return false
    }
    return error.graphQLErrors[0].extensions?.code === "FORBIDDEN"
  }, [error])

  const handleClose = () => {
    history.push(`/knowledge/category/${id}`)
  }

  if (forbidden) {
    return (
      <Content start>
        <Forbidden />
      </Content>
    )
  }

  return (
    <>
      <KnowledgeCategoryCreator open={editing !== null} edit={editing} onClose={handleClose} />

      <Content start>
        <Grid container direction="row">
          {!sm && (
            <Grid item sm={3}>
              <Categories variant="submenu" />
              <BackToTop from={300} />
            </Grid>
          )}
          <Grid item md={9} sm={12} style={sm ? { width: "100%" } : null}>
            {category && (
              <KnowledgeCategory
                variant="title"
                category={category}
                onEdit={onEditCategory}
                onDelete={onDeleteCategory}
              />
            )}
            <Grid container direction="column">
              {loading && !data && (
                <Box display="flex" justifyContent="center">
                  <LoadingSpinner size={60} />
                </Box>
              )}
              <CategoryKnowledge
                groups={groups}
                locations={locations}
                tags={tags}
                onEditArticle={onEditArticle}
                onDeleteArticle={onDeleteArticle}
                onCopyArticle={onCopyArticle}
              />
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </>
  )
}

export default Category

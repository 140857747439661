import React, { useEffect } from "react"
import { Box } from "@material-ui/core"
import moment from "moment"
import { formatters } from "json2csv"
import fileDownload from "js-file-download"
import pluralize from "pluralize"
import { ReportSectionActionCard, JobStepDataTable, FullscreenReportContainer, DownloadExportJobButton } from ".."
import { useLazyQueryReportJobDetail, PROCESS_STEP_RESPONSE_TYPE } from "../../data"
import { formatJobTitle, getStepFormattedUnit, toId, useJobUtils } from "../../utils"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ExportJobAttachmentsButton from "./ExportJobAttachmentsButton"

const reportJobsHaveResponseUploads = (jobs) => {
  if (!jobs || jobs.length === 0) {
    return false
  }

  return jobs.some((job) => {
    if (!job?.steps) {
      return false
    }

    return job.steps.some((step) => step.responseUploads.length > 0)
  })
}

const JobStepTableReport = ({ gt, lt, process, location, defaultFullscreen = false, onFullscreen, multiplePerDay }) => {
  const { getStepStatus, isStepValueResponseType, isStepValueNonResponseType, getSelectionResponseText } = useJobUtils()
  const [load, { data, loading }] = useLazyQueryReportJobDetail()

  useEffect(() => {
    load({ variables: { process: toId(process), location: toId(location), gt, lt } })
  }, [gt, load, location, lt, process])

  const handleFullscreen = (fullscreen) => {
    onFullscreen && onFullscreen(fullscreen)
  }

  const flattenJobSteps = (jobs) =>
    jobs
      .map((job) => {
        return job.steps.reduce((newJobSteps, jobStep) => {
          const status = getStepStatus({ dueAt: job?.dueAt, step: jobStep })
          let items = [{ ...jobStep, status }]
          if (jobStep.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && jobStep.job?.steps) {
            items = items.concat(
              jobStep.job?.steps?.map((step) => ({
                ...step,
                parentStep: toId(jobStep.step),
                status: getStepStatus({ dueAt: null, step }),
              })),
            )
          }

          return newJobSteps.concat(items)
        }, [])
      })
      .flat()

  const flattenProcessSteps = (downloadProcess) =>
    downloadProcess.steps.reduce((newProcessSteps, processStep) => {
      let items = [processStep]
      if (processStep.responseType === PROCESS_STEP_RESPONSE_TYPE.PROCESS && processStep.process?.steps) {
        items = items.concat(
          processStep.process?.steps.map((step) => ({
            ...step,
            name: `↳ ${step.name}`,
            parentStep: toId(processStep),
          })),
        )
      }

      return newProcessSteps.concat(items)
    }, [])

  const getJobStepValue = ({ jobStep, stringFormatter, processStepResponseType }) => {
    const { status, response, responseType: stepResponseType, responseUploads, completedAt, format } = jobStep
    const userStatus = ["complete", "overdue", "skipped"].includes(status)
    const responseType = stepResponseType || processStepResponseType
    const isValueResponseType = isStepValueResponseType(responseType)
    const isNoneResponseType = isStepValueNonResponseType(responseType)

    if (status === "skipped") {
      return "<skipped>"
    }

    if (
      !userStatus &&
      (isValueResponseType ||
        [
          PROCESS_STEP_RESPONSE_TYPE.PHOTOS,
          PROCESS_STEP_RESPONSE_TYPE.FILES,
          PROCESS_STEP_RESPONSE_TYPE.SIGNATURE,
        ].includes(responseType))
    ) {
      return "<empty>"
    }

    if (isNoneResponseType || (status !== "skipped" && !completedAt)) {
      return ""
    }

    if (responseType === PROCESS_STEP_RESPONSE_TYPE.NUMBER) {
      return format?.unit ? getStepFormattedUnit(response, format) : stringFormatter(response)
    }

    if (responseType === PROCESS_STEP_RESPONSE_TYPE.SELECTION) {
      const value = getSelectionResponseText(jobStep)
      return value.includes(",") ? `"${value}"` : value
    }

    if (responseType === PROCESS_STEP_RESPONSE_TYPE.SIGNATURE) {
      return `${jobStep?.completedByFullName ?? ""} - ${jobStep?.completedAt}`
    }

    if (responseType === PROCESS_STEP_RESPONSE_TYPE.PHOTOS && responseUploads) {
      return stringFormatter(pluralize("photo", responseUploads.length, true))
    }

    if (responseType === PROCESS_STEP_RESPONSE_TYPE.FILES && responseUploads) {
      return stringFormatter(pluralize("file", responseUploads.length, true))
    }

    if (response) {
      return stringFormatter(response)
    }

    return completedAt
  }

  const convertReportDataToCSV = ({ downloadProcess, jobs, stringFormatter }) => {
    const flattenedJobSteps = flattenJobSteps(jobs)
    const flattenedProcessSteps = flattenProcessSteps(downloadProcess)

    return flattenedProcessSteps.map(({ id: stepId, parentStep, name, responseType: processStepResponseType }) => {
      const jobStepValue = flattenedJobSteps
        .filter(
          ({ step: jobStepId, parentStep: jobParentStepId }) =>
            jobStepId === stepId && (!jobParentStepId || jobParentStepId === parentStep),
        )
        .map((jobStep) =>
          getJobStepValue({
            jobStep,
            stringFormatter,
            processStepResponseType,
          }),
        )

      return `${stringFormatter(name)},${jobStepValue.join(",")}`
    })
  }

  const handleDownloadData = () => {
    const { process: downloadProcess, jobs } = data.reportJobDetail
    const stringFormatter = formatters.string()
    const header = [
      "",
      ...jobs.map(({ availableFrom }) =>
        stringFormatter(moment(availableFrom).tz(location.timeZone).format("D MMM YY")),
      ),
    ]

    const titles = ["", ...jobs.map((job) => stringFormatter(formatJobTitle(job)))]

    const rows = convertReportDataToCSV({
      downloadProcess,
      jobs,
      stringFormatter,
    })

    const bom = "\uFEFF"
    const csv = `${bom}${header.join(",")}\n${titles.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "job-step-responses.csv", "text/csv")
  }

  const hasAttachments = reportJobsHaveResponseUploads(data?.reportJobDetail.jobs)

  return (
    <ReportSectionActionCard
      title={location.name}
      detail={`${!loading && data ? `${data.reportJobDetail?.process?.name || "Unknown process"} - ` : ""}Job data`}
      tools={
        <>
          {hasAttachments && (
            <ExportJobAttachmentsButton
              variant="icon"
              gt={gt}
              lt={lt}
              process={process}
              locations={[location]}
              disabled={loading}
            />
          )}
          <Box ml={1}>
            <DownloadExportJobButton
              jobs={
                data?.reportJobDetail?.jobs?.map(({ id, availableFrom, title }) => ({
                  id,
                  name: `${moment(availableFrom).format("D MMM YY")}${multiplePerDay ? ` - ${title}` : ""}`,
                })) || []
              }
              variant="list"
              iconSize="small"
              listAllJobsOptionProps={{ gt, lt, process, location }}
              disabled={loading}
            />
          </Box>
          <Box ml={1}>
            <ReportToolsDownloadDataIconButton onClick={() => handleDownloadData(location)} disabled={loading} />
          </Box>
        </>
      }
      defaultFullscreen={defaultFullscreen}
      onFullscreen={handleFullscreen}
      loading={loading || !data}
    >
      {({ fullscreen }) => {
        if (!data) {
          return null
        }

        const display = (
          <Box style={{ maxWidth: "100%", overflowX: "auto" }}>
            <JobStepDataTable data={data.reportJobDetail} location={location} fullscreen={fullscreen} />
          </Box>
        )

        if (fullscreen) {
          return <FullscreenReportContainer>{display}</FullscreenReportContainer>
        }

        return display
      }}
    </ReportSectionActionCard>
  )
}

export default JobStepTableReport

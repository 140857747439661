import { Box, Chip, makeStyles, Tooltip } from "@material-ui/core"
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { NavLink, useHistory, useParams } from "react-router-dom"
import pluralize from "pluralize"
import { Trans, t } from "@lingui/macro"
import {
  ActionCard,
  AuthorTools,
  Icon,
  IconMessage,
  LoadingSpinner,
  RowBox,
  SearchInput,
  TrainingCourseCreator,
} from "../../components"
import { useMutationDeleteTrainingCourse, useQueryTrainingCourses } from "../../data"
import { toId } from "../../utils"
import { ListWindowVirtualizer } from "../../components/Lists/ListWindowVirtualizer"

const useStyles = makeStyles((theme) => ({
  info: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  rightChildren: {
    height: 40,
    overflow: "visible",
  },
  footer: {
    rowGap: 10,
  },
}))

const Courses = () => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const { data, loading, refetch } = useQueryTrainingCourses()
  const [deleteTrainingCourse] = useMutationDeleteTrainingCourse()

  const [edit, setEdit] = useState(null)
  const [searchText, setSearchText] = useState("")

  const containerRef = useRef(null)
  const offsetRef = useRef(0)

  useEffect(() => {
    if (data && id && id !== "new") {
      setEdit(data.trainingCourses.find((item) => toId(item) === id))
    } else if (!id && edit) {
      setEdit(null)
    }
  }, [data, edit, id])

  useLayoutEffect(() => {
    offsetRef.current = containerRef.current?.offsetTop ?? 0
  }, [containerRef])

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleEdit = (item) => {
    history.push(`/training/courses/${toId(item)}`)
  }
  const handleDelete = async (item) => {
    await deleteTrainingCourse({ variables: { id: toId(item) } })
    refetch()
  }
  const handleClose = () => {
    history.push("/training/courses")
    refetch()
  }

  const items = useMemo(() => {
    return data?.trainingCourses.filter((item) => !searchText || item.name.toLowerCase().includes(searchText))
  }, [data, searchText])

  const isNew = id === "new"

  const hasFilter = data?.trainingCourses.length > 1

  return (
    <>
      {(isNew || Boolean(edit)) && <TrainingCourseCreator edit={edit} onClose={handleClose} />}

      {hasFilter && <SearchInput placeholder={t`Filter courses`} boxProps={{ pl: 0, pr: 0 }} onChange={handleFilter} />}

      <div ref={containerRef}>
        {data && (
          <ListWindowVirtualizer
            items={items}
            itemContent={(_, item) => {
              return (
                <div key={toId(item)}>
                  <ActionCard
                    key={toId(item)}
                    title={item.name}
                    cursor="default"
                    detail={pluralize("module", item.modules.length, true)}
                    rightChildren={
                      <Box
                        mr="auto"
                        display="flex"
                        flexDirection="row"
                        alignSelf="center"
                        alignItems="center"
                        className={classes.rightChildren}
                      >
                        <AuthorTools
                          item={item}
                          subject="course"
                          admins={{ edit: "training_update_all", delete: "training_delete_all" }}
                          onEdit={handleEdit}
                          onDelete={handleDelete}
                        />
                      </Box>
                    }
                    footer={
                      <RowBox flexWrap="wrap" style={{ rowGap: 10 }}>
                        {item.modules.map((module, moduleIndex) => (
                          <RowBox key={toId(module)} alignItems="center">
                            <Tooltip title={t`Edit module`}>
                              <Chip
                                component={NavLink}
                                to={`/training/modules/${toId(module)}`}
                                label={module.name}
                                clickable
                              />
                            </Tooltip>
                            {moduleIndex < item.modules.length - 1 && <Icon name="right" />}
                          </RowBox>
                        ))}
                      </RowBox>
                    }
                  />
                </div>
              )
            }}
          />
        )}

        {!data && loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}

        {data?.trainingCourses.length === 0 && (
          <IconMessage
            name="empty-trainingmodules"
            maxWidth={500}
            text={
              <Trans>
                <Box mb={2}>
                  Once you've created at least one module, you can use the Create button above to create a course.
                </Box>
                <Box mb={2}>
                  Remember, courses combine several modules and are what you assign to your employees to complete as
                  part of their online training.
                </Box>
                <Box>You can assign courses to your employees individually or in bulk in the People area.</Box>
              </Trans>
            }
          />
        )}
      </div>
    </>
  )
}

export { Courses }

import React from "react"
import { Grid, Box, Hidden, useTheme, useMediaQuery } from "@material-ui/core"
import pluralize from "pluralize"
import { Trans, t } from "@lingui/macro"
import { ActionCard, AuthorTools, LoadingSpinner, ModuleHeading, IconMessage } from "../../components"
import { RowBox } from "../../components/Boxes"
import { AdornmentIcon } from "../../components/Icon"
import { ActionCardInfo } from "../../components/ActionCards/ActionCardInfo"
import { DEMO_PROFILE, useQueryLabelTemplates } from "../../data"
import { useDemonstration } from "../../utils/useDemonstration"

const Templates = ({ onEditTemplate, onDeleteTemplate }) => {
  const theme = useTheme()
  const { isDemo } = useDemonstration()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const { data, loading } = useQueryLabelTemplates({
    variables: { filter: {} },
    pollInterval: 30000,
  })

  const isLabelsDemo = isDemo(DEMO_PROFILE.LABELS)

  return (
    <>
      <ModuleHeading mb={xs ? 2 : 0}>
        <Trans>All templates</Trans>
      </ModuleHeading>
      {!data?.labelTemplates.length && !loading && (
        <IconMessage name="empty-label-templates" text={t`You don't have any templates available.`} />
      )}
      <Grid container direction="column">
        {loading && !data && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}

        <Grid container direction="row" spacing={xs ? 2 : 3}>
          <Grid item xs={12} sm={12}>
            {data?.labelTemplates.map((item) => {
              const numberOfLabels = item?.numberOfLabels ?? 0

              return (
                <ActionCard
                  key={item.id}
                  title={item.title}
                  cursor="default"
                  rightChildren={
                    <Box mr="auto" display="flex" flexDirection="row" alignSelf="center" alignItems="center">
                      <AuthorTools
                        item={item}
                        subject="template"
                        admins={{ edit: "label_template_update", delete: "label_template_update" }}
                        onEdit={onEditTemplate}
                        onDelete={isLabelsDemo ? null : onDeleteTemplate}
                      />
                    </Box>
                  }
                  footer={
                    <>
                      <RowBox>
                        <>
                          <Hidden smUp>
                            <ActionCardInfo>
                              <AdornmentIcon
                                name="labels-item"
                                tooltip={`Used by ${pluralize("label", numberOfLabels, true)}`}
                              />
                              <Box mr={5}>{`${pluralize("label", numberOfLabels, true)}`}</Box>
                            </ActionCardInfo>
                          </Hidden>

                          <Hidden xsDown>
                            <AdornmentIcon
                              name="labels-item"
                              tooltip={`Used by ${pluralize("label", numberOfLabels, true)}`}
                            />
                            <Box ml={1} mr={5}>
                              {`Used by ${pluralize("label", numberOfLabels, true)}`}
                            </Box>
                          </Hidden>
                        </>

                        <>
                          <Hidden xsDown>
                            <AdornmentIcon name="display-size" tooltip={`Label size: ${item.width}x${item.height}mm`} />
                            <Box ml={1} mr={5}>
                              {`${item.width}x${item.height}mm`}
                            </Box>
                          </Hidden>
                        </>
                      </RowBox>

                      <RowBox ml="auto">
                        {Boolean(item.locations.length) && (
                          <Box display="flex" alignItems="center" flexDirection="row">
                            <Box mr={0.5}>Used {item.locations?.length > 1 ? "in" : "at"}</Box>
                            {item.locations.length === 1 && <Box>1 location</Box>}
                            {item.locations.length > 1 && (
                              <Box>
                                {item.locations.length} location{item.locations.length > 1 ? "s" : ""}
                              </Box>
                            )}
                          </Box>
                        )}
                        {!item.locations.length && (
                          <Box> Used {item.locations?.length > 1 ? "in" : "at"} all locations</Box>
                        )}
                      </RowBox>
                    </>
                  }
                />
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Templates

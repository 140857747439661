import React from "react"
import { useTheme, useMediaQuery } from "@material-ui/core"
import { t } from "@lingui/macro"
import { ActionCard, FlexBox, AuthorTools, RowBox, Icon } from ".."
import { GROUP_VISIBILITY } from "../../data"
import { ActionCardFooterInfo } from "../ActionCards/ActionCardFooterInfo"

const GroupActionCard = ({ group, onEdit, onDelete, ...rest }) => {
  const { visibility, name, hideFromKiosk } = group
  const theme = useTheme()
  const small = useMediaQuery(theme.breakpoints.down("sm"))

  const handleEdit = () => {
    onEdit && onEdit(group)
  }

  const handleDelete = () => {
    onDelete && onDelete(group)
  }

  return (
    <ActionCard
      title={name}
      stub={
        <RowBox mr={1}>
          <Icon name="group" />
        </RowBox>
      }
      onDelete={handleDelete}
      rightChildren={
        <FlexBox alignSelf="flex-start">
          <AuthorTools
            item={group}
            admins={{
              edit: "group_update",
              delete: "group_update",
            }}
            onEdit={handleEdit}
            subject="area of work"
            onDelete={handleDelete}
          />
        </FlexBox>
      }
      footer={
        <RowBox>
          {visibility === GROUP_VISIBILITY.GENERAL && (
            <ActionCardFooterInfo
              title={t`Users with the correct permissions will be able to add other users to this area of work`}
              value={t`General access`}
              icon="group-visibility-general"
              mr={small ? 0 : 3}
              valueTooltip={false}
            />
          )}
          {visibility === GROUP_VISIBILITY.PRIVATE && (
            <ActionCardFooterInfo
              title={t`Only existing members or full administrators will be able to add other users to this area of work`}
              value={t`Private`}
              icon="group-visibility-private"
              mr={small ? 0 : 3}
              valueTooltip={false}
            />
          )}
          {hideFromKiosk && (
            <ActionCardFooterInfo
              title={t`Members of this area of work will not show on the login screen of Operandio kiosk devices`}
              value={t`Hidden from kiosk devices`}
              icon="no-devices"
              mr={small ? 0 : 3}
              valueTooltip={false}
            />
          )}
        </RowBox>
      }
      {...rest}
    />
  )
}

export { GroupActionCard }

import React from "react"
import { Box, Button, Grid } from "@material-ui/core"
import { NavLink, useParams } from "react-router-dom"
import Config from "react-global-configuration"
import { Trans } from "@lingui/macro"
import { LoadingSpinner, NoItemsMessage, RowBox, SensorActionCard } from "../../components"
import { useQuerySensors } from "../../data"
import { toId } from "../../utils"
import { useAuth } from "../../services"

const pollInterval = 1000 * 60 * 2 // 2 minutes

const SensorList = ({ onClick, gridItemProps }) => {
  const { id } = useParams()
  const { location, settings } = useAuth()
  const configLinks = Config.get("links")
  const { data, loading } = useQuerySensors({
    variables: {
      location: toId(location),
    },
    pollInterval,
  })

  const handleSensorClick = (sensor) => {
    onClick && onClick(sensor)
  }

  return (
    <>
      {data?.sensors
        .filter((sensor) => !!sensor.sample)
        .map((sensor, index) => (
          <Grid key={toId(sensor)} item {...gridItemProps}>
            <SensorActionCard
              sensor={sensor}
              selected={id === toId(sensor)}
              index={index}
              onClick={() => handleSensorClick(sensor)}
              loading={loading}
              cardProps={{ component: NavLink, to: { pathname: `/sensors/${toId(sensor)}/1` } }}
            />
          </Grid>
        ))}
      {loading && !data && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        </Grid>
      )}
      {!loading && !data?.sensors?.length && (
        <Box flexGrow={1}>
          <NoItemsMessage>
            <Trans>
              <p>There are no sensors attached to your current location {location.name}.</p>
              <p>To order sensors please contact sensor sales.</p>
            </Trans>
            <p>
              <Button
                variant="contained"
                href={`mailto:${configLinks.emailSupport}?subject=Operandio%20Sensors%20for%20${encodeURIComponent(
                  settings.organisation.name,
                )}${location && location.name ? `%20(${encodeURIComponent(location.name)})` : ""}`}
              >
                <Trans>Contact Sensor Sales</Trans>
              </Button>
            </p>
          </NoItemsMessage>
        </Box>
      )}
    </>
  )
}

const Sensors = () => (
  <RowBox alignItems="flex-start">
    <Grid container spacing={1}>
      <SensorList gridItemProps={{ xs: 12, sm: 6, md: 4 }} />
    </Grid>
  </RowBox>
)

export default Sensors
export { SensorList }

import { Box, Divider, Tab, Tabs } from "@material-ui/core"
import React from "react"
import { Redirect, Route, Switch, useHistory, useParams } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { AreaHeader, Content, DesktopCreateTrainingButton } from "../../components"
import { useQueryTrainingModules } from "../../data"
import { useAuth } from "../../services"
import { Assessment } from "./Assessment"
import { Courses } from "./Courses"
import { Modules } from "./Modules"

const TrainingRoutes = () => (
  <Switch>
    <Route path="/training/modules/:id" component={Modules} />
    <Route path="/training/modules" component={Modules} />
    <Route path="/training/courses/:id" component={Courses} />
    <Route path="/training/courses" component={Courses} />
    <Route path="/training/assessment" component={Assessment} />
    <Redirect to="/training/modules" />
  </Switch>
)

const Index = () => {
  const history = useHistory()
  const { data, loading } = useQueryTrainingModules()
  const { tab } = useParams()
  const { hasFeature } = useAuth()

  const handleCreate = (type) => {
    history.push(`/training/${type}/new`)
  }

  const handleTabChange = (event, newTab) => history.push(`/training/${newTab}`)

  const hasTrainingAssessment = hasFeature(["training", "training_assessment"])

  if (!tab) {
    return <TrainingRoutes />
  }

  const hasModules = Boolean(data?.trainingModules.length)

  return (
    <>
      <AreaHeader
        titleIcon="training-accreditation-light"
        title={<Trans>Training</Trans>}
        desktopButtons={<DesktopCreateTrainingButton onCreateClick={handleCreate} />}
      />

      <Content full start mb={tab === "assessment" ? 0 : 5}>
        <Tabs
          variant="standard"
          indicatorColor="primary"
          textColor="primary"
          value={tab}
          onChange={handleTabChange}
          aria-label="Training type tabs"
        >
          <Tab label={<Trans>Modules</Trans>} value="modules" />
          <Tab label={<Trans>Courses</Trans>} value="courses" disabled={loading || !hasModules} />
          {hasTrainingAssessment && (
            <Tab label={<Trans>Assessment</Trans>} value="assessment" disabled={loading || !hasModules} />
          )}
        </Tabs>

        <Divider />

        <Box pt={2} mb={tab === "assessment" ? 0 : 5}>
          <TrainingRoutes />
        </Box>
      </Content>
    </>
  )
}

export default Index

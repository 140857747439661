import React, { useState } from "react"
import { Box, Button } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import {
  NoItemsMessage,
  AccreditationCreator,
  SearchInput,
  AccreditationActionCard,
  Icon,
  LoadingSpinner,
} from "../../components"
import { useMutationDeleteAccreditation, useQueryAccreditations } from "../../data"
import { toId } from "../../utils"
import SettingsFooter from "../../components/Settings/SettingsFooter"

const AccreditationTypes = () => {
  const { data, refetch, loading } = useQueryAccreditations()
  const [deleteAccreditation] = useMutationDeleteAccreditation()

  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(null)
  const [searchText, setSearchText] = useState("")

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleCreateAccreditation = () => {
    setOpen(true)
  }
  const handleCreateAccreditationClose = () => {
    setOpen(false)
    setEdit(null)
    refetch()
  }
  const handleEdit = (editAccreditation) => {
    setEdit(editAccreditation)
    setOpen(true)
  }
  const handleDelete = async (item) => {
    await deleteAccreditation({ variables: { id: toId(item) } })
    refetch()
  }

  const hasFilter = data?.accreditations.length > 1

  return (
    <>
      <AccreditationCreator open={open} onClose={handleCreateAccreditationClose} edit={edit} />

      {hasFilter && <SearchInput placeholder="Filter" boxProps={{ pl: 0, pr: 0 }} onChange={handleFilter} />}

      <Box mb={5} data-cy="Accreditations-list">
        {data?.accreditations
          .filter((item) => !searchText || `${item.name} ${item.description}`.toLowerCase().includes(searchText))
          .map((accreditation) => (
            <AccreditationActionCard
              key={toId(accreditation)}
              accreditation={accreditation}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}

        {!data && loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}

        {data?.accreditations.length === 0 && (
          <NoItemsMessage>
            <Trans>No accreditation types added</Trans>
          </NoItemsMessage>
        )}
      </Box>

      <SettingsFooter>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateAccreditation}
          data-cy="Button-create-accreditation-type"
        >
          <Icon name="accreditation" />{" "}
          <Box ml={1}>
            <Trans>Add accreditation type</Trans>
          </Box>
        </Button>
      </SettingsFooter>
    </>
  )
}

export default AccreditationTypes

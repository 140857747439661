import React from "react"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { MentionOutput, DownloadList } from ".."
import { ImagesViewer } from "../ImagesViewer/ImagesViewer"

const useStyles = makeStyles((theme) => ({
  content: {
    fontSize: 16,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    overflowWrap: "anywhere",
  },
}))

const NoteContent = ({ note: { text, uploads } }) => {
  const classes = useStyles()

  return (
    <Box ml={0} mr={0}>
      <div className={classes.content}>
        <MentionOutput content={text} />
        <ImagesViewer uploads={uploads} />
        <DownloadList uploads={uploads} variant="cards" mt={2} />
      </div>
    </Box>
  )
}

export { NoteContent }

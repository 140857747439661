import React, { useRef } from "react"
import { Box, Dialog, DialogContent, DialogTitle, useMediaQuery } from "@material-ui/core"
import { toId } from "../../utils"
import { DialogTitleCloser } from "../Creators"
import { ListWindowVirtualizer } from "../Lists/ListWindowVirtualizer"
import { Note } from "./Note"
import { NoItemsMessage } from "../Lists"
import { useQueryJobNotes } from "../../data/jobs/useQueryJobNotes"
import { CenteredWrapper } from "../Viewers/CenteredWrapper"
import { LoadingSpinner } from "../LoadingSpinner"

const NotesViewer = ({ open, job, onClose, onDeleted }) => {
  const isInline = useMediaQuery((theme) => theme.breakpoints.down("md"))
  const dialogScrollerRef = useRef(null)

  const { data, loading } = useQueryJobNotes({ variables: { job: toId(job) }, skip: !open })

  const handleDeleted = async () => {
    onDeleted && onDeleted(job)
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const notes = data?.job?.notes
  const hasNotes = notes?.length > 0

  const RenderDelayLoading = () => (
    <CenteredWrapper height="auto">
      <LoadingSpinner size={85} delay={false} />
    </CenteredWrapper>
  )

  if (open && isInline && !notes && loading) {
    return <RenderDelayLoading />
  }

  const display = (
    <Box mb={5}>
      {hasNotes && (
        <>
          <ListWindowVirtualizer
            items={notes}
            itemContent={(_, note) => (
              <Box data-cy="Box-notes">
                <Note key={toId(note)} job={job} note={note} onDeleted={() => handleDeleted(note)} />
              </Box>
            )}
            customScrollParent={dialogScrollerRef.current}
            components={{
              Footer: () => <Box my={10}>&nbsp;</Box>,
            }}
          />
        </>
      )}
      {!hasNotes && loading && <RenderDelayLoading />}
      {!hasNotes && !loading && (
        <CenteredWrapper delay>
          <NoItemsMessage>No notes found</NoItemsMessage>
        </CenteredWrapper>
      )}
    </Box>
  )

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">
        Notes
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent ref={(ref) => (dialogScrollerRef.current = ref)}>{display}</DialogContent>
    </Dialog>
  )
}

export default NotesViewer

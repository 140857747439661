import { useApolloClient } from "@apollo/client"
import { Box, Container, makeStyles, Typography } from "@material-ui/core"
import React, { useState } from "react"
import { Trans } from "@lingui/macro"
import { LoadingSpinner, LogoFull, PaperBox } from "../../components"
import { useAuth } from "../../services"
import { useMountEffect } from "../../utils"
import { PublicContainer } from "../Master"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    padding: 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    marginBottom: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  external: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.buttons.light.background,
  },
  externalLogo: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(1),
  },
}))

const LoginGoogle = () => {
  const classes = useStyles()
  const { processGoogleSigninResponse } = useAuth(useApolloClient())
  const [error, setError] = useState()

  useMountEffect(() => {
    const handleProcessResponse = async () => {
      try {
        const result = await processGoogleSigninResponse()
        if (result.errors) throw new Error(result.errors[0].message)
      } catch (err) {
        setError(err)
      }
    }
    handleProcessResponse()
  })

  return (
    <PublicContainer withCancel={error != null} cancelIcon="back" cancelText="Back to login">
      <PaperBox p={5}>
        <Container component="main" className={classes.root}>
          <div className={classes.wrapper}>
            <Box mb={2}>
              <LogoFull width="197" />
            </Box>
            <Typography paragraph align="center">
              <strong>
                <Trans>Signing in with Google</Trans>
              </strong>
            </Typography>
            {!error && <LoadingSpinner size={60} />}
            {error && (
              <>
                <Trans>An error occurred while trying to sign you in:</Trans>
                <br />
                <em>{error.message}</em>
              </>
            )}
          </div>
        </Container>
      </PaperBox>
    </PublicContainer>
  )
}

export { LoginGoogle }

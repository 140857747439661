import React, { Suspense } from "react"
import moment from "moment-timezone"
import { Trans } from "@lingui/macro"

const SplitHumanReadableCron = React.lazy(() => import("./SplitHumanReadableCron"))

const HumanReadableCron = ({ expression, timeZone }) => {
  if (!expression) {
    return null
  }

  return (
    <Suspense
      fallback={
        <div>
          <Trans>Loading...</Trans>
        </div>
      }
    >
      <SplitHumanReadableCron expression={expression} /> {moment.tz(timeZone).format("z")}
    </Suspense>
  )
}

export default HumanReadableCron

import React, { Suspense, useMemo } from "react"
import { Box, Hidden, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import { Redirect, Switch, useHistory, useParams } from "react-router-dom"
import moment from "moment"
import useAsyncEffect from "use-async-effect"
import { Trans, t } from "@lingui/macro"
import {
  AreaHeader,
  Content,
  DateNavigatorBar,
  PrivateRoute,
  RowBox,
  GroupOutlinedSelect,
  DeviceGroupsDisplay,
  LoadingSpinner,
  ColumnBox,
  NoItemsMessage,
} from "../../components"
import { useAuth } from "../../services"
import { mapToIds, momentToScheduleDate, toId, useMountEffect, useReportUtils, useWindowSize } from "../../utils"
import { useLazyQueryCalendar } from "../../data"
import { ScheduleOutlinedSelect } from "../../components/OutlinedSelect/ScheduleOutlinedSelect"
import { CalendarFiltersButton } from "../../components/Calendar/CalendarFiltersButton"
import { CalendarViewToggleButton } from "../../components/Calendar/CalendarViewToggleButton"

const MonthCalendar = React.lazy(() => import("../../components/Calendar/MonthCalendar"))
const WeekCalendar = React.lazy(() => import("../../components/Calendar/WeekCalendar"))

const useStyles = makeStyles((theme) => ({
  areaHeaderSelect: {
    backgroundColor: "white",
    border: "none",
    borderRadius: 4,
    marginLeft: theme.spacing(1),
    width: "auto",
    maxWidth: 250,
    minWidth: 120,
    "&&>.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white !important",
    },
  },
  areaMobileFilters: {
    position: "absolute",
    right: 16,
    top: 17,
  },
  container: {
    position: "relative",
  },
  overlay: {
    height: 52,
    width: 400,
    position: "absolute",
    left: "50%",
    right: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999,
  },
  loadingSpinnerOverlay: {
    height: ({ schedulerHeight }) => schedulerHeight || "auto",
    position: "absolute",
    width: "100%",
    zIndex: 999,
  },
  prompt: {
    borderRadius: 8,
    backgroundColor: "#f0f0f0",
    opacity: 1,
  },
}))

const getDefaultCalendarUrl = () => `/calendar/week/${moment().format("YYYY-MM-DD")}/all/all`

const Calendar = () => {
  const history = useHistory()
  const { location } = useAuth()
  const { height: windowHeight } = useWindowSize()
  const schedulerHeight = (windowHeight || 1280) - 208
  const classes = useStyles({ schedulerHeight })
  const theme = useTheme()
  const disableContentGutters = useMediaQuery(theme.breakpoints.down("md"))
  const { filterChange } = useReportUtils()
  const { view, day, groups, schedules } = useParams()
  const [loadCalendar, { data, loading, startPolling, stopPolling }] = useLazyQueryCalendar()

  const groupIds = useMemo(() => groups?.split("-") || ["all"], [groups])
  const repeatItems = useMemo(() => schedules?.split("-") || ["all"], [schedules])

  const monthView = view === "month"
  const allGroups = groups === "all"

  useMountEffect(() => {
    if (!["week", "month"].includes(view)) {
      history.push(getDefaultCalendarUrl())
    }
  })

  useAsyncEffect(async () => {
    // don't load for month + all groups
    if (view === "month" && groups === "all") {
      return
    }

    const endMoment = moment.tz(moment(day), location.timeZone).endOf(view)
    if (!["day", "week"].includes(view)) {
      endMoment.startOf("day")
    }

    await loadCalendar({
      variables: {
        start: momentToScheduleDate(moment.tz(moment(day), location.timeZone).startOf(view)),
        end: momentToScheduleDate(endMoment),
        location: toId(location),
        groups: groups === "all" ? null : groups?.split("-"),
        schedules: schedules === "all" ? null : schedules?.split("-"),
      },
    })

    startPolling(1000 * 20)
    return () => stopPolling()
  }, [view, day, groups, location, loadCalendar, schedules])

  const handleDateNavigate = (move) => {
    let newDate = moment.tz(day, location.timeZone)
    if (move === -1) newDate.subtract(1, view)
    else if (move === 1) newDate.add(1, view)
    else newDate = move

    history.push(
      `/calendar/${view}/${moment.tz(moment(newDate), location.timeZone).format("YYYY-MM-DD")}/${groups}/${schedules}`,
    )
  }

  const handleViewChange = (event, newView) => {
    if (!newView) {
      return
    }
    history.push(`/calendar/${newView}/${day}/${groups}/${schedules}`)
  }

  const handleRepeatsChange = (event) => {
    history.push(`/calendar/${view}/${day}/${groups}/${filterChange(repeatItems, event).join("-")}`)
  }

  const handleGroupsChange = (event) => {
    history.push(`/calendar/${view}/${day}/${mapToIds(filterChange(groupIds, event)).join("-")}/${schedules}`)
  }

  const loadingSpinnerOverlay = (
    <ColumnBox justifyContent="center" alignItems="center" className={classes.loadingSpinnerOverlay}>
      <Box>
        <LoadingSpinner size={70} />
      </Box>
    </ColumnBox>
  )

  return (
    <Box className={classes.container}>
      <AreaHeader
        title={
          <Box ml={1}>
            <Trans>Calendar</Trans>
          </Box>
        }
        subTitle={
          <Box ml={1}>
            <DeviceGroupsDisplay />
          </Box>
        }
        titleIcon="calendar"
        desktopButtons={
          <RowBox>
            <Hidden smDown>
              <GroupOutlinedSelect
                label=""
                allLabel={monthView && allGroups ? t`Select areas of work` : t`Any area of work`}
                value={groupIds}
                className={classes.areaHeaderSelect}
                multiple
                size="small"
                hasAll={!monthView || allGroups}
                onChange={handleGroupsChange}
              />

              <ScheduleOutlinedSelect
                label=""
                allLabel={t`Any schedule`}
                value={repeatItems}
                className={classes.areaHeaderSelect}
                multiple
                size="small"
                hasAll
                onChange={handleRepeatsChange}
              />

              <CalendarViewToggleButton view={view} onViewChange={handleViewChange} />
            </Hidden>
            <Hidden mdUp>
              <CalendarFiltersButton
                view={view}
                groups={groups}
                schedules={schedules}
                onGroupsChange={handleGroupsChange}
                onRepeatsChange={handleRepeatsChange}
                onViewChange={handleViewChange}
              />
            </Hidden>
          </RowBox>
        }
        mobileButtons={
          <Box className={classes.areaMobileFilters}>
            <CalendarFiltersButton
              view={view}
              groups={groups}
              schedules={schedules}
              onGroupsChange={handleGroupsChange}
              onRepeatsChange={handleRepeatsChange}
              onViewChange={handleViewChange}
            />
          </Box>
        }
        beta
      />

      <DateNavigatorBar date={day} mode={view} onDateNavigate={handleDateNavigate} />

      {loading && !data?.calendar.length && loadingSpinnerOverlay}

      {!loading && view === "month" && allGroups && (
        <ColumnBox mt={10} alignItems="center" className={classes.overlay}>
          <NoItemsMessage className={classes.prompt}>
            <Trans>Please select one or more areas of work</Trans>
          </NoItemsMessage>
        </ColumnBox>
      )}

      <Content full start pt={0} mb={0} disableGutters={disableContentGutters}>
        <Suspense fallback={loadingSpinnerOverlay}>
          {view === "month" && <MonthCalendar />}
          {view === "week" && <WeekCalendar />}
        </Suspense>
      </Content>
    </Box>
  )
}

const index = () => (
  <Switch>
    <PrivateRoute path="/calendar/:view/:day/:groups/:schedules" component={Calendar} />
    <Redirect to={getDefaultCalendarUrl()} />
  </Switch>
)

export default index

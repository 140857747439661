import React, { useMemo, useState } from "react"
import fileDownload from "js-file-download"
import { formatters } from "json2csv"
import { Box, makeStyles } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { RowBox, ReportSectionActionCard, HorizontalLegend, HorizontalLegendItem, NoItemsMessage } from ".."
import { mapToIds, toId } from "../../utils"
import { useAuth } from "../../services"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import ReportToolsIconButton from "../Buttons/ReportToolsIconButton"
import { useQueryReportJobsLocationsCompletions } from "../../data/report/useQueryReportJobsLocationsOverall"
import { CircularJobCompletionChart } from "../Charts/CircularJobCompletionChart"
import { useQueryRegions } from "../../data/locations/useQueryRegions"

const useStyles = makeStyles((theme) => ({
  results: {
    overflowX: "auto",
  },
  resultsFullscreen: {
    flexWrap: "wrap",
    justifyContent: "center",
  },
  legendFullscreen: {
    position: "absolute",
    bottom: theme.spacing(4),
    left: theme.spacing(4),
  },
}))

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.rank > b.rank ? -1 : 1)

const RegionsJobCompletionReport = ({ gt, lt, locations, groups, tags, onRegionClick }) => {
  const [sort, setSort] = useState("alpha")
  const [fullscreen, setFullscreen] = useState(false)
  const classes = useStyles()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data: regionsData, loading: regionsLoading } = useQueryRegions()
  const { data: reportData, loading: reportLoading } = useQueryReportJobsLocationsCompletions({
    variables: {
      input: {
        locations: locations.includes("all") ? mapToIds(userLocations) : locations,
        groups: groups.includes("all") ? null : groups,
        gt,
        lt,
        tags: tags.includes("all") ? null : tags,
      },
    },
    skip: !gt,
  })

  const handleRegionClick = ({ id }) => {
    onRegionClick && onRegionClick(id)
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  const handleFullscreen = (newFullscreen) => {
    setFullscreen(newFullscreen)
  }

  const chartData = useMemo(() => {
    if (!reportData || !regionsData) {
      return []
    }

    return [...regionsData.regions, { id: null, name: "No region" }]
      .map((region) => {
        const regionLocations = reportData?.report.jobs.locations.completions.filter((location) =>
          region.id ? toId(location.region) === toId(region) : !location.region,
        )

        const regionCompletions = regionLocations.reduce(
          (acc, { completions }) => {
            acc.completedOnTime += completions.completedOnTime
            acc.completedLate += completions.completedLate
            acc.inProgress += completions.inProgress
            acc.uncompleted += completions.uncompleted
            acc.uncompletedOverdue += completions.uncompletedOverdue
            return acc
          },
          { completedOnTime: 0, completedLate: 0, inProgress: 0, uncompleted: 0, uncompletedOverdue: 0 },
        )

        regionCompletions.total =
          regionCompletions.completedOnTime +
          regionCompletions.completedLate +
          regionCompletions.inProgress +
          regionCompletions.uncompleted +
          regionCompletions.uncompletedOverdue

        return {
          id: toId(region),
          name: region.name,
          locations: regionLocations,
          completions: regionCompletions,
          rank:
            regionCompletions.total > 0
              ? (regionCompletions.completedOnTime + regionCompletions.completedLate) / regionCompletions.total
              : -1,
        }
      })
      .sort((a, b) => sortData(sort, a, b))
  }, [reportData, regionsData, sort])

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Name", "On-time", "Late", "In-Progress", "Overdue", "Uncompleted"]
    const rows = [...chartData].map(
      ({ name, completions: { completedOnTime, completedLate, inProgress, uncompleted, uncompletedOverdue } }) =>
        [stringFormatter(name), completedOnTime, completedLate, inProgress, uncompleted, uncompletedOverdue].join(","),
    )

    const csv = `${header.join(",")}\n${rows.join("\n")}`

    fileDownload(csv, "regions-completion.csv", "text/csv")
  }

  const loading = reportLoading || regionsLoading

  return (
    <ReportSectionActionCard
      title="Jobs completed by region"
      detail="Which regions are completing their assigned jobs?"
      loading={loading}
      onFullscreen={handleFullscreen}
      tools={
        chartData?.length > 1 ? (
          <RowBox>
            <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
            <Box ml={1}>
              <ReportToolsIconButton
                title={sort === "alpha" ? "Sort by completions %" : "Sort alphabetically"}
                icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
                onClick={handleSort}
                disabled={loading}
              />
            </Box>
          </RowBox>
        ) : null
      }
    >
      {chartData?.length > 0 && (
        <>
          <RowBox alignItems="flex-start" className={fullscreen ? classes.resultsFullscreen : classes.results}>
            {chartData.map(({ id, name, completions }) => (
              <CircularJobCompletionChart
                key={id}
                text={name}
                data={completions}
                onClick={() => handleRegionClick({ id })}
              />
            ))}
          </RowBox>
          <HorizontalLegend className={fullscreen ? classes.legendFullscreen : undefined}>
            <HorizontalLegendItem text="Completed (On time)" color="success" />
            <HorizontalLegendItem text="Completed (Late)" color="warning" />
            <HorizontalLegendItem text="In Progress" color="primary" />
            <HorizontalLegendItem text="Overdue" color="secondary" />
            <HorizontalLegendItem text="Uncompleted" color="background" />
          </HorizontalLegend>
        </>
      )}
      {chartData.length === 0 && (
        <NoItemsMessage>
          <Trans>No data found</Trans>
        </NoItemsMessage>
      )}
    </ReportSectionActionCard>
  )
}

export { RegionsJobCompletionReport }

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core"
import { NavLink } from "react-router-dom"
import { Fragment, useRef, useState } from "react"
import pluralize from "pluralize"
import { Trans } from "@lingui/macro"
import { DialogTitleCloser } from "../Creators"
import { ROLE_SCOPE, useQueryAdminableRole, useQueryGroupedPermissions } from "../../data"
import { toId } from "../../utils"
import { LoadingSpinner } from "../LoadingSpinner"
import { ColumnBox, FlexBox, RowBox } from "../Boxes"
import { InfoSection } from "../Viewers"
import { Icon } from "../Icon"
import { snakeToTitle } from "../../utils/string"
import { ListWindowVirtualizer } from "../Lists/ListWindowVirtualizer"

const useStyles = makeStyles((theme) => ({
  panel: {
    backgroundColor: theme.palette.shaded.filter,
    borderRadius: "8px",
  },
  dialogPaper: {
    minHeight: ({ showingMessage }) =>
      !showingMessage ? `calc(100vh - ${theme.dimensions.dialogs.margin * 2}px)` : "auto",
  },
  avatar: {
    width: 80,
    height: 80,
    fontSize: 24,
  },
  nameTitle: {
    fontSize: 24,
    fontWeight: "500",
    lineHeight: "30px",
  },
  description: {
    fontSize: 14,
    lineHeight: "16px",
  },
}))

const usePermissionsListStyles = makeStyles((theme) => ({
  description: {
    textAlign: "left",
    paddingLeft: theme.spacing(1),
  },
  tick: {
    color: theme.palette.success.main,
  },
  cross: {
    color: theme.palette.error.main,
  },
}))

const RolesList = ({ roles }) => {
  if (!roles.length) {
    return <Box>(none)</Box>
  }

  return (
    <>
      {roles.map((role) => (
        <Box key={toId(role)}>{role.friendlyName}</Box>
      ))}
    </>
  )
}

const PermissionsList = ({ permissions, ...rest }) => {
  const classes = usePermissionsListStyles()
  const { data: permissionsGroupedData, loading } = useQueryGroupedPermissions()

  if (!permissions.length) {
    return <Box>(none)</Box>
  }

  if (loading) {
    return (
      <FlexBox justifyContent="center" py={1}>
        <LoadingSpinner size={40} delay={false} />
      </FlexBox>
    )
  }

  const groupedPermissions =
    permissionsGroupedData?.permissions.grouped.reduce((acc, permissionGroup) => {
      acc.push({ key: permissionGroup.key, type: "permissionGroup" })
      acc.push(
        ...permissionGroup.permissions.map((permission) => ({
          key: permission.key,
          description: permission.description,
          value: permissions.find((p) => p.key === permission.key)?.value || false,
          type: "permission",
        })),
      )
      return acc
    }, []) || []

  return (
    <ListWindowVirtualizer
      items={groupedPermissions}
      itemContent={(index, { type, key, value, description }) => {
        if (type === "permissionGroup") {
          return (
            <Fragment key={key}>
              {index > 0 && <Divider light />}
              <InfoSection title={snakeToTitle(pluralize(key))} compact />
            </Fragment>
          )
        }

        return (
          <Fragment key={key}>
            {index > 0 && <Divider light />}
            <InfoSection compact>
              <RowBox>
                <FlexBox alignItems="center" alignSelf="center">
                  <Tooltip title={key}>
                    <div style={{ display: "flex" }}>
                      <Icon
                        name={value ? "tick" : "cross"}
                        className={value ? classes.tick : classes.cross}
                        fontSize="small"
                      />
                    </div>
                  </Tooltip>
                </FlexBox>
                <ColumnBox className={classes.description}>{description || key}</ColumnBox>
              </RowBox>
            </InfoSection>
          </Fragment>
        )
      }}
      {...rest}
    />
  )
}

const RoleViewer = ({ id, open, onClose }) => {
  const classes = useStyles()
  const { data, loading } = useQueryAdminableRole({ skip: !id || !open, variables: { id } })
  const [tab, setTab] = useState("effective")
  const dialogScrollerRef = useRef(null)

  const role = data?.role

  const {
    friendlyName,
    description,
    inheritsRoles,
    createsRoles,
    mentionsRoles,
    scope,
    permissions,
    effectivePermissions,
  } = role || {}

  const handleClose = () => {
    onClose && onClose(scope)
  }

  const handleTabClick = (newTab) => {
    setTab(newTab)
  }

  const isOrganisation = scope === ROLE_SCOPE.ORGANISATION

  const isInheriting = inheritsRoles?.length > 0

  const combinedRolesInfo = role
    ? [
        { roles: inheritsRoles, subject: "Inherits" },
        { roles: createsRoles, subject: "Creates" },
        { roles: mentionsRoles, subject: "Mentions" },
      ].filter((rolesInfo) => rolesInfo.roles.length > 0)
    : []

  const display = (
    <Box>
      {loading && !role && (
        <FlexBox justifyContent="center" mt={3}>
          <LoadingSpinner size={60} delay={false} />
        </FlexBox>
      )}
      {role && open && (
        <>
          <Box className={classes.panel} mb={3}>
            <RowBox alignItems="center" py={3} px={3}>
              {!isOrganisation && <Icon name="operandio" size={35} link="false" />}
              {isOrganisation && <Icon name="role" fontSize="large" />}
              <Box ml={2} flexGrow={1}>
                <Box display="flex" flexGrow={1} justifyContent="flex-start">
                  <Typography component="h1" className={classes.nameTitle}>
                    {friendlyName}
                  </Typography>
                </Box>
                <Box className={classes.description}>{description}</Box>
              </Box>
            </RowBox>
          </Box>
          {combinedRolesInfo.length > 0 && (
            <Box mb={3}>
              <Paper elevation={3}>
                {combinedRolesInfo.map(({ roles, subject }, index) => (
                  <Fragment key={subject}>
                    {index > 0 && <Divider light />}
                    <InfoSection
                      title={
                        <>
                          {subject} {pluralize("role", roles.length)}
                        </>
                      }
                      value={<RolesList roles={roles} />}
                      compact
                    />
                  </Fragment>
                ))}
              </Paper>
            </Box>
          )}

          <Tabs
            variant="standard"
            value={isInheriting ? tab : "explicit"}
            indicatorColor="primary"
            textColor="primary"
            aria-label="Select permissions "
          >
            {isInheriting && (
              <Tab
                label={<Trans>Effective permissions</Trans>}
                value="effective"
                onClick={() => handleTabClick("effective")}
              />
            )}
            <Tab label={<Trans>Role permissions</Trans>} value="explicit" onClick={() => handleTabClick("explicit")} />
          </Tabs>

          <Divider />

          <Box my={3}>
            <Paper elevation={3}>
              {isInheriting && tab === "effective" && (
                <PermissionsList permissions={effectivePermissions} customScrollParent={dialogScrollerRef.current} />
              )}
              {isInheriting && tab === "explicit" && (
                <PermissionsList permissions={permissions} customScrollParent={dialogScrollerRef.current} />
              )}
              {!isInheriting && (
                <PermissionsList permissions={effectivePermissions} customScrollParent={dialogScrollerRef.current} />
              )}
            </Paper>
          </Box>
        </>
      )}
    </Box>
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="form-dialog-title">
        Permission level details
        <DialogTitleCloser onClose={handleClose} />
      </DialogTitle>
      <DialogContent ref={(ref) => (dialogScrollerRef.current = ref)}>{display}</DialogContent>
      {isOrganisation && (
        <DialogActions>
          <Box pt={1} pr={2} pb={2}>
            <Button
              component={NavLink}
              variant="contained"
              color="primary"
              to={`/account/roles/${scope}/${toId(role)}/edit`}
            >
              Edit details
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}

export { RoleViewer }

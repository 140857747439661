import { gql } from "@apollo/client"

const JOB_NOTES_FIELDS = gql`
  fragment JobNotesFields on JobProcessStepNote {
    id
    text
    author {
      id
      fullName
      firstName
      lastName
      avatar {
        key
      }
    }
    uploads {
      id
      key
      fileName
      fileGroup
      fileSize
    }
    jobStep {
      id
      name
    }
    createdAt
  }
`

export { JOB_NOTES_FIELDS }

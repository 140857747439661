import React, { useEffect } from "react"
import { Box, Grid } from "@material-ui/core"
import { useParams, useHistory } from "react-router-dom"
import { t } from "@lingui/macro"
import { CreatorMaster } from "../Creators"
import { ColumnBox } from "../Boxes"
import { TileButton } from "../Buttons"
import ScheduleSourceTrigger from "./ScheduleSourceTrigger"
import EventSourceTrigger from "./EventSourceTrigger"
import { TextDivider } from "../TextDivider"
import { useLazyQueryTrigger } from "../../data/triggers/useQueryTrigger"

const TriggerCreator = ({ open, onClose, id, isInline, cy = "TriggerCreator" }) => {
  const { type } = useParams()
  const history = useHistory()
  const [load, { data, loading }] = useLazyQueryTrigger()

  useEffect(() => {
    if (id !== "new") {
      load({ variables: { id } })
    }
  }, [id, load])

  const handleClose = () => {
    onClose && onClose()
  }

  const handleTypeChange = (newType) => {
    history.push(`/account/triggers/${newType}/new`)
  }

  const isEdit = id !== "new"

  const edit = data?.trigger || null

  const form = (
    <>
      {!isEdit && (
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ColumnBox flexGrow={1}>
                <TileButton
                  title={t`Scheduled`}
                  description={t`Specify a schedule on which to run this trigger, for example every week`}
                  active={type === "schedule"}
                  onClick={() => handleTypeChange("schedule")}
                  style={{ width: "100%" }}
                  data-cy="TileButton-schedule"
                />
              </ColumnBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <TileButton
                title={t`Event based`}
                description={t`Run this trigger when a specific event occurs in Operandio, for example a job becoming complete`}
                active={type === "event"}
                onClick={() => handleTypeChange("event")}
                style={{ width: "100%" }}
                data-cy="TileButton-event"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {Boolean(type) && (
        <>
          {!isEdit && <TextDivider mb={2} />}

          {type === "schedule" && <ScheduleSourceTrigger onClose={handleClose} edit={edit} cy={cy} />}
          {type === "event" && <EventSourceTrigger onClose={handleClose} edit={edit} cy={cy} />}
        </>
      )}
    </>
  )

  return (
    <CreatorMaster
      open={open}
      subject={type === "schedule" ? t`Scheduled trigger` : t`Event trigger`}
      form={form}
      loading={loading || (isEdit && !edit)}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export default TriggerCreator

import React, { Suspense, useState } from "react"
import { Switch, useHistory, useParams, useLocation } from "react-router-dom"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Grid,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import Config from "react-global-configuration"
import { Trans } from "@lingui/macro"
import {
  Content,
  BackToTop,
  AreaHeader,
  PrivateRoute,
  LabelsItemCreator,
  LabelsCategoryCreator,
  LabelsTemplateCreator,
  LabelsSubNav,
  DesktopCreateLabelsButton,
  MobileCreateLabelsButton,
  LoadingSpinner,
  RowBox,
  Icon,
} from "../../components"
import { useMutationDeleteLabelCategory, useMutationDeleteLabelTemplate, useMutationDeleteLabel } from "../../data"
import Templates from "./Templates"
import Items from "./Items"
import Category from "./Category"
import { toId } from "../../utils"
import { useAuth } from "../../services"

const MoreButton = () => {
  const [open, setOpen] = useState(false)
  const configLinks = Config.get("links")
  const { location, settings } = useAuth()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button variant="contained" onClick={() => setOpen(true)}>
        <Trans>Order supplies</Trans>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans>Order supplies</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <Trans>
                For customers in Australia and New Zealand,{" "}
                <a href="https://www.barcodes.com.au/operandio/" target="_blank" rel="noreferrer">
                  Barcodes.com.au
                </a>{" "}
                is our recommended supplier for Zebra printers and labels. Supplies can be ordered online through their
                website.
              </Trans>
              <Paper elevation={3}>
                <Box
                  mt={1}
                  p={2}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ fontSize: 14 }}
                >
                  <strong>Barcodes.com.au</strong>
                  <Button variant="contained" href="https://www.barcodes.com.au/operandio/" target="_blank">
                    <RowBox>
                      <Box mr={1}>
                        <Trans>Order supplies</Trans>
                      </Box>
                      <Icon name="external-link" fontSize="small" />
                    </RowBox>
                  </Button>
                </Box>
              </Paper>
            </Box>
            <Box mt={3}>
              <strong>
                <Trans>Other support</Trans>
              </strong>
              <br />
              <Trans>
                For all other support enquiries not related to labels, please{" "}
                <a
                  href={`mailto:${configLinks.emailSupport}?subject=User%20support%20request%20-%20${encodeURIComponent(
                    settings.organisation.name,
                  )}${location && location.name ? `%20(${encodeURIComponent(location.name)})` : ""}`}
                >
                  contact general support
                </a>
                .
              </Trans>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const LabelsItemPrinter = React.lazy(() => import("../../components/Labels/LabelsItemPrinter"))

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
  },
}))

const useGridItemStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}))

const Labels = () => {
  const classes = useStyles()
  const gridItemClasses = useGridItemStyles()
  const theme = useTheme()
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const sm = useMediaQuery(theme.breakpoints.down("sm"))

  const { type, id, action } = useParams()
  const history = useHistory()
  const location = useLocation()
  const background = location.state && location.state.background // This is the "location" where the user was when the modal is clicked.

  const [openSubNav, setOpenSubNav] = useState(false)

  const [deleteLabel] = useMutationDeleteLabel()
  const [deleteLabelCategory] = useMutationDeleteLabelCategory()
  const [deleteLabelTemplate] = useMutationDeleteLabelTemplate()

  // User can create either a new label item or a new label template
  const handleCreateClick = (create) => {
    if (create === "label") {
      history.push("/labels/item/new")
    } else {
      history.push("/labels/template/new")
    }
  }

  const handleClose = () => {
    history.goBack()
  }

  const handleToggleSubNav = () => {
    setOpenSubNav(!openSubNav)
  }

  // Label item creation, edit and delete
  const handleCreateItemCreated = () => {
    history.push("/labels")
  }

  const handleEditItem = (item) => {
    history.push({ pathname: `/labels/item/${toId(item)}/edit`, state: { background: location } })
  }

  const handleDeleteItem = async ({ id: deleteId }) => {
    await deleteLabel({
      variables: {
        id: deleteId,
      },
    })
  }

  // Label category edit and delete
  const handleEditCategory = (category) => {
    history.push(`/labels/category/${toId(category)}/edit`)
  }
  const handleDeleteCategory = async ({ id: deleteId }) => {
    await deleteLabelCategory({
      variables: {
        id: deleteId,
      },
    })
  }

  // Label template creation, edit and delete
  const handleCreateTemplateCreated = () => {
    history.push("/labels/templates")
  }

  const handleEditTemplate = (template) => {
    history.push(`/labels/template/${toId(template)}/edit`)
  }

  const handleDeleteTemplate = async ({ id: deleteId }) => {
    await deleteLabelTemplate({
      variables: {
        id: deleteId,
      },
    })
  }

  const isNew = id === "new"
  const isEdit = action === "edit"
  const isPrint = action === "print"

  const openPrint = type === "item" && isPrint
  const openItem = type === "item" && (isNew || isEdit)
  const openTemplate = type === "template" && (isNew || isEdit)
  const openCategory = type === "category" && (isNew || isEdit)

  return (
    <>
      {openItem && (
        <LabelsItemCreator
          open={openItem}
          onCreated={handleCreateItemCreated}
          onClose={handleClose}
          isInline={xs}
          edit={isEdit}
        />
      )}

      {openTemplate && (
        <LabelsTemplateCreator
          open={openTemplate}
          onCreated={handleCreateTemplateCreated}
          onClose={handleClose}
          isInline={xs}
          edit={isEdit}
        />
      )}

      {openCategory && <LabelsCategoryCreator open={openCategory} onClose={handleClose} isInline={xs} edit={isEdit} />}

      {(!sm || (sm && !openItem && !openTemplate && !isPrint && !isEdit)) && (
        <Box className={classes.container}>
          <AreaHeader
            title={<Trans>Labels</Trans>}
            titleIcon="labels-light"
            desktopButtons={
              <RowBox>
                <Box mr={1}>
                  <MoreButton />
                </Box>
                <DesktopCreateLabelsButton onCreateClick={handleCreateClick} />
              </RowBox>
            }
            mobileButtons={<MobileCreateLabelsButton onCreateClick={handleCreateClick} />}
          />

          <Content full start>
            <Grid container direction="row">
              {(kiosk || device) && <LabelsSubNav variant="horizontal" />}

              {!kiosk && !device && (
                <Grid item sm={12} md={3} classes={gridItemClasses}>
                  <LabelsSubNav open={openSubNav} onClose={handleToggleSubNav} variant="vertical" />
                  <BackToTop from={300} />
                </Grid>
              )}

              <Grid item sm={12} md={!kiosk && !device ? 9 : 12} classes={gridItemClasses}>
                <Switch location={background || location}>
                  <PrivateRoute
                    exact
                    path="/labels/item/:id/:action"
                    component={() => (
                      <Items
                        onOpenSubNav={handleToggleSubNav}
                        onEditItem={handleEditItem}
                        onDeleteItem={handleDeleteItem}
                        onEditCategory={handleEditCategory}
                        onDeleteCategory={handleDeleteCategory}
                      />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/labels/item/:id"
                    component={() => (
                      <Items
                        onOpenSubNav={handleToggleSubNav}
                        onEditItem={handleEditItem}
                        onDeleteItem={handleDeleteItem}
                        onEditCategory={handleEditCategory}
                        onDeleteCategory={handleDeleteCategory}
                      />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/labels"
                    component={() => (
                      <Items
                        onOpenSubNav={handleToggleSubNav}
                        onEditItem={handleEditItem}
                        onDeleteItem={handleDeleteItem}
                        onEditCategory={handleEditCategory}
                        onDeleteCategory={handleDeleteCategory}
                      />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/labels/template/:id/:action"
                    component={() => (
                      <Templates
                        onClose={handleClose}
                        onEditTemplate={handleEditTemplate}
                        onDeleteTemplate={handleDeleteTemplate}
                      />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/labels/template/:id"
                    component={() => (
                      <Templates onEditTemplate={handleEditTemplate} onDeleteTemplate={handleDeleteTemplate} />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/labels/templates"
                    component={() => (
                      <Templates onEditTemplate={handleEditTemplate} onDeleteTemplate={handleDeleteTemplate} />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/labels/category/:id/:action"
                    component={() => (
                      <Category
                        kiosk={kiosk}
                        device={device}
                        onClose={handleClose}
                        onEditItem={handleEditItem}
                        onDeleteItem={handleDeleteItem}
                        onEditCategory={handleEditCategory}
                        onDeleteCategory={handleDeleteCategory}
                      />
                    )}
                  />

                  <PrivateRoute
                    exact
                    path="/labels/category/:id"
                    component={() => (
                      <Category
                        kiosk={kiosk}
                        device={device}
                        onClose={handleClose}
                        onEditItem={handleEditItem}
                        onDeleteItem={handleDeleteItem}
                        onEditCategory={handleEditCategory}
                        onDeleteCategory={handleDeleteCategory}
                      />
                    )}
                  />
                </Switch>
              </Grid>
            </Grid>
          </Content>
        </Box>
      )}

      {/* Show the modal when a `backgroundLocation` is set, such as when printing a label so as not to lose scroll position on the page */}
      {background && (
        <PrivateRoute
          exact
          path="/labels/item/:id/print"
          component={() => (
            <Suspense fallback={<LoadingSpinner />}>
              <LabelsItemPrinter kiosk={kiosk} device={device} open={openPrint} onClose={handleClose} isInline={sm} />
            </Suspense>
          )}
        />
      )}
    </>
  )
}

export default Labels

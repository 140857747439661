import React, { useState } from "react"
import { Switch, Redirect, useParams } from "react-router-dom"
import {
  Tabs,
  Grid,
  Box,
  Divider,
  Hidden,
  Drawer,
  List,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { MailOutline, Menu as MenuIcon } from "@material-ui/icons"
import Config from "react-global-configuration"
import { Trans } from "@lingui/macro"
import AccreditationTypes from "./AccreditationTypes"
import Billing from "./Billing"
import Devices from "./Devices"
import IdentityProviders from "./IdentityProviders"
import Integrations from "./Integrations"
import Organisation from "./Organisation"
import Locations from "./Locations"
import Tagging from "./Tagging"
import Training from "./Training"
import Accreditation from "./Accreditation"
import Groups from "./Groups"
import Roles from "./Roles"
import Notifications from "./SimpleNotifications"
import Profile from "./Profile"

import {
  AreaHeader,
  Content,
  ErrorBoundary,
  MainHeading,
  ModuleHeading,
  NavLinkTab,
  PrivateRoute,
  RowBox,
  SubNavigation,
  SubNavigationListItem,
  SubNavigationListItemLink,
  TextDivider,
} from "../../components"
import { UserPanel } from "../../components/UserPanel/UserPanel"
import { useAuth } from "../../services"
import { useQueryAdminableUser } from "../../data"
import Triggers from "./Triggers"
import UsernameChangeConfirmation from "./UsernameChangeConfirmation"
import EmailChangeDialog from "./Profile/EmailChangeDialog"
import { userStateVar } from "../../data/users/userStateVar"
import { useMountAwareReactiveVar } from "../../utils"
import { triggerUserHelpBeacon } from "../../utils/helpscout"

const useSubNavStyles = makeStyles((theme) => ({
  filterButton: {
    paddingTop: ({ xs }) => (xs ? 6 : "inherit"),
    paddingBottom: ({ xs }) => (xs ? 6 : "inherit"),
  },
  supportLink: {
    color: theme.palette.primary.main,
  },
}))

const ForceBillingNotice = () => {
  const {
    settings: {
      organisation: { disabled: forceBilling },
    },
  } = useAuth()

  if (!forceBilling) return null

  return (
    <Box pb={2}>
      <Alert severity="error">
        <AlertTitle>Payment required</AlertTitle>
        Your account is currently restricted. Please visit the billing portal below to reactivate your subscription.
        <br />
        <br />
        After payment has been made it may take several hours for access to be restored.
      </Alert>
    </Box>
  )
}

const AccountRoutes = () => (
  <ErrorBoundary>
    <Switch>
      <PrivateRoute exact path="/account/profile" component={Profile} />
      <PrivateRoute exact path="/account/notifications" component={Notifications} />
      <PrivateRoute exact path="/account/training" component={Training} />
      <PrivateRoute exact path="/account/accreditation" component={Accreditation} />
      <PrivateRoute exact path="/account/organisation" component={Organisation} requires="organisation_update" />
      <PrivateRoute exact path="/account/locations" component={Locations} />
      <PrivateRoute exact path="/account/tagging" component={Tagging} />
      <PrivateRoute exact path="/account/regions" component={Locations} />
      <PrivateRoute exact path="/account/groups/:id" component={Groups} requires="group_update" />
      <PrivateRoute exact path="/account/groups" component={Groups} requires="group_update" />
      <PrivateRoute exact path="/account/roles/:scope/:id/:action" component={Roles} requires="role_read" />
      <PrivateRoute exact path="/account/roles/:scope/:id" component={Roles} requires="role_read" />
      <PrivateRoute exact path="/account/roles/:scope" component={Roles} requires="role_read" />
      <PrivateRoute exact path="/account/roles" component={Roles} requires="role_read" />
      <PrivateRoute exact path="/account/accreditation-types" component={AccreditationTypes} />
      <PrivateRoute exact path="/account/billing" component={Billing} requires="billing_read" feature="billing" />
      <PrivateRoute
        exact
        path="/account/devices"
        component={Devices}
        requires={["devices_update_locations", "devices_update_all"]}
      />
      <PrivateRoute
        exact
        path="/account/identity-providers"
        component={IdentityProviders}
        requires={["identity_provider_update"]}
      />
      <PrivateRoute
        exact
        path="/account/integrations/provider/:integrationProviderId/connect"
        component={Integrations}
        requires="integration_update"
      />
      <PrivateRoute
        exact
        path="/account/integrations/provider/:integrationProviderId"
        component={Integrations}
        requires="integration_read"
      />
      <PrivateRoute
        exact
        path="/account/integrations/:id"
        component={Integrations}
        requires="integration_read"
        feature="integrations"
      />
      <PrivateRoute
        exact
        path="/account/integrations"
        component={Integrations}
        requires="integration_read"
        feature="integrations"
      />
      <PrivateRoute
        exact
        path="/account/triggers/:type/:id"
        component={Triggers}
        requires={["trigger_read", "trigger_read_locations"]}
        feature="triggers"
      />
      <PrivateRoute
        exact
        path="/account/triggers/:type"
        component={Triggers}
        requires={["trigger_read", "trigger_read_locations"]}
        feature="triggers"
      />
      <PrivateRoute
        exact
        path="/account/triggers"
        component={Triggers}
        requires={["trigger_read", "trigger_read_locations"]}
        feature="triggers"
      />
      <PrivateRoute exact path="/account/triggers" component={Triggers} requires="trigger_read" feature="triggers" />
      <PrivateRoute exact path="/account/confirm-email/:token" component={UsernameChangeConfirmation} />
      <Redirect to="/account/profile" />
    </Switch>
  </ErrorBoundary>
)

const AccountUser = () => {
  const params = useParams()
  const {
    principal,
    hasPermission,
    hasFeature,
    settings: {
      organisation: { disabled: forceBilling },
    },
  } = useAuth()
  const { data: userData } = useQueryAdminableUser({
    variables: { id: principal.userID, organisation: principal.organisation },
  })
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const store = useMountAwareReactiveVar(userStateVar)

  const handleOpenChangeEmailDialogClose = () => {
    userStateVar({
      ...store,
      openEmailChangeDialog: false,
    })
  }

  const handleChangeEmailSuccessful = () => {
    userStateVar({
      ...store,
      openEmailChangeDialog: false,
      isPendingUsernameChange: true,
    })
  }

  const NavTab = ({ value }) => <NavLinkTab label={getHeading(value)} base="/account/" value={value} />

  return (
    <ErrorBoundary>
      <EmailChangeDialog
        open={store?.openEmailChangeDialog}
        onClose={() => handleOpenChangeEmailDialogClose()}
        onSuccess={() => handleChangeEmailSuccessful()}
        changeByAdmin={false}
        isInline={xs}
      />

      {(!xs || (xs && !store?.openEmailChangeDialog)) && (
        <>
          <AreaHeader title={<Trans>Settings</Trans>} titleIcon="settings-light" />
          <Content full start mb={5}>
            <Grid container direction="row">
              <Hidden mdDown>
                <Grid item md="auto">
                  {userData && <UserPanel user={userData.adminableUser} mt={1} />}
                </Grid>
              </Hidden>
              <Grid item xs>
                <ForceBillingNotice />
                <PageHeadingAndMobileSubNav />
                <Hidden mdDown>
                  <Tabs
                    variant="standard"
                    value={forceBilling ? "billing" : params.page || "profile"}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Select account setting area"
                  >
                    <NavTab value="profile" />
                    {hasFeature("training") && <NavTab value="training" />}
                    {hasFeature("accreditation") && <NavTab value="accreditation" />}
                    <NavTab value="locations" />
                    {hasPermission("group_update") && <NavTab value="groups" />}
                    {hasPermission("role_read") && <NavTab value="roles" />}
                    {hasPermission("organisation_update") && <NavTab value="organisation" />}
                    <NavTab value="notifications" />
                    {hasFeature("billing") && hasPermission("billing_read") && <NavTab value="billing" />}
                    {hasPermission(["devices_update_locations", "devices_update_all"]) && <NavTab value="devices" />}
                    {hasFeature("identity_providers") && hasPermission("identity_provider_update") && (
                      <NavTab to="identity-providers" />
                    )}
                  </Tabs>
                </Hidden>

                <Divider />

                <Box pt={5}>
                  <AccountRoutes />
                </Box>
              </Grid>
            </Grid>
          </Content>
        </>
      )}
    </ErrorBoundary>
  )
}

const AccountAdmin = () => {
  const { page } = useParams()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const store = useMountAwareReactiveVar(userStateVar)

  const handleOpenChangeEmailDialogClose = () => {
    userStateVar({
      ...store,
      openEmailChangeDialog: false,
    })
  }

  const handleChangeEmailSuccessful = () => {
    userStateVar({
      ...store,
      openEmailChangeDialog: false,
      isPendingUsernameChange: true,
    })
  }

  return (
    <>
      <ErrorBoundary>
        <EmailChangeDialog
          open={store?.openEmailChangeDialog}
          onClose={() => handleOpenChangeEmailDialogClose()}
          onSuccess={() => handleChangeEmailSuccessful()}
          changeByAdmin={false}
          isInline={xs}
        />

        {(!xs || (xs && !store?.openEmailChangeDialog)) && (
          <>
            <AreaHeader title={<Trans>Settings</Trans>} subTitle="Administrator" titleIcon="settings-light" />

            <Content full start>
              <Grid container direction="row">
                <Grid item xs={12} lg={3}>
                  <AdminSubNav />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Grid item xs>
                    <ForceBillingNotice />

                    {page !== "confirm-email" && <PageHeadingAndMobileSubNav admin />}

                    <AccountRoutes />
                  </Grid>
                </Grid>
              </Grid>
            </Content>
          </>
        )}
      </ErrorBoundary>
    </>
  )
}

const AdminSubNav = () => {
  const { page } = useParams()
  const {
    hasPermission,
    hasFeature,
    permissionGroups: { adminAccreditations },
  } = useAuth()

  const NavItem = ({ to }) => <SubNavigationListItem to={`/account/${to}`} text={getHeading(to)} active={page === to} />

  return (
    <Hidden mdDown>
      <SubNavigation>
        <List>
          <NavItem to="profile" />
          <NavItem to="notifications" />
          {hasFeature("training") && <NavItem to="training" />}
          {hasFeature("accreditation") && <NavItem to="accreditation" />}
          <Box my={1}>
            <TextDivider />
          </Box>
          {hasPermission("organisation_update") && <NavItem to="organisation" />}
          <NavItem to="locations" />
          {hasFeature("areas") && hasPermission(["area_update", "area_update_locations", "tag_update"]) && (
            <NavItem to="tagging" />
          )}
          {hasPermission("group_update") && <NavItem to="groups" />}
          {hasPermission("role_read") && <NavItem to="roles" />}
          {hasFeature("accreditation") && hasPermission(adminAccreditations) && <NavItem to="accreditation-types" />}
          {hasFeature("billing") && hasPermission("billing_read") && <NavItem to="billing" />}
          {hasPermission(["devices_update_locations", "devices_update_all"]) && <NavItem to="devices" />}
          {hasFeature("identity_providers") && hasPermission("identity_provider_update") && (
            <NavItem to="identity-providers" />
          )}
          {hasFeature("integrations") && hasPermission("integration_read") && <NavItem to="integrations" />}
          {hasFeature("triggers") && hasPermission(["trigger_read", "trigger_read_locations"]) && (
            <NavItem to="triggers" />
          )}
        </List>
      </SubNavigation>
    </Hidden>
  )
}

const PageHeadingAndMobileSubNav = ({ admin = false }) => {
  const classes = useSubNavStyles()
  const { page } = useParams()
  const { clientKiosk: kiosk } = Config.get()
  const {
    location,
    principal,
    hasPermission,
    hasFeature,
    settings,
    settings: {
      organisation: { disabled: forceBilling },
    },
    permissionGroups: { adminAccreditations },
  } = useAuth()
  const [open, setOpen] = useState(false)

  const handleToggleOpen = () => setOpen(!open)

  const handleClick = () => {
    setOpen(false)
  }

  const handleContactSupport = () => {
    triggerUserHelpBeacon(principal, location, settings)
    handleToggleOpen()
  }

  const NavItem = ({ to }) => (
    <SubNavigationListItem
      to={`/account/${to}`}
      text={getHeading(to)}
      active={page === to}
      onClick={() => handleClick()}
    />
  )

  const heading = getHeading(forceBilling ? "billing" : page)

  return (
    <>
      {admin && (
        <Hidden mdDown>
          <MainHeading mb={3.75} ml={0}>
            {heading}
          </MainHeading>
        </Hidden>
      )}
      <Hidden lgUp>
        <Box display="flex" alignItems="center" direction="row" mb={1}>
          <Box flexGrow={1}>
            <ModuleHeading noMargin>{heading}</ModuleHeading>
          </Box>
          <Box alignSelf="center">
            <IconButton onClick={handleToggleOpen} className={classes.filterButton}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Box>

        <Drawer open={open} onClose={handleToggleOpen}>
          <List>
            <NavItem to="profile" />
            <NavItem to="notifications" />
            {hasFeature("training") && <NavItem to="training" />}
            {hasFeature("accreditation") && <NavItem to="accreditation" />}
            <Box my={1}>
              <TextDivider />
            </Box>
            {hasPermission("organisation_update") && <NavItem to="organisation" />}
            <NavItem to="locations" />
            {hasFeature("areas") && hasPermission(["area_update", "area_update_locations", "tag_update"]) && (
              <NavItem to="tagging" />
            )}
            {hasPermission("group_update") && <NavItem to="groups" />}
            {hasPermission("role_update") && <NavItem to="roles" />}
            {hasFeature("accreditation") && hasPermission(adminAccreditations) && <NavItem to="accreditation-types" />}
            {hasFeature("billing") && hasPermission("billing_read") && <NavItem to="billing" />}
            {hasPermission(["devices_update_locations", "devices_update_all"]) && <NavItem to="devices" />}
            {hasFeature("identity_providers") && hasPermission("identity_provider_update") && (
              <NavItem to="identity-providers" />
            )}
            {hasFeature("integrations") && hasPermission("integration_read") && <NavItem to="integrations" />}
            {hasFeature("triggers") && hasPermission(["trigger_read", "trigger_read_locations"]) && (
              <NavItem to="triggers" />
            )}
            {!kiosk && (
              <Box my={1}>
                <TextDivider />
              </Box>
            )}
            {!kiosk && (
              <SubNavigationListItemLink onClick={handleContactSupport}>
                <RowBox className={classes.supportLink}>
                  <MailOutline />
                  <Box ml={1}>
                    <Trans>Contact support</Trans>
                  </Box>
                </RowBox>
              </SubNavigationListItemLink>
            )}
          </List>
        </Drawer>
      </Hidden>
    </>
  )
}

const getHeading = (page) => {
  switch (page) {
    case "profile":
      return <Trans>Your Profile</Trans>
    case "locations":
    case "regions":
      return <Trans>Locations</Trans>
    case "tagging":
      return <Trans>Tagging</Trans>
    case "training":
      return <Trans>Your Training</Trans>
    case "accreditation":
      return <Trans>Your Accreditations</Trans>
    case "groups":
      return <Trans>Areas of Work</Trans>
    case "roles":
      return <Trans>Permission Levels</Trans>
    case "accreditation-types":
      return <Trans>Accreditation Types</Trans>
    case "organisation":
      return <Trans>Company</Trans>
    case "notifications":
      return <Trans>Your Notifications</Trans>
    case "billing":
      return <Trans>Billing</Trans>
    case "devices":
      return <Trans>Devices</Trans>
    case "identity-providers":
      return <Trans>Single Sign-On</Trans>
    case "integrations":
      return <Trans>Integrations</Trans>
    case "triggers":
      return <Trans>Triggers</Trans>
    case "tags":
      return <Trans>Tags</Trans>
    default:
      return (
        <>
          <Trans>Loading...</Trans>
        </>
      )
  }
}

export { AccountUser, AccountAdmin }

import React, { useEffect, useMemo, useState } from "react"
import { Box, makeStyles, Grid, useTheme } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router"
import { Alert } from "@material-ui/lab"
import { Trans } from "@lingui/macro"
import {
  RowBox,
  NumberDisplay,
  LoadingSpinner,
  FlexBox,
  NoItemsMessage,
  ReportSectionActionCard,
} from "../../../components"
import { mapToIds, multipleSelectChange, toId, useDateUtils, useReportUtils } from "../../../utils"
import { useAuth } from "../../../services"
import { PROCESS_TYPE } from "../../../data"
import AuditScoringDaysReport from "../../../components/Reports/AuditScoringDaysReport"
import { useQueryReportAuditsOverview } from "../../../data/report/useQueryReportAuditsOverview"
import { ProcessChipMenu } from "../../../components/ChipMenu/ProcessChipMenu"
import { LocationChipMenu } from "../../../components/ChipMenu/LocationChipMenu"
import { DateRangeChipMenu } from "../../../components/ChipMenu/DateRangeChipMenu"
import { ReportsFilters } from "../../../components/Reports/ReportsFilters"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
    maxWidth: 300,
  },
  reports: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
}))

const initialLimit = 8

const AuditTiles = ({ gt, lt, locations, gridItemProps = {}, onNavigate }) => {
  const theme = useTheme()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data, loading } = useQueryReportAuditsOverview({
    variables: { locations: locations.includes("all") ? mapToIds(userLocations) : locations, gt, lt },
  })

  const handleNavigate = (process) => {
    onNavigate && onNavigate(process)
  }

  if (loading) {
    return (
      <FlexBox justifyContent="center" flexGrow={1} mt={3}>
        <LoadingSpinner size={60} delay={false} />
      </FlexBox>
    )
  }

  const { jobsTotal, jobsReturned } = data?.reportAuditsOverview?.counts || { jobsTotal: 0, jobsReturned: 0 }

  const zeroColour = theme.palette.text.secondary

  return (
    <>
      {jobsTotal > jobsReturned && (
        <Grid item xs={12}>
          <Alert severity="warning">
            Too much data to display. Showing {jobsReturned} of {jobsTotal} documents, try narrowing your filters.
          </Alert>
        </Grid>
      )}
      {data?.reportAuditsOverview?.list.map((process) => (
        <Grid key={toId(process)} item {...gridItemProps}>
          <ReportSectionActionCard
            title={process.name}
            detail={`${process.total} total`}
            cardProps={{ onClick: () => handleNavigate(process) }}
            layout="row"
            titleProps={{ pl: 2 }}
            p={0}
          >
            <RowBox px={2} pt={2}>
              <NumberDisplay
                value={process.submitted}
                color={process.submitted > 0 ? theme.palette.success.main : zeroColour}
                subject="Submitted"
              />
              <NumberDisplay
                value={process.overdue}
                color={process.overdue > 0 ? theme.palette.error.main : zeroColour}
                subject="Overdue"
                ml={2}
              />
              <NumberDisplay
                value={process.inProgress}
                color={process.inProgress ? theme.palette.primary.main : zeroColour}
                subject="In progress"
                ml={2}
              />
            </RowBox>
          </ReportSectionActionCard>
        </Grid>
      ))}
      {data?.reportAuditsOverview?.list.length === 0 && (
        <FlexBox justifyContent="center" flexGrow={1} mt={3}>
          <NoItemsMessage>
            <Trans>No audits found for the selected filters</Trans>
          </NoItemsMessage>
        </FlexBox>
      )}
    </>
  )
}

const Audits = () => {
  const classes = useStyles()
  const { days, locations, groups, tags, process } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToAuditsLink, getNavigateToLink } = useReportUtils()
  const [, setLimit] = useState(initialLimit)
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()

  const daysValue = days || 7
  const groupIds = groups?.split("-") || ["all"]
  const locationIds = useMemo(() => locations?.split("-") || ["all"], [locations])
  const tagIds = tags?.split("-") || ["all"]
  const processValue = process || "all"

  useEffect(() => {
    setLimit(initialLimit)
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      setGt(momentToScheduleDate(moment(daysParts[0])))
      setLt(momentToScheduleDate(moment(daysParts[1])))
    } else {
      setGt(momentToScheduleDate(moment().subtract(days, "days")))
      setLt(null)
    }
  }, [days, locations, momentToScheduleDate, process, userLocations])

  const handleFiltersMenuApply = (values) => {
    const { days: newDays, locations: newLocations, process: newProcess, tags: newTags } = values
    history.push(getNavigateToAuditsLink(newDays, newLocations, groupIds, newProcess, newTags))
  }

  const handleDaysChange = (newDays) => {
    history.push(getNavigateToAuditsLink(newDays, locationIds, groupIds, processValue))
  }

  const handleLocationsChange = (event) => {
    history.push(
      getNavigateToAuditsLink(daysValue, multipleSelectChange(locationIds, event), groupIds, processValue, tagIds),
    )
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToAuditsLink(daysValue, mapToIds(regionLocations), groupIds, processValue))
  }

  const handleJobChange = (newProcess) => {
    history.push(getNavigateToAuditsLink(daysValue, locationIds, groupIds, newProcess))
  }

  const handleNavigateToAudit = (audit) => {
    history.push(getNavigateToAuditsLink(daysValue, locationIds, groupIds, toId(audit)))
  }

  const handleReset = () => {
    history.push(getNavigateToLink("audits", 7, ["all"], ["all"]))
  }

  const isAll = processValue === "all"

  return (
    <>
      <ReportsFilters
        values={{
          days,
          locations: locationIds,
          // groups: groupIds,
          process: processValue,
          tags: tagIds,
        }}
        menu={{
          days: true,
          locations: true,
          process: true,
        }}
        onApply={handleFiltersMenuApply}
        onReset={handleReset}
      >
        <Box ml={0.5}>
          <DateRangeChipMenu value={days} onChange={handleDaysChange} onReset={handleReset} />
        </Box>
        <Box ml={0.5}>
          <LocationChipMenu
            value={locationIds}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            onReset={handleReset}
            multiple
          />
        </Box>
        <Box ml={0.5}>
          <ProcessChipMenu
            value={processValue}
            onChange={handleJobChange}
            onReset={handleReset}
            types={[PROCESS_TYPE.AUDIT]}
          />
        </Box>
        {/* {hasGroups && (
          <Box ml={0.5}>
            <GroupChipMenu value={groupIds} onChange={handleGroupsChange} multiple />
          </Box>
        )} */}
        {/* <Box ml={0.5}>
          <TagChipMenu value={tagIds} onChange={handleTagsChange} multiple />
        </Box> */}
      </ReportsFilters>

      {/* <Box p={3} className={classes.filter} mt={3} mb={3}>
        <Box mb={2}>
          <DateRangeOutlinedSelect value={daysValue} onChange={handleDaysChange} className={classes.filterSelect} />
        </Box>
        <Box mb={2}>
          <LocationOutlinedSelect
            value={locationIds}
            className={classes.filterSelect}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        <Box>
          <TemplateOutlinedSelect
            label="Audit"
            selectLabel="Select audit..."
            allLabel="All audits"
            value={processValue}
            className={classes.filterSelect}
            onChange={handleJobChange}
            hasAll
            types={[PROCESS_TYPE.AUDIT]}
          />
        </Box>
        <RowBox mt={2} justifyContent="flex-end">
          <Button variant="contained" onClick={handleReset}>
            Reset
          </Button>
        </RowBox>
      </Box> */}

      <Grid container spacing={1} className={classes.reports}>
        {isAll && (
          <AuditTiles
            gt={gt}
            lt={lt}
            locations={locationIds}
            gridItemProps={{ xs: 12 }}
            onNavigate={handleNavigateToAudit}
          />
        )}
        {!isAll && (
          <Grid item xs={12}>
            <AuditScoringDaysReport gt={gt} lt={lt} locations={locationIds} process={processValue} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Audits

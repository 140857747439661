import React, { useState } from "react"
import { Box, Button } from "@material-ui/core"
import { TabletMacOutlined } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import {
  DeviceActionCard,
  NoItemsMessage,
  DeviceCreator,
  SearchInput,
  LoadingSpinner,
  RequirePermissions,
} from "../../components"
import { useAuth } from "../../services"
import { useMutationDeleteDevice, useQueryDevices } from "../../data"
import { toId } from "../../utils"
import SettingsFooter from "../../components/Settings/SettingsFooter"
import { ListWindowVirtualizer } from "../../components/Lists/ListWindowVirtualizer"

const Devices = () => {
  const {
    settings: { locations },
  } = useAuth()
  const { data, refetch, loading } = useQueryDevices()
  const [deleteDevice] = useMutationDeleteDevice()

  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(null)
  const [searchText, setSearchText] = useState("")

  const handleFilter = (text) => {
    setSearchText(text.toLowerCase())
  }

  const handleCreateDevice = () => {
    setOpen(true)
  }
  const handleCreateDeviceClose = () => {
    setOpen(false)
    setEdit(null)
    refetch()
  }
  const handleEdit = (editDevice) => {
    setEdit(editDevice)
    setOpen(true)
  }
  const handleDelete = async (item) => {
    await deleteDevice({ variables: { id: toId(item) } })
    refetch()
  }

  if (!locations.length)
    return (
      <NoItemsMessage>
        <Trans>No locations are configured for your user, unable to register device</Trans>
      </NoItemsMessage>
    )

  const hasFilter = data?.devices.length > 1

  const items = data?.devices.filter((item) => !searchText || JSON.stringify(item).toLowerCase().includes(searchText))

  return (
    <>
      <DeviceCreator open={open} onClose={handleCreateDeviceClose} edit={edit} />

      {hasFilter && <SearchInput placeholder="Filter" boxProps={{ pl: 0, pr: 0 }} onChange={handleFilter} />}

      <Box mb={5} data-cy="Devices-list">
        <ListWindowVirtualizer
          items={items}
          itemContent={(_, device) => (
            <DeviceActionCard key={device.id} device={device} onEdit={handleEdit} onDelete={handleDelete} />
          )}
          components={{
            Footer: () => <Box my={10}>&nbsp;</Box>,
          }}
        />

        {!data && loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}

        {data?.devices.length === 0 && (
          <NoItemsMessage>
            <Trans>No devices registered</Trans>
          </NoItemsMessage>
        )}
      </Box>

      <RequirePermissions requires={["devices_update_all", "devices_update_locations"]}>
        <SettingsFooter>
          <Button variant="contained" color="primary" onClick={handleCreateDevice} data-cy="Button-create-device">
            <TabletMacOutlined />{" "}
            <Box ml={1}>
              <Trans>Register device</Trans>
            </Box>
          </Button>
        </SettingsFooter>
      </RequirePermissions>
    </>
  )
}

export default Devices

import React, { Suspense, useEffect, useMemo, useState } from "react"
import {
  Badge,
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  makeStyles,
  Typography,
  Avatar as MuiAvatar,
  Hidden,
  Button,
  Popover,
} from "@material-ui/core"
import Config from "react-global-configuration"
import { NavLink, useHistory, useParams } from "react-router-dom"
import moment from "moment-timezone"
import PopupState, { anchorRef, bindMenu, bindTrigger } from "material-ui-popup-state"
import { FilterList } from "@material-ui/icons"
import { Trans } from "@lingui/macro"
import {
  RowBox,
  PaperBox,
  NumberDisplay,
  TimeAgo,
  Icon,
  SensorSampleChart,
  SensorIndicator,
  DateRangeOutlinedSelect,
  ColumnBox,
  FormatDateCompact,
  LoadingSpinner,
  FlexBox,
  ErrorBoundary,
  sentryIgnoreBatteryProducts,
  NoItemsMessage,
} from "../../components"
import { SENSOR_SAMPLE_TYPE, useLazyQuerySensor } from "../../data"
import { SensorList } from "./Sensors"
import { convertCelsius, useWindowSize } from "../../utils"

const { HUMIDITY } = SENSOR_SAMPLE_TYPE

const useStyles = makeStyles((theme) => ({
  paperWarning: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "30px",
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  humidity: {
    fontWeight: "normal",
  },
  scale: {
    opacity: 0.3,
    fontSize: 18,
  },
  at: {},
  detail: {
    position: "sticky",
    top: 80,
  },
  sensorIcon: {
    color: "#000000",
  },
  days: {
    fontSize: 24,
    lineHeight: "30px",
    letterSpacing: "-2px",
  },
  warning: {
    color: "red",
    fontWeight: "600",
  },
}))

const SensorMap = React.lazy(() => import("./SensorMap"))

const useFiltersPopoverStyles = makeStyles((theme) => ({
  root: {
    width: 256,
    marginTop: theme.spacing(1),
  },
  paper: {
    width: "100%",
    maxWidth: "calc(100vw - 32px)",
  },
}))

const formatDays = (value) => {
  if (value.toString() === "1") {
    return "Last 24 hours"
  }
  if (value.toString().includes("-to-")) {
    const yesterday = moment().add(-1, "day")
    if (value === `${yesterday.format("YYYY-MM-DD")}-to-${yesterday.format("YYYY-MM-DD")}`) {
      return "Yesterday"
    }
    const values = value.split("-to-")
    return (
      <>
        <FormatDateCompact value={values[0]} shortMonthName={false} />
        {" - "}
        <FormatDateCompact value={values[1]} shortMonthName={false} />
      </>
    )
  }
  return `Last ${value} days`
}

const FiltersButton = ({ filters, onFilterChange, onReset, disabled }) => {
  const classes = useFiltersPopoverStyles()

  const handleFilterChange = (type, event) => {
    if (onFilterChange) onFilterChange(type, event)
  }

  const handleReset = (event) => {
    onReset && onReset(event)
  }

  return (
    <PopupState variant="popover" popupId="bulk-actions">
      {(popupState) => (
        <>
          <Button
            variant="contained"
            color="primary"
            {...bindTrigger(popupState)}
            ref={anchorRef(popupState)}
            disabled={disabled}
          >
            <FilterList />
            <Box ml={1}>
              <Trans>Filters</Trans>
            </Box>
          </Button>
          <Popover
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            classes={classes}
          >
            <ColumnBox p={2}>
              <DateRangeOutlinedSelect
                label="Date range"
                value={filters.days}
                onChange={(event) => handleFilterChange("days", event)}
                todayLabel="Last 24 hours"
              />
              <Box mt={2} ml="auto">
                <Button variant="contained" onClick={handleReset}>
                  <Trans>Reset</Trans>
                </Button>
              </Box>
            </ColumnBox>
          </Popover>
        </>
      )}
    </PopupState>
  )
}

const Sensor = () => {
  const classes = useStyles()
  const { id, days } = useParams()
  const { height } = useWindowSize()
  const config = Config.get("sensors")
  const history = useHistory()
  const [loadSensor, { data, loading: sensorLoading }] = useLazyQuerySensor()
  const [sensor, setSensor] = useState({})
  const [chartHeight, setChartHeight] = useState(null)

  useEffect(() => {
    let gt
    let lt
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      gt = moment(daysParts[0])
      lt = moment(daysParts[1]).endOf("day")
    } else {
      gt = moment().subtract(days, "days")
    }

    const options = {
      variables: { id, gt, lt },
    }

    loadSensor({ ...options })
  }, [days, id, loadSensor])

  useEffect(() => {
    if (data && data.sensor.id !== sensor.id) setSensor({ ...data.sensor })
  }, [data, sensor.id])

  const chartData = useMemo(() => {
    if (!data) {
      return null
    }

    return data.sensor.samples.map(({ value, at, type, displayUnit }) =>
      type === HUMIDITY ? { humidity: value, at } : { temperature: convertCelsius(value, displayUnit), at }
    )
  }, [data])

  useEffect(() => {
    if (!chartHeight && height) {
      setChartHeight(height)
    }
  }, [chartHeight, height])

  const handleFilterChange = (type, event) => {
    history.push(`/sensors/${id}/${event.target.value}`)
  }

  const handleReset = () => {
    history.push(`/sensors/${id}/1`)
  }

  const hasSensor = Boolean(sensor.id)
  const hasBattery = !sentryIgnoreBatteryProducts.includes(sensor.product)
  const atWarning = hasSensor ? moment().diff(moment(sensor.sample.at), "minutes") > config.at.warnAgoMaxMin : false
  const batteryWarning = sensor.sample?.battery < config.battery.warnLowPercent && hasBattery
  const hasWarning = atWarning || batteryWarning

  const temperature = sensor.sample
  const humidity = sensor.samples ? sensor.samples.find((sample) => sample.type === HUMIDITY) : null

  const loading = sensorLoading || (data?.sensor.samples.length > 0 && !chartData.length)

  const title = sensor.name || (loading ? <Trans>Loading...</Trans> : <Trans>Error</Trans>)

  return (
    <Grid container spacing={3}>
      <Hidden smDown>
        <Grid item sm={5} md={4}>
          <SensorList />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={8}>
        <Box className={classes.detail}>
          <RowBox mb={3}>
            <MuiAvatar aria-label={sensor.name || "Loading..."} className={classes.avatar}>
              <Icon name="sensors-temperature" className={classes.sensorIcon} />
            </MuiAvatar>
            <ColumnBox ml={1.5}>
              <Typography variant="h3" className={classes.title}>
                {title}
              </Typography>
              <RowBox className={classes.subtitle}>
                {sensor.product}
                {sensor.product ? " - #" : "#"}
                {sensor.serial}
              </RowBox>
            </ColumnBox>
          </RowBox>

          <Box mb={3}>
            <Breadcrumbs>
              <NavLink to="/sensors">All sensors</NavLink>
              <Box>{title}</Box>
            </Breadcrumbs>
          </Box>

          <PaperBox p={2} paperProps={{ className: hasWarning ? classes.paperWarning : "" }}>
            {loading && (
              <FlexBox justifyContent="center">
                <LoadingSpinner size={60} />
              </FlexBox>
            )}
            {!loading && chartData && (
              <>
                <RowBox mb={1} alignItems="flex-start">
                  <NumberDisplay
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    value={
                      <RowBox>
                        {temperature ? convertCelsius(temperature.value, temperature.displayUnit).toFixed(1) : "--"}
                        <small className={classes.scale}>
                          {temperature?.displayUnit === "f" ? "\u2109" : "\u2103"}
                        </small>
                        {Boolean(humidity) && (
                          <RowBox ml={1.5} className={classes.humidity}>
                            {humidity.value}
                            <small className={classes.scale}>%RH</small>
                          </RowBox>
                        )}
                      </RowBox>
                    }
                    subject={
                      <RowBox ml={0.5}>
                        <Badge variant="dot" color={atWarning ? "error" : "primary"} overlap="circular" />
                        <Box ml={1.25} className={`${classes.at} ${atWarning ? classes.warning : ""}`}>
                          <small>{temperature ? <TimeAgo date={temperature.at} /> : <Trans>Loading...</Trans>}</small>
                        </Box>
                      </RowBox>
                    }
                  />

                  {temperature && (
                    <>
                      <SensorIndicator
                        value={temperature.signal}
                        iconBase="signal"
                        iconStep={10}
                        ml="auto"
                        justifyContent="center"
                        className={classes.indicators}
                      />
                      {hasBattery && (
                        <SensorIndicator
                          value={temperature.battery}
                          iconBase="battery"
                          iconStep={5}
                          ml={1}
                          justifyContent="center"
                          className={`${classes.indicators} ${batteryWarning ? classes.warning : ""}`}
                        />
                      )}
                    </>
                  )}
                </RowBox>

                <Divider />

                <Box mt={2}>
                  <RowBox mb={2}>
                    <Typography variant="h3" className={classes.days}>
                      {formatDays(days)}
                    </Typography>
                    {false && (
                      <Box ml="auto">
                        <RowBox>
                          <FiltersButton
                            onFilterChange={handleFilterChange}
                            onReset={handleReset}
                            filters={{ days }}
                            disabled={loading}
                          />
                        </RowBox>
                      </Box>
                    )}
                  </RowBox>
                  <Box mr={0.5}>
                    {Boolean(chartHeight) && Boolean(chartData?.length) && (
                      <SensorSampleChart
                        data={chartData}
                        height={chartHeight / 2}
                        temperatureUnit={temperature?.displayUnit || "c"}
                      />
                    )}
                  </Box>
                </Box>

                {Boolean(temperature?.lbs?.length) && (
                  <Suspense
                    fallback={
                      <FlexBox justifyContent="center">
                        <LoadingSpinner size={60} />
                      </FlexBox>
                    }
                  >
                    <Divider />
                    <Box mt={2}>
                      <ErrorBoundary>
                        <Typography variant="h3" className={classes.days}>
                          Last location
                        </Typography>
                        <SensorMap lbs={temperature.lbs} />
                      </ErrorBoundary>
                    </Box>
                  </Suspense>
                )}
              </>
            )}
            {!loading && !chartData && (
              <NoItemsMessage>
                <Trans>Unable to retrieve sensor data, check integration</Trans>
              </NoItemsMessage>
            )}
          </PaperBox>
        </Box>
      </Grid>
    </Grid>
  )
}

export default Sensor

import React from "react"
import { makeStyles } from "@material-ui/styles"
import { List, Hidden, Drawer, Box } from "@material-ui/core"
import { useParams } from "react-router-dom"
import Config from "react-global-configuration"
import { Trans, t } from "@lingui/macro"
import { SubNavigation, SubNavigationListItem, DrawerHeading } from "../../components"
import { useQueryTemplateCategories, useQueryProcessSetCounts } from "../../data"
import { toId } from "../../utils"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  filterButton: {
    paddingTop: ({ isInline }) => (isInline ? 6 : "inherit"),
    paddingBottom: ({ isInline }) => (isInline ? 6 : "inherit"),
  },
  subNav: {
    paddingTop: 0,
    borderRadius: "0 0 8px 8px",
    "&&:first-of-type": {
      marginBottom: 0,
      borderRadius: "8px 8px 0 0",
      paddingBottom: 0,
    },
  },
  subNavTitle: {
    paddingTop: 0,
  },
  subNavList: {
    maxWidth: 240 - theme.spacing(1),
  },
  featuredIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const TemplatesSubNav = ({ open, onClose }) => {
  const classes = useStyles()
  const { hasFeature } = useAuth()
  const { data: templateCategoriesData, loading: loadingCategories } = useQueryTemplateCategories()
  const { data: processSetCountsData, loading: loadingProcessCounts } = useQueryProcessSetCounts()
  const { set, category } = useParams()
  const { featuredCategory: featuredId } = Config.get("templates")

  const featuredCategory =
    templateCategoriesData && templateCategoriesData.templateCategories.find(({ id }) => id === featuredId)

  const counts = processSetCountsData?.processes

  const handleClick = () => {
    onClose && onClose()
  }

  const myActiveNav = (
    <List className={classes.subNavList}>
      <SubNavigationListItem
        to="/templates/active"
        text={<Trans>Any</Trans>}
        active={set === "active"}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/templates/active-recurring"
        text={t`Recurring`}
        badgeCount={counts?.activeRecurring || 0}
        badgeVariant="text"
        active={set === "active-recurring"}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/templates/active-oneoff"
        text={t`One-off`}
        badgeCount={counts?.activeOneoff || 0}
        badgeVariant="text"
        active={set === "active-oneoff"}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/templates/active-adhoc"
        text={t`Ad hoc`}
        badgeCount={counts?.activeAdhoc || 0}
        badgeVariant="text"
        active={set === "active-adhoc"}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/templates/active-unscheduled"
        text={t`Unscheduled`}
        badgeCount={counts?.activeUnscheduled || 0}
        badgeVariant="text"
        active={set === "active-unscheduled"}
        onClick={handleClick}
      />
    </List>
  )

  const myInactiveNav = (
    <List className={classes.subNavList}>
      <SubNavigationListItem
        to="/templates/inactive"
        text={<Trans>Any</Trans>}
        active={set === "inactive"}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/templates/inactive-recurring"
        text={t`Recurring`}
        badgeCount={counts?.inactiveRecurring || 0}
        badgeVariant="text"
        active={set === "inactive-recurring"}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/templates/inactive-oneoff"
        text={t`One-off`}
        badgeCount={counts?.inactiveOneoff || 0}
        badgeVariant="text"
        active={set === "inactive-oneoff"}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/templates/inactive-adhoc"
        text={t`Ad hoc`}
        badgeCount={counts?.inactiveAdhoc || 0}
        badgeVariant="text"
        active={set === "inactive-adhoc"}
        onClick={handleClick}
      />
      <SubNavigationListItem
        to="/templates/inactive-unscheduled"
        text={t`Unscheduled`}
        badgeCount={counts?.inactiveUnscheduled || 0}
        badgeVariant="text"
        active={set === "inactive-unscheduled"}
        onClick={handleClick}
      />
    </List>
  )

  const libraryNav = templateCategoriesData && (
    <List className={classes.subNavList}>
      {featuredCategory && (
        <SubNavigationListItem
          to={`/templates/library/${featuredCategory.id}`}
          text={featuredCategory.name}
          rightIcon={{ name: "featured-star", className: classes.featuredIcon }}
          badgeCount={featuredCategory.items.count}
          badgeVariant="text"
          active={category === featuredCategory.id}
          onClick={handleClick}
        />
      )}
      {templateCategoriesData.templateCategories
        .filter((item) => toId(item) !== toId(featuredCategory, true) && item.items.count)
        .map(({ id, name, items: { count } }) => (
          <SubNavigationListItem
            key={id}
            to={`/templates/library/${id}`}
            text={name}
            badgeCount={count}
            badgeVariant="text"
            active={category === id}
            onClick={handleClick}
          />
        ))}
    </List>
  )

  const hasTemplateLibrary = hasFeature("process_library")

  return (
    <>
      <Hidden lgUp>
        <Drawer open={open} onClose={onClose}>
          <Box mt={2}>
            <DrawerHeading>
              <Trans>My active jobs</Trans>
            </DrawerHeading>
            {myActiveNav}
            <DrawerHeading>
              <Trans>My inactive jobs</Trans>
            </DrawerHeading>
            {myInactiveNav}
            {hasTemplateLibrary && (
              <>
                <DrawerHeading>
                  <Trans>Template library</Trans>
                </DrawerHeading>
                {libraryNav}
              </>
            )}
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <SubNavigation title={<Trans>My active jobs</Trans>} loading={loadingProcessCounts} className={classes.subNav}>
          {myActiveNav}
        </SubNavigation>
        <SubNavigation
          title={<Trans>My inactive jobs</Trans>}
          loading={loadingProcessCounts}
          className={classes.subNav}
          titleClassName={classes.subNavTitle}
        >
          {myInactiveNav}
        </SubNavigation>
        {hasTemplateLibrary && (
          <SubNavigation title={<Trans>Template library</Trans>} loading={loadingCategories}>
            {libraryNav}
          </SubNavigation>
        )}
      </Hidden>
    </>
  )
}

export { TemplatesSubNav }

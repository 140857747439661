import React, { Suspense } from "react"
import { useHistory } from "react-router-dom"
import { Hidden } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { AreaHeader, Content } from "../../components"
import { DesktopCreateAssetsButton, MobileCreateAssetsButton } from "../../components/Assets/CreateAssetsButton"
import { ItemCountHeader } from "../../components/AreaHeader/ItemCountHeader"
import { useQueryAssetsCount } from "../../data"
import PageLoadingSpinner from "../../components/LoadingSpinner/PageLoadingSpinner"

const Assets = React.lazy(() => import("./Assets"))

const AssetsArea = () => {
  const history = useHistory()
  const { data } = useQueryAssetsCount()

  const handleCreate = () => {
    history.push("/assets/new")
  }

  return (
    <>
      <AreaHeader
        title={<Trans>Assets</Trans>}
        titleIcon="assets-light"
        desktopButtons={<DesktopCreateAssetsButton onCreate={handleCreate} />}
        mobileButtons={<MobileCreateAssetsButton onCreate={handleCreate} />}
        beta
      />

      <Content full start>
        <Hidden smDown>
          <ItemCountHeader loading={!data} count={data?.assets.count} subject="assets" />
        </Hidden>

        <Suspense fallback={<PageLoadingSpinner />}>
          <Assets />
        </Suspense>
      </Content>
    </>
  )
}

export default AssetsArea

import React, { useState } from "react"
import { Grid, useMediaQuery, Drawer, Hidden, Box } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import { Switch, Route, useParams, Redirect, useHistory, useLocation } from "react-router-dom"
import { Trans } from "@lingui/macro"
import PostCreator from "../../components/PostCreator"
import {
  Content,
  AreaHeader,
  SubNavigation,
  HelpBanner,
  DesktopCreateCommsButton,
  MobileCreateCommsButton,
  ActionCreator,
  DrawerHeading,
  BackToTop,
  RowBox,
} from "../../components"
import { useAuth } from "../../services"
import { Actions, ActionsSubNav } from "./Actions"
import { SingleAction } from "./SingleAction"
import { Posts, PostsSubNav } from "./Posts"
import { SinglePost } from "./SinglePost"
import { HubFiltersButton } from "../../components/Hub/HubFiltersButton"
import { InlineHubFilters } from "../../components/Hub/InlineHubFilters"

const useStyles = makeStyles((theme) => ({
  drawer: {
    minWidth: "60vw",
  },
  subNav: {
    paddingTop: 0,
    borderRadius: "0 0 8px 8px",
    "&&:first-of-type": {
      marginBottom: 0,
      borderRadius: "8px 8px 0 0",
      paddingBottom: 0,
    },
  },
  subNavItem: {
    fontSize: "16px",
    fontWeight: "600",
    color: theme.palette.primary.main,
  },
  subNavItemUnfconfirmed: {
    color: theme.palette.error.main,
  },
  subNavItemScheduled: {},
}))

const useGridItemStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}))

const HubRoutes = ({ onMenuClick }) => (
  <Switch>
    <Route exact path="/hub/actions/:set" component={() => <Actions onMenuClick={onMenuClick} />} />
    <Route exact path="/hub/action/:id" component={() => <SingleAction onMenuClick={onMenuClick} />} />
    <Route exact path="/hub/action/:id/:action" component={() => <SingleAction onMenuClick={onMenuClick} />} />
    <Route exact path="/hub/:set" component={() => <Posts onMenuClick={onMenuClick} />} />
    <Route exact path="/hub/post/:id" component={() => <SinglePost onMenuClick={onMenuClick} />} />
    <Route exact path="/hub/post/:id/:action" component={() => <SinglePost onMenuClick={onMenuClick} />} />
    <Route exact path="/hub/:set/:id" component={() => <Posts onMenuClick={onMenuClick} />} />
    <Route exact path="/hub/:set/:id/:action" component={() => <Posts onMenuClick={onMenuClick} />} />
    <Redirect to="/hub/all" />
  </Switch>
)

const Index = () => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const gridItemClasses = useGridItemStyles()
  const history = useHistory()
  const location = useLocation()

  const [openSubNav, setOpenSubNav] = useState(false)
  const { set, id, action } = useParams()

  const isSingle = set === "post"
  const baseUri = `/hub/${set}`

  const isActions = location.pathname.startsWith("/hub/action")
  const openNewPost = !isActions && id === "new"
  const openNewAction = isActions && id === "new"
  const isEdit = action === "edit"

  const handleCreateClick = (type) => {
    if (type === "action") {
      history.push("/hub/actions/unresolved/new")
    } else {
      history.push("/hub/all/new")
    }
  }

  const handleCreatePostClose = () => {
    history.push(isSingle ? `${baseUri}/${id}` : baseUri)
  }

  const handleCreateActionClose = () => {
    history.push(`/hub/actions/${set}`)
  }

  const handleToggleSubNav = () => {
    setOpenSubNav(!openSubNav)
  }

  return (
    <>
      {openNewPost && <PostCreator open={openNewPost} onClose={handleCreatePostClose} isInline={xs} />}

      {openNewAction && <ActionCreator open={openNewAction} onClose={handleCreateActionClose} isInline={xs} />}

      {(!xs || (xs && !openNewPost && !openNewAction && !isEdit)) && (
        <>
          <AreaHeader
            title={<Trans>Communications Hub</Trans>}
            titleIcon="hub-light"
            desktopButtons={
              <RowBox>
                <DesktopCreateCommsButton onCreateClick={handleCreateClick} />

                <Hidden smDown>
                  <Box ml={1}>
                    <HubFiltersButton />
                  </Box>
                </Hidden>
              </RowBox>
            }
            mobileButtons={
              <MobileCreateCommsButton creatorOpen={openNewPost || openNewAction} onCreateClick={handleCreateClick} />
            }
          />

          <Content full start>
            <HelpBanner name="comms" />

            <Grid container direction="row">
              <Grid item sm={12} md={3} classes={gridItemClasses}>
                <HubSubNav open={openSubNav} onClose={handleToggleSubNav} />
                <BackToTop from={300} />
              </Grid>
              <Grid item sm={12} md={9} classes={gridItemClasses}>
                <HubRoutes onMenuClick={handleToggleSubNav} />
              </Grid>
            </Grid>
          </Content>
        </>
      )}

      {xs && isEdit && <HubRoutes />}
    </>
  )
}

const HubSubNav = ({ open, onClose }) => {
  const classes = useStyles()
  const { hasFeature } = useAuth()

  const handleClose = () => {
    onClose && onClose()
  }

  const handleClick = () => {
    onClose && onClose()
  }

  const hasActions = hasFeature("actions")

  return (
    <>
      <Hidden mdUp>
        <Drawer open={open} onClose={handleClose} classes={{ paper: classes.drawer }}>
          <Box mt={2}>
            <InlineHubFilters />
            {hasActions && (
              <DrawerHeading>
                <Trans>Posts</Trans>
              </DrawerHeading>
            )}
            <PostsSubNav onClick={handleClick} />
            {hasActions && (
              <DrawerHeading>
                <Trans>Actions</Trans>
              </DrawerHeading>
            )}
            <ActionsSubNav onClick={handleClick} />
          </Box>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <SubNavigation title={hasActions ? <Trans>Posts</Trans> : null} className={classes.subNav}>
          <PostsSubNav onClick={handleClick} />
        </SubNavigation>
        {hasActions && (
          <SubNavigation title={<Trans>Actions</Trans>} className={classes.subNav}>
            <ActionsSubNav onClick={handleClick} />
          </SubNavigation>
        )}
      </Hidden>
    </>
  )
}

export default Index

import { useMemo, useState } from "react"
import { TableCell, TableRow, makeStyles } from "@material-ui/core"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { toId, useWindowSize } from "../../utils"
import { FlexBox } from "../Boxes"
import { PersonTrainingCourseStatus } from "../Training/PersonTrainingCourseStatus"
import { NoItemsMessage } from "../Lists"
import { UserTableRowCell } from "../UserPanel/UserTableRowCell"
import { TableVirtualizer } from "../Lists/TableVirtualizer"
import { ErrorBoundary } from "../ErrorBoundary"
import { PersonViewer } from "../PersonViewer"
import { useQueryUserTrainingsCourse } from "../../data"
import { PersonTrainingViewer } from "../PersonViewer/PersonTrainingViewer"

const useStyles = makeStyles(() => ({
  tableCellSticky: {
    position: "sticky",
    left: 0,
    backgroundColor: "white",
    zIndex: 999,
  },
  status: {
    maxWidth: 80,
    cursor: "pointer",
  },
}))

const UserTrainingCoursesDataTable = ({ data, fullscreen, fetchMore, filter }) => {
  const classes = useStyles({ fullscreen })
  const { height: windowHeight } = useWindowSize()
  const [viewPerson, setViewPerson] = useState(null)
  const [viewPersonTraining, setViewPersonTraining] = useState()
  const { data: userTrainingsCourseData, loading: userTrainingsCourseLoading } = useQueryUserTrainingsCourse({
    variables: { user: viewPersonTraining?.user, course: viewPersonTraining?.course },
    skip: !viewPersonTraining,
  })

  const { list: users, courses } = data || {}

  const handleViewPerson = (user) => {
    setViewPerson(toId(user))
  }
  const handleClosePersonViewer = () => {
    setViewPerson(null)
  }

  const handleViewPersonTraining = (user, course) => {
    if (!course) {
      return
    }
    setViewPersonTraining({ user: toId(user), course: toId(course) })
  }

  const handleClosePersonTrainingViewer = () => {
    setViewPersonTraining(null)
  }

  const renderTableCell = (item, user) => {
    return (
      <ErrorBoundary>
        <TableCell
          style={{ width: 150, background: "white", position: "sticky", left: 0, zIndex: 1 }}
          key={`${item.key}-name`}
        >
          <UserTableRowCell user={user} onClick={() => handleViewPerson(user)} />
        </TableCell>
        {courses.map((course) => {
          const userCourse = [...user.training.courses]
            .sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt)) ? -1 : 1))
            .find((userTraining) => toId(userTraining.course) === toId(course))

          return (
            <TableCell align="center" key={`${item.key}-${toId(course)}`}>
              <FlexBox justifyContent="center" flexGrow={1}>
                <FlexBox
                  className={classes.status}
                  justifySelf="center"
                  onClick={() => {
                    handleViewPersonTraining(user, userCourse?.course)
                  }}
                >
                  <PersonTrainingCourseStatus course={userCourse} />
                </FlexBox>
              </FlexBox>
            </TableCell>
          )
        })}
      </ErrorBoundary>
    )
  }

  const tableHeight = useMemo(() => (windowHeight || 1280) - (fullscreen ? 150 : 489), [fullscreen, windowHeight])

  const handleLoadMore = async () => {
    await fetchMore({
      variables: {
        filter,
        offset: users.length,
        limit: 10,
      },
    })
  }

  if (!data) {
    return null
  }

  return (
    <>
      {Boolean(viewPerson) && (
        <PersonViewer id={toId(viewPerson)} active="training" onClose={handleClosePersonViewer} disabledDialogActions />
      )}

      {Boolean(viewPersonTraining) && (
        <PersonTrainingViewer
          userTraining={userTrainingsCourseData?.userTrainingByCourse}
          onClose={handleClosePersonTrainingViewer}
          loading={userTrainingsCourseLoading}
        />
      )}

      <TableVirtualizer
        items={users}
        endReached={handleLoadMore}
        style={{ height: tableHeight }}
        fixedHeaderContent={() => (
          <TableRow>
            <TableCell style={{ width: 150, background: "white", position: "sticky", left: 0, zIndex: 2 }}>
              Name
            </TableCell>
            {courses.map((course) => (
              <TableCell key={toId(course)} align="center" style={{ whiteSpace: "nowrap" }}>
                {course.name}
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(item, user) => {
          return <ErrorBoundary key={toId(user)}>{renderTableCell(item, user)}</ErrorBoundary>
        }}
      />
      {users && !users.length && (
        <NoItemsMessage>
          <Trans>No matching items</Trans>
        </NoItemsMessage>
      )}
    </>
  )
}

export { UserTrainingCoursesDataTable }

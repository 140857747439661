import React, { useEffect, useMemo, useState } from "react"
import { Box, makeStyles, Grid, Button } from "@material-ui/core"
import { useHistory, useParams } from "react-router"
import { Trans } from "@lingui/macro"
import {
  RowBox,
  GroupOutlinedSelect,
  ReportSectionActionCard,
  LocationOutlinedSelect,
  CircularTrainingCompletionChart,
} from "../../components"
import { useQueryGroups, useLazyQueryReportLocationsTraining } from "../../data"
import { mapToIds, useDateUtils, useReportUtils } from "../../utils"
import { useAuth } from "../../services"
import { TrainingUserTableReport } from "../../components/Reports/TrainingUserTableReport"
import ReportToolsIconButton from "../../components/Buttons/ReportToolsIconButton"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
  },
  reports: {
    width: "auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
}))

const sortData = (sort, a, b) => (sort === "alpha" ? 0 : a.percentComplete > b.percentComplete ? -1 : 1)

const Training = () => {
  const classes = useStyles()
  const { days, locations, groups } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToLink, filterChange } = useReportUtils()
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data: groupsData } = useQueryGroups()
  const [loadReportLocationsTraining, { data: locationsTrainingReportData, loading: locationsTrainingLoading }] =
    useLazyQueryReportLocationsTraining()
  const [sort, setSort] = useState("alpha")

  useEffect(() => {
    if (!groupsData) return
    const filterLocations = locations === "all" ? mapToIds(userLocations) : locations.split("-")
    const filterGroups = groups === "all" ? null : groups.split("-")

    const options = {
      variables: { locations: filterLocations, groups: filterGroups },
    }

    loadReportLocationsTraining({ ...options })
  }, [days, groups, groupsData, loadReportLocationsTraining, locations, momentToScheduleDate, userLocations])

  const groupIds = useMemo(() => groups?.split("-") || ["all"], [groups])
  const locationIds = useMemo(() => locations?.split("-") || ["all"], [locations])

  const handleLocationsChange = (event) => {
    history.push(getNavigateToLink("training", days, filterChange(locationIds, event), groupIds))
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToLink("training", days, mapToIds(regionLocations), groupIds))
  }

  const handleGroupsChange = (event) => {
    history.push(getNavigateToLink("training", days, locationIds, filterChange(groupIds, event)))
  }

  // const handleLocationClick = ({ id }) => {
  //   history.push(getNavigateToLink("training", days, [id], groupIds))
  // }

  // const handleGroupClick = ({ id }) => {
  //   history.push(getNavigateToLink("training", days, locationIds, [id]))
  // }

  const handleReset = () => {
    history.push(getNavigateToLink("training", 7, ["all"], ["all"], null))
  }

  const handleSort = () => {
    setSort(sort === "alpha" ? "rank" : "alpha")
  }

  // const timeZoneTrainingReportData = useMemo(
  //   () =>
  //     locationsTrainingReportData
  //       ? locationsTrainingReportData.reportLocationsTraining
  //           .reduce(
  //             (
  //               acc,
  //               {
  //                 timeZone,
  //                 percentComplete,
  //                 percentInProgress,
  //                 percentOverdue,
  //                 complete,
  //                 inProgress,
  //                 overdue,
  //                 userCount,
  //               }
  //             ) => {
  //               let zone = acc.find((item) => item.timeZone === timeZone)

  //               if (zone) {
  //                 zone.percentComplete += percentComplete
  //                 zone.percentInProgress += percentInProgress
  //                 zone.percentOverdue += percentOverdue
  //                 zone.complete += complete
  //                 zone.inProgress += inProgress
  //                 zone.overdue += overdue
  //                 zone.userCount += userCount

  //                 return acc
  //               }

  //               zone = {
  //                 timeZone,
  //                 percentComplete,
  //                 percentInProgress,
  //                 percentOverdue,
  //                 complete,
  //                 inProgress,
  //                 overdue,
  //                 userCount,
  //               }
  //               acc.push(zone)

  //               return acc
  //             },
  //             []
  //           )
  //           .map(({ percentComplete, percentInProgress, percentOverdue, ...rest }) => {
  //             const total = percentComplete + percentInProgress + percentOverdue
  //             return {
  //               percentComplete: (percentComplete / total) * 100,
  //               percentInProgress: (percentInProgress / total) * 100,
  //               percentOverdue: (percentOverdue / total) * 100,
  //               ...rest,
  //             }
  //           })
  //           .sort(({ timeZone: a }, { timeZone: b }) => a.localeCompare(b))
  //       : [],
  //   [locationsTrainingReportData]
  // )

  const overallTrainingReportData = useMemo(() => {
    if (locationsTrainingReportData) {
      const result = locationsTrainingReportData.reportLocationsTraining.reduce(
        (acc, { percentComplete, percentInProgress, percentOverdue, complete, inProgress, overdue, userCount }) => {
          acc.percentComplete += percentComplete
          acc.percentInProgress += percentInProgress
          acc.percentOverdue += percentOverdue
          acc.complete += complete
          acc.inProgress += inProgress
          acc.overdue += overdue
          acc.userCount += userCount

          return acc
        },
        {
          percentComplete: 0,
          percentInProgress: 0,
          percentOverdue: 0,
          complete: 0,
          inProgress: 0,
          overdue: 0,
          userCount: 0,
        },
      )

      const total = result.percentComplete + result.percentInProgress + result.percentOverdue

      return {
        percentComplete: (result.percentComplete / total) * 100,
        percentInProgress: (result.percentInProgress / total) * 100,
        percentOverdue: (result.percentOverdue / total) * 100,
        complete: result.complete,
        inProgress: result.inProgress,
        overdue: result.overdue,
        userCount: result.userCount,
      }
    }

    return null
  }, [locationsTrainingReportData])

  const hasGroups = !!groupsData?.groups.length

  return (
    <RowBox alignItems="flex-start">
      <Box p={3} className={classes.filter} mt={3} mb={3}>
        <Box mb={2}>
          <LocationOutlinedSelect
            value={locationIds}
            className={classes.filterSelect}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        {hasGroups && (
          <Box mt={2}>
            <GroupOutlinedSelect
              value={groupIds}
              className={classes.filterSelect}
              onChange={handleGroupsChange}
              multiple
            />
          </Box>
        )}
        <RowBox mt={2} justifyContent="flex-end">
          <Button variant="contained" onClick={handleReset}>
            <Trans>Reset</Trans>
          </Button>
        </RowBox>
      </Box>
      <Box flexGrow={1}>
        <Grid container spacing={1} className={classes.reports}>
          <Grid item xs={3}>
            <ReportSectionActionCard title="Overall" detail="% people per status" loading={locationsTrainingLoading}>
              <RowBox flexWrap="wrap" alignItems="flex-start" justifyContent="center">
                {!locationsTrainingLoading && overallTrainingReportData && (
                  <CircularTrainingCompletionChart
                    text={
                      locations === "all"
                        ? "All locations"
                        : `${locationIds.length} selected location${locationIds.length > 1 ? "s" : ""}`
                    }
                    data={overallTrainingReportData}
                  />
                )}
              </RowBox>
            </ReportSectionActionCard>
          </Grid>
          {/* <Grid item xs={9}>
            <ReportSectionActionCard title="Time Zones" detail="% people per status" loading={locationsTrainingLoading}>
              <RowBox flexWrap="wrap" alignItems="flex-start" justifyContent="center">
                {!locationsTrainingLoading &&
                  timeZoneTrainingReportData?.map(({ timeZone, ...timeZoneTrainingData }) => (
                    <CircularTrainingCompletionChart
                      key={timeZone}
                      text={timeZone.replace("/", " / ").replace("_", " ")}
                      data={timeZoneTrainingData}
                    />
                  ))}
              </RowBox>
            </ReportSectionActionCard>
          </Grid> */}
          <Grid item xs={9}>
            <ReportSectionActionCard
              title="Locations"
              detail="% people per status"
              loading={locationsTrainingLoading}
              tools={
                <RowBox>
                  <ReportToolsIconButton
                    title={sort === "alpha" ? "Sort by completions %" : "Sort alphabetically"}
                    icon={sort === "alpha" ? "rank" : "sort-by-alpha"}
                    onClick={handleSort}
                    disabled={locationsTrainingLoading}
                  />
                </RowBox>
              }
            >
              <RowBox flexWrap="wrap" alignItems="flex-start" justifyContent="center">
                {!locationsTrainingLoading &&
                  [...(locationsTrainingReportData?.reportLocationsTraining || [])]
                    .sort((a, b) => sortData(sort, a, b))
                    .map(({ id, name, ...locationTrainingData }) => (
                      <CircularTrainingCompletionChart key={id} text={name} data={locationTrainingData} showCount />
                    ))}
              </RowBox>
            </ReportSectionActionCard>
          </Grid>
          <Grid item xs={12}>
            <TrainingUserTableReport locations={locationIds} groups={groupIds.filter((groupId) => groupId !== "all")} />
          </Grid>
        </Grid>
      </Box>
    </RowBox>
  )
}

export default Training

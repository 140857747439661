import React, { useEffect, useMemo, useState } from "react"
import { Box, MenuItem, TextField } from "@material-ui/core"
import { t } from "@lingui/macro"
import { mapToIds, multipleSelectChange, toId, useFormUtils, removeTypename } from "../../utils"
import { CreatorActions } from "../Creators"
import {
  LocationOutlinedSelect,
  OutlinedSelect,
  TemplateOutlinedSelect,
  TrainingModulesOutlinedSelect,
} from "../OutlinedSelect"
import { EVENT_NAME, TRIGGER_TARGET_TYPE } from "../../data"
import { UsersGroupsAssigner } from "../Assigners"
import { useAuth } from "../../services"
import { TextDivider } from "../TextDivider"
import { formatEventLabel } from "../../utils/events"
import {
  useMutationCreateEventTrigger,
  useMutationUpdateEventTrigger,
} from "../../data/triggers/useMutationEventTrigger"
import TriggerTargetTypeOutlinedSelect from "./TriggerTargetTypeOutlinedSelect"
import TagsTextField from "../TextField/TagsTextField"
import TriggerNotifyMethodOutlinedSelect from "./TriggerNotifyMethodOutlinedSelect"
import { TrainingCoursesOutlinedSelect } from "../OutlinedSelect/TrainingCoursesOutlinedSelect"
import { ActionPriorityOutlinedSelect } from "../OutlinedSelect/ActionPriorityOutlinedSelect"

const { NOTIFY_USERS, NOTIFY_EMAILS, NOTIFY_AUTHOR, INTEGRATION } = TRIGGER_TARGET_TYPE

const initial = {
  name: "",
  eventSource: {
    events: [],
    locations: [],
    process: "",
    processStep: "",
    trainingCourses: [],
    actionPriorities: [],
    trainingModules: [],
  },
  targetType: "",
  notifyUsersTarget: {
    methods: [],
    users: [],
    groups: [],
  },
  notifyEmailsTarget: {
    emails: [],
  },
  notifyAuthorTarget: {
    methods: [],
  },
  integrationTarget: "",
}

const supportedEvents = [
  EVENT_NAME.ACTION_CREATED,
  EVENT_NAME.ACTION_UPDATED,
  EVENT_NAME.ACTION_OVERDUE,
  EVENT_NAME.ACTION_COMMENT_CREATED,
  EVENT_NAME.JOB_CREATED,
  EVENT_NAME.JOB_COMPLETED,
  EVENT_NAME.JOB_SUBMITTED,
  EVENT_NAME.JOB_OVERDUE,
  EVENT_NAME.USER_TRAINING_COURSE_COMPLETED,
  EVENT_NAME.USER_TRAINING_MODULE_COMPLETED,
]

const EventSourceTrigger = ({ edit, onClose, cy }) => {
  const [createTrigger, { loading: createLoading }] = useMutationCreateEventTrigger()
  const [updateTrigger, { loading: updateLoading }] = useMutationUpdateEventTrigger()
  const {
    settings: { locations },
  } = useAuth()
  const { isValid, validate } = useFormUtils()
  const [name, setName] = useState(initial.name)
  const [eventSource, setEventSource] = useState(initial.eventSource)
  const [targetType, setTargetType] = useState("")
  const [notifyUsersTarget, setNotifyUsersTarget] = useState(initial.notifyUsersTarget)
  const [notifyEmailsTarget, setNotifyEmailsTarget] = useState(initial.notifyEmailsTarget)
  const [notifyAuthorTarget, setNotifyAuthorTarget] = useState(initial.notifyAuthorTarget)
  const [integrationTarget, setIntegrationTarget] = useState("")
  const [coursesLoaded, setCoursesLoaded] = useState(false)
  const [modulesLoaded, setModulesLoaded] = useState(false)

  const [initialPicked, setInitialPicked] = useState(null)

  useEffect(() => {
    if (edit) {
      setName(edit.name)
      setEventSource(() => ({
        ...removeTypename(edit.eventSource),
        locations: !edit.eventSource?.locations?.length ? ["all"] : mapToIds(edit.eventSource.locations),
        process: toId(edit.eventSource.process, true) || "all",
        processStep: toId(edit.eventSource.processStep, true),
        trainingCourses: !edit.eventSource?.trainingCourses?.length
          ? ["all"]
          : mapToIds(edit.eventSource.trainingCourses),
        actionPriorities: !edit.eventSource?.actionPriorities?.length ? ["all"] : edit.eventSource?.actionPriorities,
        trainingModules: !edit.eventSource?.trainingModules?.length
          ? ["all"]
          : mapToIds(edit.eventSource.trainingModules),
      }))
      setTargetType(edit.targetType)
      if (edit.targetType === NOTIFY_USERS) {
        setNotifyUsersTarget(() => ({
          ...removeTypename(edit.notifyUsersTarget),
          users: mapToIds(edit.notifyUsersTarget.users),
          groups: mapToIds(edit.notifyUsersTarget.groups),
        }))
      }
      if (edit.targetType === NOTIFY_EMAILS) {
        setNotifyEmailsTarget(removeTypename(edit.notifyEmailsTarget))
      }
      if (edit.targetType === NOTIFY_AUTHOR) {
        setNotifyAuthorTarget(removeTypename(edit.notifyAuthorTarget))
      }
      setIntegrationTarget(edit.integrationTarget)

      if (edit.targetType === NOTIFY_USERS) {
        setInitialPicked([...edit.notifyUsersTarget.users, ...edit.notifyUsersTarget.groups])
      } else {
        setInitialPicked([])
      }
    } else {
      setInitialPicked([])
    }
  }, [edit])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleCoursesChange = (event) => {
    setEventSource((prev) => ({
      ...prev,
      trainingCourses: [...multipleSelectChange(eventSource.trainingCourses, event)],
    }))
  }

  const handleCoursesLoaded = () => setCoursesLoaded(true)

  const handleModulesChange = (event) => {
    setEventSource((prev) => ({
      ...prev,
      trainingModules: [...multipleSelectChange(eventSource.trainingModules, event)],
    }))
  }
  const handleModulesLoaded = () => setModulesLoaded(true)

  const handleTargetTypeChange = (event) => {
    setTargetType(event.target.value)
  }

  const handleLocationsChange = (event) => {
    setEventSource((prev) => ({
      ...prev,
      locations: [...multipleSelectChange(eventSource.locations, event)],
    }))
  }

  const handleRegionChange = (newLocations) => {
    setEventSource((prev) => ({
      ...prev,
      locations: [...mapToIds(newLocations)],
    }))
  }

  const handleEventsChange = (event) => {
    setEventSource((prev) => ({
      ...prev,
      events: [...multipleSelectChange(eventSource.events, event)],
      trainingCourses: [],
    }))
    setTargetType("")
  }

  const handleTemplateChange = (event) => {
    setEventSource((prev) => ({
      ...prev,
      process: event.target.value,
    }))
  }

  const handleActionPriorityChange = (event) => {
    setEventSource((prev) => ({
      ...prev,
      actionPriorities: [...multipleSelectChange(eventSource.actionPriorities, event)],
    }))
  }

  const handleNotifyMethodsChange = (event) => {
    setNotifyUsersTarget((prev) => ({
      ...prev,
      methods: [...multipleSelectChange(notifyUsersTarget.methods, event)],
    }))
  }

  const handleNotifyAuthorMethodsChange = (event) => {
    setNotifyAuthorTarget((prev) => ({
      ...prev,
      methods: [...multipleSelectChange(notifyAuthorTarget.methods, event)],
    }))
  }

  const handleNotifyUsersPickedChange = (picked) => {
    const newHasEveryone = picked.find((p) => p.__typename === "Everyone")
    setNotifyUsersTarget((prev) => ({
      ...prev,
      users: newHasEveryone ? [] : [...mapToIds(picked.filter((p) => p.__typename === "User"))],
      groups: newHasEveryone ? [] : [...mapToIds(picked.filter((p) => p.__typename === "Group"))],
    }))
  }

  const handleEmailsChange = (event, options) => {
    setNotifyEmailsTarget((prev) => ({
      ...prev,
      emails: options,
    }))
  }

  const handleSubmit = async () => {
    const variables = {
      input: {
        name,
        eventSource: {
          ...eventSource,
          locations: eventSource.locations.includes("all") ? [] : eventSource.locations,
          process: eventSource.process === "all" || !eventSource.process ? null : toId(eventSource.process, true),
          processStep: toId(eventSource.processStep, true) || null,
          trainingCourses: eventSource.trainingCourses.includes("all")
            ? []
            : hasUserTrainingCourseCompleted
              ? eventSource.trainingCourses
              : [],
          actionPriorities: eventSource?.actionPriorities?.includes("all") ? [] : eventSource?.actionPriorities,
          trainingModules: eventSource.trainingModules.includes("all")
            ? []
            : hasUserTrainingModuleCompleted
              ? eventSource.trainingModules
              : [],
        },
        targetType,
      },
    }

    if (!eventSource.process) {
      delete variables.input.eventSource.process
    }
    if (!eventSource.processStep) {
      delete variables.input.eventSource.processStep
    }

    switch (targetType) {
      case NOTIFY_USERS:
        variables.input.notifyUsersTarget = { ...notifyUsersTarget }
        break
      case NOTIFY_EMAILS:
        variables.input.notifyEmailsTarget = { ...notifyEmailsTarget }
        break
      case NOTIFY_AUTHOR:
        variables.input.notifyAuthorTarget = { ...notifyAuthorTarget }
        break
      case INTEGRATION:
        variables.input.integrationTarget = { ...integrationTarget }
        break
      default:
        throw Error("No target type selected")
    }

    if (edit) {
      await updateTrigger({
        variables: {
          id: toId(edit),
          ...variables,
        },
      })
    } else {
      await createTrigger({
        variables,
      })
    }

    onClose && onClose()
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const formValid = useMemo(
    () =>
      Boolean(eventSource?.events?.length) &&
      Boolean(eventSource?.locations?.length) &&
      isValid(targetType) &&
      (targetType !== NOTIFY_USERS ||
        Boolean(
          notifyUsersTarget?.methods?.length && (notifyUsersTarget?.users?.length || notifyUsersTarget?.groups?.length),
        )) &&
      (targetType !== NOTIFY_EMAILS ||
        (notifyEmailsTarget?.emails?.length &&
          notifyEmailsTarget.emails.every((email) => validate.requiredEmail(email)))) &&
      (targetType !== NOTIFY_AUTHOR || Boolean(notifyAuthorTarget?.methods?.length)),
    [
      eventSource?.events?.length,
      eventSource?.locations?.length,
      isValid,
      notifyEmailsTarget.emails,
      notifyAuthorTarget?.methods?.length,
      notifyUsersTarget?.groups?.length,
      notifyUsersTarget?.methods?.length,
      notifyUsersTarget?.users?.length,
      targetType,
      validate,
    ],
  )

  const hasActionEvent = eventSource.events.some((item) => item.startsWith("action_"))
  const hasJobEvent = eventSource.events.some((item) => item.startsWith("job_"))
  // const hasJobStepEvent = eventSource.events.some((item) => item.startsWith("job_step_"))
  const hasNotification = targetType.startsWith("notify_")
  const hasUserTrainingCourseCompleted = eventSource.events?.includes(EVENT_NAME.USER_TRAINING_COURSE_COMPLETED)
  const hasUserTrainingModuleCompleted = eventSource.events?.includes(EVENT_NAME.USER_TRAINING_MODULE_COMPLETED)

  const loading = createLoading || updateLoading

  const disableSubmit =
    !formValid ||
    (hasUserTrainingCourseCompleted && !coursesLoaded) ||
    (hasUserTrainingModuleCompleted && !modulesLoaded)

  return (
    <>
      <Box mb={1}>
        <Box mb={2}>
          <TextField
            label={t`Trigger name`}
            variant="outlined"
            value={name}
            onChange={handleNameChange}
            fullWidth
            inputProps={{ "data-cy": "TextField-name" }}
          />
        </Box>

        <TextDivider mb={2} />

        <Box mb={2}>
          <OutlinedSelect
            value={eventSource.events}
            label={t`Which event(s) should this trigger respond to?`}
            native={false}
            onChange={handleEventsChange}
            multiple
            required
          >
            {Object.values(EVENT_NAME)
              .filter((item) => supportedEvents.includes(item))
              .map((value) => (
                <MenuItem key={value} value={value}>
                  {formatEventLabel(value)}
                </MenuItem>
              ))}
          </OutlinedSelect>
        </Box>

        {hasUserTrainingCourseCompleted && (
          <>
            <TextDivider mb={2} />

            <Box mb={2}>
              <TrainingCoursesOutlinedSelect
                label={t`Select training courses`}
                placeholder={t`For which training courses?`}
                value={eventSource?.trainingCourses || []}
                onChange={handleCoursesChange}
                onDataLoaded={handleCoursesLoaded}
                hasAll
                multiple
                required
              />
            </Box>
          </>
        )}

        {hasUserTrainingModuleCompleted && (
          <>
            <TextDivider mb={2} />

            <Box mb={2}>
              <TrainingModulesOutlinedSelect
                label={t`Select training modules`}
                placeholder={t`For which training modules?`}
                value={eventSource?.trainingModules || []}
                onChange={handleModulesChange}
                onDataLoaded={handleModulesLoaded}
                hasAll
                allLabel="All training modules"
                multiple
                required
              />
            </Box>
          </>
        )}

        {(hasJobEvent || hasActionEvent) && (
          <>
            <TextDivider mb={2} />

            <Box mb={2}>
              <TemplateOutlinedSelect
                label={t`Select job template`}
                hasAll
                value={toId(eventSource?.process, true) || ""}
                onChange={handleTemplateChange}
                required={!hasActionEvent}
              />
            </Box>

            <TextDivider mb={2} />

            <Box mb={2}>
              <ActionPriorityOutlinedSelect
                label={t`For what action priorities?`}
                hasAll
                allLabel="All priorities"
                multiple
                onChange={handleActionPriorityChange}
                value={eventSource?.actionPriorities ?? []}
              />
            </Box>
          </>
        )}

        <TextDivider mb={2} />

        <Box mb={2}>
          <TriggerTargetTypeOutlinedSelect
            showNotifyAuthor={hasUserTrainingCourseCompleted}
            value={targetType}
            onChange={handleTargetTypeChange}
            required
          />
        </Box>

        {hasNotification && (
          <>
            {targetType === NOTIFY_USERS && (
              <Box mb={2}>
                <TriggerNotifyMethodOutlinedSelect
                  value={notifyUsersTarget.methods}
                  onChange={handleNotifyMethodsChange}
                  multiple
                />
              </Box>
            )}

            {targetType === NOTIFY_AUTHOR && (
              <Box mb={2}>
                <TriggerNotifyMethodOutlinedSelect
                  value={notifyAuthorTarget.methods}
                  onChange={handleNotifyAuthorMethodsChange}
                  multiple
                />
              </Box>
            )}
            <Box mb={2}>
              <LocationOutlinedSelect
                mode="admin"
                label={t`For which locations?`}
                value={eventSource?.locations || []}
                onChange={handleLocationsChange}
                onRegionChange={handleRegionChange}
                multiple
              />
            </Box>
          </>
        )}

        {targetType === NOTIFY_USERS && Boolean(initialPicked) && (
          <UsersGroupsAssigner
            title={t`Who should be notified?`}
            allowSelf
            allowAll={false}
            locations={mapToIds(locations)}
            initialPicked={initialPicked}
            onPickedChanged={handleNotifyUsersPickedChange}
            cy="TriggerCreator-notification-to"
          />
        )}

        {targetType === NOTIFY_EMAILS && (
          <Box my={2}>
            <TagsTextField
              value={notifyEmailsTarget.emails || []}
              label={t`Email Address(es)`}
              placeholder={t`Type address and press enter...`}
              inputProps={{ type: "email" }}
              onChange={handleEmailsChange}
            />
          </Box>
        )}
      </Box>

      <CreatorActions
        id={`${cy}-CreatorActions`}
        subject={t`Trigger`}
        onClose={handleClose}
        onSubmit={handleSubmit}
        submitLoading={loading}
        disableSubmit={disableSubmit}
      />
    </>
  )
}

export default EventSourceTrigger

import React from "react"
import { Card, CardContent, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import { NoteHeader } from "./NoteHeader"
import { InfoSection } from ".."
import { NoteContent } from "./NoteContent"
import { useMutationJobStepDeleteNote } from "../../data"
import { toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  note: {
    marginBottom: theme.spacing(3),
    padding: 0,
  },
  content: {
    paddingTop: 0,
    "&&": {
      paddingBottom: 0,
    },
  },
  section: {
    borderBottom: "1px solid #EFEFEF",
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    "&:last-child": {
      borderBottom: "none",
    },
  },
  compactSection: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sectionHeader: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const Note = ({ job, note, onDeleted }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const [deleteNote, { loading: deleteLoading }] = useMutationJobStepDeleteNote(job, note.jobStep)
  const classes = useStyles({ xs })
  const { jobStep: step } = note

  const hasJobStep = Boolean(step)

  const handleDelete = async () => {
    const variables = {
      job: toId(job),
      step: toId(step),
      note: toId(note),
    }

    const result = await deleteNote({
      variables,
    })

    if (!result.errors) {
      onDeleted && onDeleted(note)
    }
  }

  return (
    <>
      <Card className={classes.note} elevation={3}>
        <CardContent className={classes.content}>
          <div className={`${classes.section} ${classes.sectionHeader}`}>
            <NoteHeader onDelete={handleDelete} isLoading={deleteLoading} {...{ note }} />
          </div>
          <div className={classes.section}>
            <NoteContent note={note} />
          </div>
          {hasJobStep && (
            <div className={classes.compactSection}>
              <InfoSection title="Step" value={step.name} px={0} compact />
            </div>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export { Note }

import React, { useEffect, useMemo, useRef, useState } from "react"
import pluralize from "pluralize"
import { LocationOutlinedSelect } from ".."
import { ChipMenu } from "./ChipMenu"
import { ChipMenuItem } from "./ChipMenuItem"
import { ChipMenuActions } from "./ChipMenuActions"
import { multipleSelectChange, toId } from "../../utils"
import { useAuth } from "../../services"
import { ValueText } from "./ValueText"
import { useQueryLocations } from "../../data"

const LocationValueText = ({ value, selectedRegions }) => {
  const { getUserLocation } = useAuth()

  if (!value) {
    return "Locations"
  }

  if (value === "all" || (Array.isArray(value) && value.includes("all"))) {
    return "All locations"
  }

  const count = Array.isArray(value) ? value.length : 0

  if (selectedRegions?.length === 1 && count === selectedRegions[0].locations?.length) {
    return `Region: ${selectedRegions[0].name}`
  }

  if (count === 0) {
    return "No locations"
  }

  if (count === 1) {
    const location = getUserLocation(value[0])
    return location ? `Location: ${location.name}` : "Unknown location"
  }

  return pluralize("location", count, true)
}

const LocationChipMenuItem = ({ value, onChange, ...props }) => {
  const handleChange = (event) => {
    onChange && onChange(multipleSelectChange(value, event.target.value))
  }

  return (
    <ChipMenuItem>
      <LocationOutlinedSelect value={value} onChange={handleChange} {...props} hasAll multiple />
    </ChipMenuItem>
  )
}

const LocationChipMenu = ({ value, onChange, onRegionChange, ...props }) => {
  const [nextValue, setNextValue] = useState(value)
  const menuRef = useRef()
  const { hasFeature } = useAuth()
  const { data: locations } = useQueryLocations()

  const hasRegions = hasFeature("regions")

  const regions = useMemo(() => {
    if (!hasRegions || !locations) return []

    return locations?.locations?.reduce((acc, location) => {
      if (location.region) {
        acc[location.region.id] = location.region
      }
      return acc
    }, [])
  }, [hasRegions, locations])

  const selectedRegions = useMemo(() => {
    if (!hasRegions || (Object.entries(regions).length <= 0 && locations?.locations.length <= 0)) return []

    const newSelectedRegions = Object.values(regions).reduce((selectedItems, region) => {
      const locationsByRegion = locations?.locations?.filter((location) => {
        return toId(region) === toId(location?.region)
      })
      const selectedLocationsByRegion = locationsByRegion.filter((location) => {
        return nextValue.includes(toId(location))
      })

      if (selectedLocationsByRegion.length === locationsByRegion.length) {
        const hasRegion = selectedItems.some((selectedItem) => selectedItem.id === region.id)

        if (!hasRegion) {
          return [...selectedItems, { ...region, locations: selectedLocationsByRegion }]
        }
      }

      return selectedItems.filter((selectedItem) => toId(selectedItem) !== toId(region))
    }, [])

    return newSelectedRegions
  }, [hasRegions, regions, locations, nextValue])

  useEffect(() => {
    setNextValue(value)
  }, [value])

  const handleChange = (newValue) => {
    setNextValue(newValue)
  }

  const handleRegionChange = (regionLocations) => {
    setNextValue(regionLocations)
  }

  const handleApply = () => {
    menuRef.current?.close()
    onChange && onChange(nextValue)
  }

  const handleClear = () => {
    menuRef.current?.close()
    onChange && onChange(props?.multiple ? ["all"] : null)
  }

  return (
    <ChipMenu
      menuRef={menuRef}
      text={
        <ValueText>
          <LocationValueText value={value} selectedRegions={selectedRegions} />
        </ValueText>
      }
    >
      <LocationChipMenuItem value={nextValue} onChange={handleChange} onRegionChange={handleRegionChange} {...props} />
      <ChipMenuActions onApply={handleApply} onClear={handleClear} />
    </ChipMenu>
  )
}

export { LocationChipMenu, LocationChipMenuItem, LocationValueText }

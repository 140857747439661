import React, { useMemo, useState } from "react"
import { formatters } from "json2csv"
import fileDownload from "js-file-download"
import { Alert } from "@material-ui/lab"
import { Box } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { ReportSectionActionCard } from "../ActionCards"
import { RowBox } from "../Boxes"
import ReportToolsDownloadDataIconButton from "../Buttons/ReportToolsDownloadDataIconButton"
import { SplineDaysChart } from "../Charts/SplineDaysChart"
import { useQueryReportAuditScores } from "../../data/report/useQueryReportAuditScoresDays"
import { useAuth } from "../../services"
import { mapToIds, scheduleDateToMoment, scheduleDatesMatch, toId, useWindowSize } from "../../utils"
import { NoItemsMessage } from "../Lists"

const AuditScoringDaysReport = ({ gt, lt, locations, process, containerHeight = 500 }) => {
  const [fullscreen, setFullscreen] = useState(false)
  const { height: windowHeight } = useWindowSize()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data, loading } = useQueryReportAuditScores({
    variables: {
      locations: locations.includes("all") ? mapToIds(userLocations) : locations,
      process: process === "all" ? null : toId(process),
      gt,
      lt,
    },
  })

  const handleFullscreen = () => {
    setFullscreen(!fullscreen)
  }

  const getPropertyKey = (item) => `${toId(item.process)}-${toId(item.processSchedule)}-${toId(item.location)}-`

  const chartData = useMemo(() => {
    const propertyKeys = []

    const result =
      data?.reportAuditScores?.list.reduce((acc, auditScore) => {
        // get all unique report at days
        const reportAtDays = auditScore.data.reduce((reportAtDaysAcc, d) => {
          const dataDay = reportAtDaysAcc.find((dd) => scheduleDatesMatch(dd, d.reportAtDay))
          if (dataDay) {
            return reportAtDaysAcc
          }
          return [...reportAtDaysAcc, d.reportAtDay]
        }, [])

        // push data to data days in acc
        reportAtDays.forEach((lineDataDay) => {
          const key = getPropertyKey(auditScore)

          propertyKeys.push(key)

          const dataDay = acc.find((dd) => dd.date === scheduleDateToMoment(lineDataDay).unix())

          if (dataDay) {
            dataDay[key] = auditScore.data.find((d) => scheduleDatesMatch(d.reportAtDay, lineDataDay)).scorePercent
            return
          }

          acc.push({
            date: scheduleDateToMoment(lineDataDay).unix(),
            downloadDay: scheduleDateToMoment(lineDataDay).format("YYYY-MM-DD"),
            [key]: auditScore.data.find((d) => scheduleDatesMatch(d.reportAtDay, lineDataDay)).scorePercent,
          })
        })

        return acc
      }, []) || []

    return result.sort((a, b) => a.date - b.date)
  }, [data])

  const series = data?.reportAuditScores?.list.map(getPropertyKey)

  const { jobsTotal, jobsReturned } = data?.reportAuditScores?.counts || { jobsTotal: 0, jobsReturned: 0 }

  const getPropertyKeyFromChartKey = (chartKey) => {
    const keyParts = chartKey.split("-")
    return `${keyParts[0]}-${keyParts[1]}-${keyParts[2]}-`
  }

  const labelResolver = (inputKey) => {
    const key = getPropertyKeyFromChartKey(inputKey)

    const item = data?.reportAuditScores?.list.find((d) => {
      return getPropertyKey(d) === key
    })

    if (item) {
      return item.locationName
    }

    return "Unknown"
  }

  const handleDownloadData = () => {
    const stringFormatter = formatters.string()

    const header = ["Date", ...series.map((s) => stringFormatter(labelResolver(s)))]
    const rows = chartData.map((d) => {
      const result = [d.downloadDay]
      series.forEach((s) => {
        result.push(d[s] || "")
      })
      return result.join(",")
    })
    const csv = `${header.join(",")}\n${rows.join("\n")}`
    fileDownload(csv, "audit-score-over-time.csv", "text/csv")
  }

  const hasDataAlert = jobsTotal > jobsReturned
  const alertOffset = hasDataAlert ? 70 : 0

  return (
    <>
      {hasDataAlert && (
        <Box mb={1}>
          <Alert severity="warning">
            Too much data to display. Showing {jobsReturned} of {jobsTotal} documents, try narrowing your filters.
          </Alert>
        </Box>
      )}
      <ReportSectionActionCard
        title="Audits"
        detail="Score % over time"
        loading={loading}
        onFullscreen={handleFullscreen}
        tools={
          <RowBox>
            <ReportToolsDownloadDataIconButton onClick={handleDownloadData} disabled={loading} />
          </RowBox>
        }
      >
        {chartData?.length > 0 && (
          <SplineDaysChart
            data={chartData}
            series={series}
            labelResolver={labelResolver}
            getPropertyKeyFromChartKey={getPropertyKeyFromChartKey}
            argumentField="date"
            height={
              fullscreen
                ? windowHeight - 120
                : Math.max(windowHeight - 450 - alertOffset, containerHeight - alertOffset)
            }
            containerHeight={fullscreen ? null : containerHeight}
          />
        )}
        {chartData?.length === 0 && (
          <NoItemsMessage>
            <Trans>No data</Trans>
          </NoItemsMessage>
        )}
      </ReportSectionActionCard>
    </>
  )
}

export default AuditScoringDaysReport

import React, { useEffect, useMemo, useRef, useState } from "react"

import { useHistory } from "react-router-dom"
import Config from "react-global-configuration"
import { Box, Button, makeStyles, useMediaQuery, useTheme } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import useMeasure from "react-use-measure"
import { Trans, t } from "@lingui/macro"
import { useAuth } from "../../services"
import { SelectorMaster, NoItemsMessage, SearchInput } from ".."
import { toId, useMountEffect } from "../../utils"
import LocationSwitcherCard from "./LocationSwitcherCard"
import { GroupedListVirtualizer } from "../Lists/GroupedListVirtualizer"
import { RegionSwitcherCard } from "./RegionSwitcherCard"

const useStyles = makeStyles((theme) => ({
  dialogActionsClassName: {
    display: "block",
    padding: "8px 16px !important",
    borderTop: "1px solid #efefef",
  },
  dialogContentClassName: {
    padding: theme.spacing(0, 0, 2, 2),
  },
  dialogTitleClassName: {
    padding: "16px",
  },
  inlineAction: {
    position: "fixed",
    bottom: 65,
    zIndex: 999,
    backgroundColor: "white",
    padding: theme.spacing(1, 2),
    width: "100vw",
    borderTop: "1px solid #efefef",
  },
}))

const closeOnSwitchTimeoutMs = 700

const scrollTo = {
  delayMs: 300,
  behavior: "auto",
}

const matchesLocation = (location, filterableSearchText) => location?.name.toLowerCase().includes(filterableSearchText)

const matchesRegion = (location, filterableSearchText) =>
  location?.region?.name.toLowerCase().includes(filterableSearchText)

const filterLocations = (locations, searchText) => {
  const filterableSearchText = searchText.trim().toLowerCase()

  return locations.filter(
    (item) =>
      !filterableSearchText || matchesLocation(item, filterableSearchText) || matchesRegion(item, filterableSearchText),
  )
}

const LocationSwitcher = ({ open, from, onClose, isInline = false }) => {
  const history = useHistory()
  const {
    location: userLocation,
    settings: { locations, regions },
    setLocation,
    hasFeature,
  } = useAuth()
  const [searchText, setSearchText] = useState("")
  const [switching, setSwitching] = useState(null)
  const classes = useStyles()
  const closerRef = useRef()
  const initialScrollRef = useRef()
  const virtuosoRef = useRef()
  const [inlineTitleRef, { height: inlineTitleHeight }] = useMeasure()
  const [inlineSearchRef, { height: inlineSearchHeight }] = useMeasure()
  const [inlineActionsRef, { height: inlineActionsHeight }] = useMeasure()
  const [dialogTitleRef, { height: dialogTitleHeight }] = useMeasure()
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  useMountEffect(() => {
    return () => clearTimeout(closerRef.current)
  }, [])

  const handleFilter = (text) => {
    setSearchText(text)
  }

  const handleChange = (item) => {
    setLocation(item)
    setSwitching(item)
    closerRef.current = setTimeout(() => handleClose(true), closeOnSwitchTimeoutMs)
  }

  const handleCancel = () => handleClose(false)

  const handleClose = (changed) => {
    setSearchText("")
    setSwitching(null)

    // for active jobs area viewing a job, redirect back to active jobs + date instead
    // to avoid the location switching back to that in the from value
    if (isInline && changed) {
      const splitFrom = from?.split("/").filter((part) => part)
      if (from?.startsWith("/jobs/") && splitFrom.length > 2) {
        console.log("redirecting user to", `/jobs/${splitFrom[1]}`)
        history.push(`/jobs/${splitFrom[1]}`)
        return
      }
    }

    // back to from
    if (isInline) {
      console.log("redirecting user to", from || "/")
      history.push(from || "/")
      return
    }

    console.log("closing location switcher")

    onClose && onClose()
  }

  const hasRegions = hasFeature("regions")

  const { counts, items, groups, hasItems } = useMemo(() => {
    const result = {
      groups: regions.filter((region) => filterLocations(region.locations, searchText).length > 0),
    }
    const hasRegionItems = regions.some((region) => region.id !== "none")

    // regions
    if (hasRegions) {
      // -> counts
      result.counts = hasRegionItems
        ? result.groups.map((region) => filterLocations(region.locations, searchText).length)
        : [filterLocations(locations, searchText).length]

      // -> items
      result.hasItems = false
      result.items = result.groups.reduce((acc, region) => {
        acc.push(region)
        const filteredLocations = filterLocations(region.locations, searchText)
        acc.push(...filteredLocations.sort((a, b) => (a.inactive ? 1 : b.inactive ? -1 : 0)))
        result.hasItems = result.hasItems || filteredLocations.length > 0
        return acc
      }, [])

      return result
    }

    // no regions
    result.items = filterLocations(locations, searchText)
    result.counts = [result.items.length]
    result.hasItems = result.items.length > 0

    return result
  }, [hasRegions, locations, regions, searchText])

  useEffect(() => {
    if (xs && initialScrollRef.current) {
      return
    }

    // -> scroll to current item index
    let timeout
    if (open && userLocation) {
      const userLocationIndex = items.findIndex((item) => toId(item) === toId(userLocation))
      if (userLocationIndex > -1) {
        timeout = setTimeout(() => {
          virtuosoRef.current?.scrollToIndex({ index: userLocationIndex, align: "center", behavior: scrollTo.behavior })
          initialScrollRef.current = true
        }, scrollTo.delayMs)
      }
    }
    return () => clearTimeout(timeout)
  }, [items, open, userLocation, xs])

  const hasFilter = locations.length > 10

  const search = (
    <SearchInput
      placeholder={t`Filter locations`}
      initialValue={searchText}
      boxProps={{ pl: 0, pr: 0, mb: 1 }}
      onChange={handleFilter}
      autoFocus={!kiosk && !device}
    />
  )

  const listStyle = isInline
    ? {
        height: `calc(100vh - ${
          theme.dimensions.header.height +
          inlineTitleHeight +
          inlineSearchHeight +
          inlineActionsHeight +
          theme.dimensions.footer.mobileBottomNav.height +
          24
        }px)`,
      }
    : {
        height: `min(calc(100vh - ${
          theme.dimensions.dialogs.margin * 2 + dialogTitleHeight + theme.spacing(2)
        }px), 600px)`,
        overflowY: "scroll",
      }

  const form = (
    <Box>
      {hasFilter && isInline && (
        <Box ref={inlineSearchRef} px={2}>
          {search}
        </Box>
      )}

      {!hasItems && !hasFilter && (
        <Alert severity="info">
          <AlertTitle>
            <Trans>There are no locations linked to your user profile.</Trans>
          </AlertTitle>
          <Trans>Please contact your system administrator.</Trans>
        </Alert>
      )}

      {!hasItems && hasFilter && (
        <NoItemsMessage>
          <Trans>No matching locations</Trans>
        </NoItemsMessage>
      )}

      {open && hasItems && (
        <GroupedListVirtualizer
          ref={virtuosoRef}
          style={listStyle}
          groupCounts={counts}
          groupContent={(index) => {
            const region = groups[index]

            if (!region) {
              return null
            }

            if (region.id === "none") {
              return <div style={{ height: theme.spacing(0.5) }} />
            }

            return (
              <Box
                key={toId(region, true) || "none"}
                pl={2}
                pr={isInline ? 2 : 1}
                mb={1}
                pb={index === 0 ? 1 : 0}
                mt={index > 0 ? 1 : 0}
              >
                <RegionSwitcherCard region={region} />
              </Box>
            )
          }}
          items={items}
          itemContent={(_, __, item) => {
            if (!item) {
              return null
            }

            return (
              <Box key={toId(item)} pl={2} pr={isInline ? 2 : 1}>
                <LocationSwitcherCard switching={item === switching} location={item} onChange={handleChange} />
              </Box>
            )
          }}
          components={{
            Footer: () => <div style={{ height: theme.spacing(1) }}>&nbsp;</div>,
          }}
          logLevel={5}
        />
      )}

      {isInline && (
        <Box ref={inlineActionsRef} className={classes.inlineAction}>
          <Button variant="contained" onClick={handleCancel} fullWidth>
            <Trans>Cancel</Trans>
          </Button>
        </Box>
      )}
    </Box>
  )

  return (
    <SelectorMaster
      open={open}
      title={<Trans>Change location</Trans>}
      header={isInline ? null : <Box pt={2}>{search}</Box>}
      form={form}
      isInline={isInline}
      onCancel={xs ? handleCancel : null}
      onClose={handleCancel}
      maxWidth="md"
      cancelVariant="button"
      inlineTitleProps={{ ref: inlineTitleRef }}
      inlineContentProps={{ px: 0 }}
      dialogTitleProps={{ ref: dialogTitleRef }}
      dialogContentClassName={classes.dialogContentClassName}
      dialogActionsClassName={classes.dialogActionsClassName}
    />
  )
}

export { LocationSwitcher }

import React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { t } from "@lingui/macro"
import { GroupOutlinedSelect, LocationOutlinedSelect } from ".."
import { multipleSelectChange, useMountAwareReactiveVar } from "../../utils"
import { hubFiltersVar } from "../../data/hubFiltersVar"
import TagsField from "../TextField/TagsField"

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "70vw",
  },
}))

const InlineHubFilters = () => {
  const classes = useStyles()
  const { groups, locations, tags } = useMountAwareReactiveVar(hubFiltersVar)

  const handleGroupsChanged = (event) => {
    updateState({ groups: [...multipleSelectChange(groups, event)] })
  }

  const handleLocationsChanged = (event) => {
    updateState({ locations: [...multipleSelectChange(locations, event)] })
  }

  const handleRegionChange = (regionLocations) => {
    updateState({ locations: [...multipleSelectChange(locations, regionLocations)] })
  }

  const handleTagsChanged = (_, newTags) => {
    updateState({ tags: [...multipleSelectChange(tags, newTags)] })
  }

  const updateState = (data) => {
    hubFiltersVar({ ...hubFiltersVar(), ...data })
  }

  const tagsValue = tags.includes("all") ? [] : tags

  return (
    <Box px={2} mb={3} className={classes.root}>
      <Box mb={2}>
        <GroupOutlinedSelect
          value={groups}
          onChange={handleGroupsChanged}
          multiple
          usersGroupsOnly
          data-cy="InlineHubFilters-groups"
        />
      </Box>
      <LocationOutlinedSelect
        value={locations}
        onChange={handleLocationsChanged}
        onRegionChange={handleRegionChange}
        multiple
        data-cy="InlineHubFilters-locations"
      />
      <Box mt={2}>
        <TagsField
          label={tagsValue.length > 0 ? t`Tags` : null}
          placeholder={t`Tags`}
          value={tags.includes("all") ? [] : tags}
          onChange={handleTagsChanged}
          data-cy="HubFiltersButton-tags"
        />
      </Box>
    </Box>
  )
}

export { InlineHubFilters }

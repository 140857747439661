import React, { Suspense } from "react"
import { Hidden } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { AreaHeader, Content } from "../../components"
import {
  DesktopCreateSuppliersButton,
  MobileCreateSuppliersButton,
} from "../../components/Suppliers/CreateSuppliersButton"
import { ItemCountHeader } from "../../components/AreaHeader/ItemCountHeader"
import { useQuerySuppliersCount } from "../../data"
import PageLoadingSpinner from "../../components/LoadingSpinner/PageLoadingSpinner"

const Suppliers = React.lazy(() => import("./Suppliers"))

const SuppliersArea = () => {
  const history = useHistory()
  const { data } = useQuerySuppliersCount()

  const handleCreate = () => {
    history.push("/suppliers/new")
  }

  return (
    <>
      <AreaHeader
        title={<Trans>Suppliers</Trans>}
        titleIcon="suppliers-light"
        desktopButtons={<DesktopCreateSuppliersButton onCreate={handleCreate} />}
        mobileButtons={<MobileCreateSuppliersButton onCreate={handleCreate} />}
      />

      <Content full start>
        <Hidden smDown>
          <ItemCountHeader loading={!data} count={data?.suppliers.count} subject="suppliers" />
        </Hidden>

        <Suspense fallback={<PageLoadingSpinner />}>
          <Suppliers />
        </Suspense>
      </Content>
    </>
  )
}

export default SuppliersArea

import React, { useEffect, useMemo, useState } from "react"
import { Box, makeStyles, Grid, Button, useTheme } from "@material-ui/core"
import moment from "moment"
import { useHistory, useParams } from "react-router"
import { Alert } from "@material-ui/lab"
import { Trans } from "@lingui/macro"
import {
  LocationOutlinedSelect,
  TemplateOutlinedSelect,
  DateRangeOutlinedSelect,
  RowBox,
  NumberDisplay,
  LoadingSpinner,
  FlexBox,
  NoItemsMessage,
  ReportSectionActionCard,
} from "../../components"
import { mapToIds, toId, useDateUtils, useReportUtils } from "../../utils"
import { useAuth } from "../../services"
import { PROCESS_TYPE } from "../../data"
import AuditScoringDaysReport from "../../components/Reports/AuditScoringDaysReport"
import { useQueryReportAuditsOverview } from "../../data/report/useQueryReportAuditsOverview"

const useStyles = makeStyles((theme) => ({
  filter: {
    backgroundColor: theme.palette.shaded.filter,
    width: 300,
    flexShrink: 0,
  },
  filterSelect: {
    backgroundColor: "white",
    maxWidth: 300,
  },
  reports: {
    width: 932 - theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  infiniteScrollWrapper: {
    "&&>div": {
      display: "contents",
    },
  },
  infiniteScroll: {
    display: "contents",
  },
}))

const initialLimit = 8

const AuditTiles = ({ gt, lt, locations, gridItemProps = {}, onNavigate }) => {
  const theme = useTheme()
  const {
    settings: { locations: userLocations },
  } = useAuth()
  const { data, loading } = useQueryReportAuditsOverview({
    variables: { locations: locations.includes("all") ? mapToIds(userLocations) : locations, gt, lt },
  })

  const handleNavigate = (process) => {
    onNavigate && onNavigate(process)
  }

  if (loading) {
    return (
      <FlexBox justifyContent="center" flexGrow={1} mt={3}>
        <LoadingSpinner size={60} delay={false} />
      </FlexBox>
    )
  }

  const { jobsTotal, jobsReturned } = data?.reportAuditsOverview?.counts || { jobsTotal: 0, jobsReturned: 0 }

  const zeroColour = theme.palette.text.secondary

  return (
    <>
      {jobsTotal > jobsReturned && (
        <Grid item xs={12}>
          <Alert severity="warning">
            <Trans>
              Too much data to display. Showing {jobsReturned} of {jobsTotal} documents, try narrowing your filters.
            </Trans>
          </Alert>
        </Grid>
      )}
      {data?.reportAuditsOverview?.list.map((process) => (
        <Grid key={toId(process)} item {...gridItemProps}>
          <ReportSectionActionCard
            title={process.name}
            detail={`${process.total} total`}
            cardProps={{ onClick: () => handleNavigate(process) }}
            layout="row"
            titleProps={{ pl: 2 }}
            p={0}
          >
            <RowBox px={2} pt={2}>
              <NumberDisplay
                value={process.submitted}
                color={process.submitted > 0 ? theme.palette.success.main : zeroColour}
                subject={<Trans>Submitted</Trans>}
              />
              <NumberDisplay
                value={process.overdue}
                color={process.overdue > 0 ? theme.palette.error.main : zeroColour}
                subject={<Trans>Overdue</Trans>}
                ml={2}
              />
              <NumberDisplay
                value={process.inProgress}
                color={process.inProgress ? theme.palette.primary.main : zeroColour}
                subject={<Trans>In progress</Trans>}
                ml={2}
              />
            </RowBox>
          </ReportSectionActionCard>
        </Grid>
      ))}
      {data?.reportAuditsOverview?.list.length === 0 && (
        <FlexBox justifyContent="center" flexGrow={1} mt={3}>
          <NoItemsMessage>
            <Trans>No audits found for the selected filters</Trans>
          </NoItemsMessage>
        </FlexBox>
      )}
    </>
  )
}

const Audits = () => {
  const classes = useStyles()
  const { days, locations, groups, process } = useParams()
  const { momentToScheduleDate } = useDateUtils()
  const { getNavigateToAuditsLink, getNavigateToLink, filterChange } = useReportUtils()
  const [, setLimit] = useState(initialLimit)
  const [gt, setGt] = useState(null)
  const [lt, setLt] = useState(null)
  const history = useHistory()
  const {
    settings: { locations: userLocations },
  } = useAuth()

  const daysValue = days || 7
  const groupIds = groups?.split("-") || ["all"]
  const locationIds = useMemo(() => locations?.split("-") || ["all"], [locations])
  const processValue = process || "all"

  useEffect(() => {
    setLimit(initialLimit)
    if (days.includes("-to-")) {
      const daysParts = days.split("-to-")
      setGt(momentToScheduleDate(moment(daysParts[0])))
      setLt(momentToScheduleDate(moment(daysParts[1])))
    } else {
      setGt(momentToScheduleDate(moment().subtract(days, "days")))
      setLt(null)
    }
  }, [days, locations, momentToScheduleDate, process, userLocations])

  const handleDaysChange = (event) => {
    history.push(getNavigateToAuditsLink(event.target.value, locationIds, groupIds, processValue))
  }

  const handleLocationsChange = (event) => {
    history.push(getNavigateToAuditsLink(daysValue, filterChange(locationIds, event), groupIds, processValue))
  }

  const handleRegionChange = (regionLocations) => {
    history.push(getNavigateToAuditsLink(daysValue, mapToIds(regionLocations), groupIds, processValue))
  }

  const handleJobChange = (event) => {
    history.push(getNavigateToAuditsLink(daysValue, locationIds, groupIds, event.target.value))
  }

  const handleNavigateToAudit = (audit) => {
    history.push(getNavigateToAuditsLink(daysValue, locationIds, groupIds, toId(audit)))
  }

  const handleReset = () => {
    history.push(getNavigateToLink("audits", 7, ["all"], ["all"]))
  }

  const isAll = processValue === "all"

  return (
    <RowBox alignItems="flex-start">
      <Box p={3} className={classes.filter} mt={3} mb={3}>
        <Box mb={2}>
          <DateRangeOutlinedSelect value={daysValue} onChange={handleDaysChange} className={classes.filterSelect} />
        </Box>
        <Box mb={2}>
          <LocationOutlinedSelect
            value={locationIds}
            className={classes.filterSelect}
            onChange={handleLocationsChange}
            onRegionChange={handleRegionChange}
            multiple
          />
        </Box>
        <Box>
          <TemplateOutlinedSelect
            label={<Trans>Audit</Trans>}
            selectLabel={<Trans>Select audit...</Trans>}
            allLabel={<Trans>All audits</Trans>}
            value={processValue}
            className={classes.filterSelect}
            onChange={handleJobChange}
            hasAll
            types={[PROCESS_TYPE.AUDIT]}
          />
        </Box>
        <RowBox mt={2} justifyContent="flex-end">
          <Button variant="contained" onClick={handleReset}>
            <Trans>Reset</Trans>
          </Button>
        </RowBox>
      </Box>

      <Box flexGrow={1}>
        <Grid container spacing={1} className={classes.reports}>
          {isAll && (
            <AuditTiles
              gt={gt}
              lt={lt}
              locations={locationIds}
              gridItemProps={{ xs: 12 }}
              onNavigate={handleNavigateToAudit}
            />
          )}
          {!isAll && (
            <Grid item xs={12}>
              <AuditScoringDaysReport gt={gt} lt={lt} locations={locationIds} process={processValue} />
            </Grid>
          )}
        </Grid>
      </Box>
    </RowBox>
  )
}

export default Audits

import { gql, useQuery } from "@apollo/client"
import { JOB_NOTES_FIELDS } from "./fragments/jobNotesFieldsFragment"

const JOB_NOTES_QUERY = gql`
  query JobStep($job: ID!) {
    job(id: $job) {
      notes {
        ...JobNotesFields
      }
    }
  }
  ${JOB_NOTES_FIELDS}
`

const useQueryJobNotes = (options) => useQuery(JOB_NOTES_QUERY, options)

export { useQueryJobNotes }

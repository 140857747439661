import React, { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { Divider } from "@material-ui/core"
import { FiltersChipMenu } from "./FiltersChipMenu"
import { DateRangeFiltersChipMenuItem } from "./DateRangeFiltersChipMenuItem"
import { LocationsFiltersChipMenuItem } from "./LocationsFiltersChipMenuItem"
import { GroupsFiltersChipMenuItem } from "./GroupsFiltersChipMenuItem"
import { TagsFiltersChipMenuItem } from "./TagsFiltersChipMenuItem"
import { ProcessFiltersChipMenuItem } from "./ProcessFiltersChipMenuItem"
import { ActionStatusFiltersChipMenuItem } from "./ActionStatusFiltersChipMenuItem"
import { ActionPriorityFiltersChipMenuItem } from "./ActionPriorityFiltersChipMenuItem"
import { UsersFiltersChipMenuItem } from "./UsersFiltersChipMenuItem"
import { JobStatusFiltersChipMenuItem } from "./JobStatusFiltersChipMenuItem"
import { isString } from "../../utils/string"

const ReportFiltersChipMenu = ({
  values,
  onApply,
  onClear,
  menu = {
    days: false,
    users: false,
    locations: false,
    groups: false,
    process: false,
    actionStatus: false,
    actionPriorities: false,
    tags: false,
  },
  menuOptions = {},
}) => {
  const [nextValues, setNextValues] = useState(values)
  const [expandedKey, setExpandedKey] = useState("")
  const menuRef = useRef()
  const daysMenuItemRef = useRef()
  const usersMenuItemRef = useRef()
  const locationsMenuItemRef = useRef()
  const groupsMenuItemRef = useRef()
  const processMenuItemRef = useRef()
  const jobStatusMenuItemRef = useRef()
  const actionStatusMenuItemRef = useRef()
  const actionPrioritiesItemRef = useRef()
  const tagsMenuItemRef = useRef()

  useEffect(() => {
    setNextValues(values)
  }, [values])

  useEffect(() => {
    if (!expandedKey) {
      return
    }

    const menuItemRefs = {
      days: daysMenuItemRef,
      users: usersMenuItemRef,
      locations: locationsMenuItemRef,
      groups: groupsMenuItemRef,
      process: processMenuItemRef,
      jobStatus: jobStatusMenuItemRef,
      actionStatus: actionStatusMenuItemRef,
      actionPriorities: actionPrioritiesItemRef,
      tags: tagsMenuItemRef,
    }

    Object.keys(menuItemRefs)
      .filter((key) => key !== expandedKey)
      .forEach((key) => {
        const ref = menuItemRefs[key]
        if (!ref.current) {
          return
        }

        ref.current.close()
      })
  }, [expandedKey])

  const handleExpanded = (key) => {
    setExpandedKey(key)
  }

  const handleChange = (key, newValue) => {
    setNextValues((prevValues) => ({ ...prevValues, [key]: newValue }))
  }

  const handleApply = () => {
    menuRef.current?.close()
    onApply && onApply(nextValues)
  }

  const handleClear = () => {
    menuRef.current?.close()
    onClear && onClear()
  }

  const filterHasValue = (filter) =>
    (isString(filter) && filter !== "all") || (Array.isArray(filter) && filter.length > 0 && filter[0] !== "all")

  const filtersCount = Object.entries(values).filter(([key, value]) => key !== "days" && filterHasValue(value)).length
  const filtersText = `Filters (${filtersCount})`

  const menuItems = useMemo(() => {
    const result = []
    if (menu.days) {
      result.push({
        key: "days",
        item: (
          <DateRangeFiltersChipMenuItem
            key="days"
            menuItemRef={daysMenuItemRef}
            value={nextValues.days}
            onChange={(value) => handleChange("days", value)}
            onExpanded={() => handleExpanded("days")}
          />
        ),
      })
    }
    if (menu.users) {
      result.push({
        key: "users",
        item: (
          <UsersFiltersChipMenuItem
            key="users"
            menuItemRef={usersMenuItemRef}
            value={nextValues.users}
            onChange={(value) => handleChange("users", value)}
            onExpanded={() => handleExpanded("users")}
          />
        ),
      })
    }
    if (menu.locations) {
      result.push({
        key: "locations",
        item: (
          <LocationsFiltersChipMenuItem
            key="locations"
            menuItemRef={locationsMenuItemRef}
            value={nextValues.locations}
            onChange={(value) => handleChange("locations", value)}
            onRegionChange={(regionLocations) => handleChange("locations", regionLocations)}
            onExpanded={() => handleExpanded("locations")}
          />
        ),
      })
    }
    if (menu.groups) {
      result.push({
        key: "groups",
        item: (
          <GroupsFiltersChipMenuItem
            key="groups"
            menuItemRef={groupsMenuItemRef}
            value={nextValues.groups}
            onChange={(value) => handleChange("groups", value)}
            onExpanded={() => handleExpanded("groups")}
          />
        ),
      })
    }
    if (menu.process) {
      result.push({
        key: "process",
        item: (
          <ProcessFiltersChipMenuItem
            key="process"
            menuItemRef={processMenuItemRef}
            value={nextValues.process}
            onChange={(value) => handleChange("process", value)}
            onExpanded={() => handleExpanded("process")}
            processFilters={menuOptions?.process?.filters ?? {}}
          />
        ),
      })
    }
    if (menu.jobStatus) {
      result.push({
        key: "jobStatus",
        item: (
          <JobStatusFiltersChipMenuItem
            key="jobStatus"
            menuItemRef={jobStatusMenuItemRef}
            value={nextValues.jobStatus}
            onChange={(value) => handleChange("jobStatus", value)}
            onExpanded={() => handleExpanded("jobStatus")}
          />
        ),
      })
    }
    if (menu.actionStatus) {
      result.push({
        key: "actionStatus",
        item: (
          <ActionStatusFiltersChipMenuItem
            key="actionStatus"
            menuItemRef={actionStatusMenuItemRef}
            value={nextValues.actionStatus}
            onChange={(value) => handleChange("actionStatus", value)}
            onExpanded={() => handleExpanded("actionStatus")}
          />
        ),
      })
    }
    if (menu.actionPriorities) {
      result.push({
        key: "actionPriorities",
        item: (
          <ActionPriorityFiltersChipMenuItem
            key="actionPriorities"
            menuItemRef={actionPrioritiesItemRef}
            value={nextValues.actionPriorities}
            onChange={(value) => handleChange("actionPriorities", value)}
            onExpanded={() => handleExpanded("actionPriorities")}
          />
        ),
      })
    }
    if (menu.tags) {
      result.push({
        key: "tags",
        item: (
          <TagsFiltersChipMenuItem
            key="tags"
            menuItemRef={tagsMenuItemRef}
            value={nextValues.tags}
            onChange={(value) => handleChange("tags", value)}
            onExpanded={() => handleExpanded("tags")}
          />
        ),
      })
    }
    return result
  }, [
    menu.actionPriorities,
    menu.actionStatus,
    menu.days,
    menu.groups,
    menu.jobStatus,
    menu.locations,
    menu.process,
    menu.tags,
    menu.users,
    menuOptions?.process?.filters,
    nextValues.actionPriorities,
    nextValues.actionStatus,
    nextValues.days,
    nextValues.groups,
    nextValues.jobStatus,
    nextValues.locations,
    nextValues.process,
    nextValues.tags,
    nextValues.users,
  ])

  return (
    <FiltersChipMenu text={filtersText} menuRef={menuRef} onApply={handleApply} onClear={handleClear}>
      {menuItems.map(({ key, item }, index) => (
        <Fragment key={key}>
          {index > 0 && <Divider />}
          {item}
        </Fragment>
      ))}
    </FiltersChipMenu>
  )
}

export { ReportFiltersChipMenu }

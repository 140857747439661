import { Box, LinearProgress, makeStyles, Tooltip } from "@material-ui/core"
import clsx from "clsx"
import { RowBox } from "../Boxes"
import { JOB_COMPLETION_STATUS_LABELS } from "../../data"

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    flexGrow: 1,
    backgroundColor: theme.palette.grey[300],
    overflow: "hidden",
  },
  series: {
    height: 10,
  },
  progress: {
    height: 10,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  secondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  grey: {
    backgroundColor: theme.palette.grey[200],
  },
}))

const MultiSeriesLinearProgress = ({ values = [] }) => {
  const classes = useStyles()

  return (
    <RowBox className={classes.root}>
      {values.map(({ key, value, color }) => (
        <Box key={key} style={{ width: `${((value / 100) * 100).toFixed(1)}%` }} className={clsx(classes.series)}>
          <Tooltip
            title={
              <Box>
                <Box>
                  <strong>{JOB_COMPLETION_STATUS_LABELS[key]}</strong>
                </Box>
                <Box>{value.toFixed(1)}%</Box>
              </Box>
            }
            arrow
          >
            <LinearProgress
              variant="determinate"
              value={100}
              className={clsx(classes.progress)}
              classes={{
                barColorPrimary: classes[color],
              }}
            />
          </Tooltip>
        </Box>
      ))}
    </RowBox>
  )
}

export { MultiSeriesLinearProgress }
